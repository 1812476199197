<template>
  <div>
    <modal
      :class="'limit-height-100-145 modal-min-h-300'"
      :title="'Product Lists'"
      :handler="true"
      @modalHandler="this.modalHandler"
      :cssClass="'modal-xl'"
    >
      <template v-slot:content>
        <app-loader v-if="isProductListLoading" />
        <div class="pt-0 px-2 pb-4">
          <div class="d-flex">
            <div
              class="search-box-wrap w-100 w-100-vsm mb-3 me-0 me-sm-3 me-md-4"
            >
              <span class="k-icon k-i-zoom"></span>
              <k-input
                @input="debounceSearch"
                v-model="searchText"
                :class="'mb-0'"
                placeholder="Search by list name"
              >
              </k-input>
            </div>
          </div>
          <div class="limit-height-450 overflow-y-auto">
            <div class="product-lists-wrap">
              <div class="product-list-table">
                <div class="plt-head d-flex align-items-center">
                  <div class="plt-name">
                    <p class="font-14 font-w-400 base-color m-0">Name</p>
                  </div>
                  <div class="plt-crated-date">
                    <p class="font-14 font-w-400 base-color m-0">
                      Created Date
                    </p>
                  </div>
                </div>

                <div v-if="productLists?.length">
                  <template
                    v-for="(productList, index) in productLists"
                    :key="index"
                  >
                    <div class="plt-row d-flex align-items-center">
                      <div class="plt-name">
                        <div class="d-flex align-items-center">
                          <radiobutton
                            :size="'large'"
                            :name="'barcodeGroup'"
                            :value="'qrcode'"
                            :checked="
                              this.selectedProductList?.id == productList.id
                            "
                            @change="productListSelectionHandler(productList)"
                          />
                          <div class="ml-12">
                            <p class="font-14 font-w-500 base-color m-0">
                              {{ productList.parameterName }}
                            </p>
                            <!-- <p class="font-12 font-w-400 text-color-2 m-0">
                            Show products from All categories.
                          </p> -->
                          </div>
                        </div>
                      </div>
                      <div class="plt-crated-date">
                        <p class="font-14 font-w-400 text-color m-0">
                          {{ formatDateTime(productList.createdAt) }}
                        </p>
                      </div>
                    </div>
                  </template>
                </div>
                <div v-else>
                  <div v-if="searchText && !isProductListLoading">
                    <div
                      class="h-426 d-flex justify-content-center align-items-center text-center"
                    >
                      <div class="max-w-250">
                        <div class="mb-20">
                          <svg
                            width="166"
                            height="158"
                            viewBox="0 0 166 158"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <circle
                              cx="78.3221"
                              cy="78.9999"
                              r="78.3221"
                              fill="white"
                            />
                            <circle
                              cx="78.3221"
                              cy="78.9999"
                              r="78.3221"
                              fill="#0052CC"
                              fill-opacity="0.12"
                            />
                            <g filter="url(#filter0_dd_13284_154784)">
                              <rect
                                x="18.0746"
                                y="17.4141"
                                width="121.834"
                                height="122.504"
                                rx="13.7547"
                                fill="white"
                              />
                            </g>
                            <g
                              opacity="0.9"
                              filter="url(#filter1_d_13284_154784)"
                            >
                              <rect
                                x="5.35559"
                                y="23.6035"
                                width="123.843"
                                height="30.1239"
                                rx="7.64149"
                                fill="white"
                              />
                            </g>
                            <rect
                              x="8.60803"
                              y="26.6602"
                              width="23.4297"
                              height="24.0991"
                              rx="6.11319"
                              fill="#A4C3F3"
                            />
                            <rect
                              x="38.8265"
                              y="31.4727"
                              width="69.6197"
                              height="6.02478"
                              rx="3.01239"
                              fill="#0052CC"
                              fill-opacity="0.25"
                            />
                            <rect
                              x="39.7356"
                              y="41.1797"
                              width="35.915"
                              height="5.34905"
                              rx="2.67452"
                              fill="white"
                            />
                            <rect
                              x="39.7356"
                              y="41.1797"
                              width="35.915"
                              height="5.34905"
                              rx="2.67452"
                              fill="#0052CC"
                              fill-opacity="0.12"
                            />
                            <path
                              d="M13.0433 34.5472C13.0433 33.7701 13.352 33.0249 13.9015 32.4754C14.451 31.9259 15.1963 31.6172 15.9734 31.6172H24.7635C25.5406 31.6172 26.2858 31.9259 26.8353 32.4754C27.3848 33.0249 27.6935 33.7701 27.6935 34.5472V43.3373C27.6935 44.1144 27.3848 44.8597 26.8353 45.4092C26.2858 45.9587 25.5406 46.2674 24.7635 46.2674H15.9734C15.1963 46.2674 14.451 45.9587 13.9015 45.4092C13.352 44.8597 13.0433 44.1144 13.0433 43.3373V34.5472Z"
                              stroke="white"
                              stroke-width="1.5283"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M17.8045 38.2094C18.8159 38.2094 19.6358 37.3895 19.6358 36.3781C19.6358 35.3668 18.8159 34.5469 17.8045 34.5469C16.7932 34.5469 15.9733 35.3668 15.9733 36.3781C15.9733 37.3895 16.7932 38.2094 17.8045 38.2094Z"
                              stroke="white"
                              stroke-width="1.5283"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M22.2186 39.3974L15.9733 46.2676H24.8608C25.6121 46.2676 26.3325 45.9692 26.8638 45.4379C27.395 44.9067 27.6934 44.1862 27.6934 43.435V43.3376C27.6934 42.9962 27.5652 42.8651 27.3345 42.6124L24.3825 39.393C24.2449 39.2429 24.0775 39.1232 23.8911 39.0414C23.7046 38.9596 23.5032 38.9176 23.2996 38.918C23.096 38.9184 22.8947 38.9612 22.7086 39.0438C22.5225 39.1263 22.3556 39.2468 22.2186 39.3974V39.3974Z"
                              stroke="white"
                              stroke-width="1.5283"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <g
                              opacity="0.9"
                              filter="url(#filter2_d_13284_154784)"
                            >
                              <rect
                                x="5.35559"
                                y="103.77"
                                width="123.843"
                                height="30.1239"
                                rx="7.64149"
                                fill="white"
                              />
                            </g>
                            <rect
                              x="8.60803"
                              y="107.117"
                              width="23.4297"
                              height="23.4297"
                              rx="6.11319"
                              fill="#A4C3F3"
                            />
                            <rect
                              opacity="0.5"
                              x="38.9714"
                              y="111.48"
                              width="22.1603"
                              height="5.34905"
                              rx="2.67452"
                              fill="#78A6ED"
                            />
                            <rect
                              x="38.8265"
                              y="120.504"
                              width="60.2478"
                              height="6.02478"
                              rx="3.01239"
                              fill="white"
                            />
                            <rect
                              x="38.8265"
                              y="120.504"
                              width="60.2478"
                              height="6.02478"
                              rx="3.01239"
                              fill="#0052CC"
                              fill-opacity="0.12"
                            />
                            <path
                              d="M13.0433 114.559C13.0433 113.782 13.352 113.037 13.9015 112.487C14.451 111.938 15.1963 111.629 15.9734 111.629H24.7635C25.5406 111.629 26.2858 111.938 26.8353 112.487C27.3848 113.037 27.6935 113.782 27.6935 114.559V123.349C27.6935 124.126 27.3848 124.871 26.8353 125.421C26.2858 125.97 25.5406 126.279 24.7635 126.279H15.9734C15.1963 126.279 14.451 125.97 13.9015 125.421C13.352 124.871 13.0433 124.126 13.0433 123.349V114.559Z"
                              stroke="white"
                              stroke-width="1.5283"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M17.8045 118.221C18.8159 118.221 19.6358 117.401 19.6358 116.39C19.6358 115.378 18.8159 114.559 17.8045 114.559C16.7932 114.559 15.9733 115.378 15.9733 116.39C15.9733 117.401 16.7932 118.221 17.8045 118.221Z"
                              stroke="white"
                              stroke-width="1.5283"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M22.2186 119.409L15.9733 126.279H24.8608C25.6121 126.279 26.3325 125.981 26.8638 125.45C27.395 124.918 27.6934 124.198 27.6934 123.447V123.349C27.6934 123.008 27.5652 122.877 27.3345 122.624L24.3825 119.405C24.2449 119.255 24.0775 119.135 23.8911 119.053C23.7046 118.971 23.5032 118.929 23.2996 118.93C23.096 118.93 22.8947 118.973 22.7086 119.055C22.5225 119.138 22.3556 119.258 22.2186 119.409V119.409Z"
                              stroke="white"
                              stroke-width="1.5283"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <g filter="url(#filter3_d_13284_154784)">
                              <rect
                                x="38.1572"
                                y="64.2734"
                                width="123.843"
                                height="30.1239"
                                rx="7.64149"
                                fill="white"
                              />
                              <rect
                                x="38.5393"
                                y="64.6555"
                                width="123.079"
                                height="29.3598"
                                rx="7.25942"
                                stroke="#0052CC"
                                stroke-opacity="0.5"
                                stroke-width="0.764149"
                              />
                            </g>
                            <rect
                              x="41.4094"
                              y="67.6211"
                              width="23.4297"
                              height="23.4297"
                              rx="6.11319"
                              fill="url(#paint0_linear_13284_154784)"
                            />
                            <rect
                              x="70.9587"
                              y="72.3066"
                              width="70.2891"
                              height="5.35536"
                              rx="2.67768"
                              fill="#0052CC"
                              fill-opacity="0.25"
                            />
                            <rect
                              x="71.0657"
                              y="80.9141"
                              width="33.6226"
                              height="6.11319"
                              rx="3.0566"
                              fill="white"
                            />
                            <rect
                              x="71.0657"
                              y="80.9141"
                              width="33.6226"
                              height="6.11319"
                              rx="3.0566"
                              fill="#0052CC"
                              fill-opacity="0.12"
                            />
                            <path
                              d="M45.7249 75.1175C45.7249 74.3404 46.0336 73.5952 46.583 73.0457C47.1325 72.4962 47.8778 72.1875 48.6549 72.1875H57.445C58.2221 72.1875 58.9674 72.4962 59.5169 73.0457C60.0663 73.5952 60.375 74.3404 60.375 75.1175V83.9076C60.375 84.6847 60.0663 85.43 59.5169 85.9795C58.9674 86.529 58.2221 86.8377 57.445 86.8377H48.6549C47.8778 86.8377 47.1325 86.529 46.583 85.9795C46.0336 85.43 45.7249 84.6847 45.7249 83.9076V75.1175Z"
                              stroke="white"
                              stroke-width="1.5283"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M50.4862 78.7797C51.4976 78.7797 52.3175 77.9598 52.3175 76.9485C52.3175 75.9371 51.4976 75.1172 50.4862 75.1172C49.4748 75.1172 48.6549 75.9371 48.6549 76.9485C48.6549 77.9598 49.4748 78.7797 50.4862 78.7797Z"
                              stroke="white"
                              stroke-width="1.5283"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M54.9003 79.9677L48.6549 86.8379H57.5424C58.2937 86.8379 59.0142 86.5395 59.5454 86.0083C60.0766 85.477 60.3751 84.7566 60.3751 84.0053V83.9079C60.3751 83.5665 60.2469 83.4354 60.0161 83.1827L57.0641 79.9633C56.9265 79.8132 56.7592 79.6935 56.5727 79.6117C56.3863 79.5299 56.1848 79.4879 55.9812 79.4883C55.7776 79.4887 55.5764 79.5315 55.3902 79.6141C55.2041 79.6966 55.0373 79.8171 54.9003 79.9677V79.9677Z"
                              stroke="white"
                              stroke-width="1.5283"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M60.5856 106.045L57.2198 93.3798C56.9192 92.2486 57.9496 91.2132 59.0822 91.5083L71.8723 94.8413C73.0186 95.14 73.404 96.5727 72.5623 97.4062L71.321 98.6354C70.7171 99.2334 70.7171 100.209 71.321 100.807L76.2022 105.641C76.8062 106.239 76.8062 107.215 76.2022 107.813L74.1295 109.865C73.5339 110.455 72.5744 110.455 71.9788 109.865L67.055 104.99C66.4594 104.4 65.4999 104.4 64.9043 104.99L63.138 106.739C62.3034 107.565 60.8873 107.18 60.5856 106.045Z"
                              fill="white"
                              stroke="#0052CC"
                              stroke-width="1.14622"
                            />
                            <defs>
                              <filter
                                id="filter0_dd_13284_154784"
                                x="14.2538"
                                y="15.8858"
                                width="129.476"
                                height="130.91"
                                filterUnits="userSpaceOnUse"
                                color-interpolation-filters="sRGB"
                              >
                                <feFlood
                                  flood-opacity="0"
                                  result="BackgroundImageFix"
                                />
                                <feColorMatrix
                                  in="SourceAlpha"
                                  type="matrix"
                                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                  result="hardAlpha"
                                />
                                <feOffset dy="3.0566" />
                                <feGaussianBlur stdDeviation="1.91037" />
                                <feColorMatrix
                                  type="matrix"
                                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.04 0"
                                />
                                <feBlend
                                  mode="normal"
                                  in2="BackgroundImageFix"
                                  result="effect1_dropShadow_13284_154784"
                                />
                                <feColorMatrix
                                  in="SourceAlpha"
                                  type="matrix"
                                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                  result="hardAlpha"
                                />
                                <feOffset dy="1.5283" />
                                <feGaussianBlur stdDeviation="1.5283" />
                                <feColorMatrix
                                  type="matrix"
                                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.03 0"
                                />
                                <feBlend
                                  mode="normal"
                                  in2="effect1_dropShadow_13284_154784"
                                  result="effect2_dropShadow_13284_154784"
                                />
                                <feBlend
                                  mode="normal"
                                  in="SourceGraphic"
                                  in2="effect2_dropShadow_13284_154784"
                                  result="shape"
                                />
                              </filter>
                              <filter
                                id="filter1_d_13284_154784"
                                x="2.29899"
                                y="22.8394"
                                width="129.956"
                                height="36.2362"
                                filterUnits="userSpaceOnUse"
                                color-interpolation-filters="sRGB"
                              >
                                <feFlood
                                  flood-opacity="0"
                                  result="BackgroundImageFix"
                                />
                                <feColorMatrix
                                  in="SourceAlpha"
                                  type="matrix"
                                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                  result="hardAlpha"
                                />
                                <feMorphology
                                  radius="0.764149"
                                  operator="erode"
                                  in="SourceAlpha"
                                  result="effect1_dropShadow_13284_154784"
                                />
                                <feOffset dy="2.29245" />
                                <feGaussianBlur stdDeviation="1.91037" />
                                <feComposite in2="hardAlpha" operator="out" />
                                <feColorMatrix
                                  type="matrix"
                                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0"
                                />
                                <feBlend
                                  mode="normal"
                                  in2="BackgroundImageFix"
                                  result="effect1_dropShadow_13284_154784"
                                />
                                <feBlend
                                  mode="normal"
                                  in="SourceGraphic"
                                  in2="effect1_dropShadow_13284_154784"
                                  result="shape"
                                />
                              </filter>
                              <filter
                                id="filter2_d_13284_154784"
                                x="2.29899"
                                y="103.005"
                                width="129.956"
                                height="36.2362"
                                filterUnits="userSpaceOnUse"
                                color-interpolation-filters="sRGB"
                              >
                                <feFlood
                                  flood-opacity="0"
                                  result="BackgroundImageFix"
                                />
                                <feColorMatrix
                                  in="SourceAlpha"
                                  type="matrix"
                                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                  result="hardAlpha"
                                />
                                <feMorphology
                                  radius="0.764149"
                                  operator="erode"
                                  in="SourceAlpha"
                                  result="effect1_dropShadow_13284_154784"
                                />
                                <feOffset dy="2.29245" />
                                <feGaussianBlur stdDeviation="1.91037" />
                                <feComposite in2="hardAlpha" operator="out" />
                                <feColorMatrix
                                  type="matrix"
                                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0"
                                />
                                <feBlend
                                  mode="normal"
                                  in2="BackgroundImageFix"
                                  result="effect1_dropShadow_13284_154784"
                                />
                                <feBlend
                                  mode="normal"
                                  in="SourceGraphic"
                                  in2="effect1_dropShadow_13284_154784"
                                  result="shape"
                                />
                              </filter>
                              <filter
                                id="filter3_d_13284_154784"
                                x="35.1006"
                                y="63.5093"
                                width="129.956"
                                height="36.2362"
                                filterUnits="userSpaceOnUse"
                                color-interpolation-filters="sRGB"
                              >
                                <feFlood
                                  flood-opacity="0"
                                  result="BackgroundImageFix"
                                />
                                <feColorMatrix
                                  in="SourceAlpha"
                                  type="matrix"
                                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                  result="hardAlpha"
                                />
                                <feMorphology
                                  radius="0.764149"
                                  operator="erode"
                                  in="SourceAlpha"
                                  result="effect1_dropShadow_13284_154784"
                                />
                                <feOffset dy="2.29245" />
                                <feGaussianBlur stdDeviation="1.91037" />
                                <feComposite in2="hardAlpha" operator="out" />
                                <feColorMatrix
                                  type="matrix"
                                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0"
                                />
                                <feBlend
                                  mode="normal"
                                  in2="BackgroundImageFix"
                                  result="effect1_dropShadow_13284_154784"
                                />
                                <feBlend
                                  mode="normal"
                                  in="SourceGraphic"
                                  in2="effect1_dropShadow_13284_154784"
                                  result="shape"
                                />
                              </filter>
                              <linearGradient
                                id="paint0_linear_13284_154784"
                                x1="53.1243"
                                y1="67.6211"
                                x2="61.8267"
                                y2="91.0508"
                                gradientUnits="userSpaceOnUse"
                              >
                                <stop stop-color="#0052CC" />
                                <stop offset="1" stop-color="#A4C3F3" />
                              </linearGradient>
                            </defs>
                          </svg>
                        </div>
                        <h6 class="font-14 font-w-600 mb-6">
                          No Matches Found
                        </h6>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
      <template v-slot:footer>
        <div class="d-flex justify-content-between">
          <div class="w-100">
            <pager
              :class="'bg-white border-0 p-0'"
              :responsive="true"
              :skip="initialPageState.skip"
              :take="initialPageState.take"
              :total="initialPageState.total"
              :button-count="initialPageState.buttonCount"
              :info="initialPageState.info"
              :type="initialPageState.type"
              :page-sizes="
                initialPageState.pageSizes
                  ? initialPageState.pageSizeDefs
                  : undefined
              "
              :previous-next="initialPageState.previousNext"
              @pagechange="handlePageChange"
            >
            </pager>
          </div>
          <div class="d-flex">
            <div class="me-1">
              <kbutton @click="this.modalHandler" :class="''">Cancel</kbutton>
            </div>
            <div class="ms-1">
              <kbutton
                @click="onSubmitHandler"
                :theme-color="'primary'"
                :class="''"
              >
                Select</kbutton
              >
            </div>
          </div>
        </div>
      </template>
    </modal>
  </div>
</template>

<script>
import { Button } from "@progress/kendo-vue-buttons";
import AppLoader from "@/components/common/AppLoader/AppLoader.vue";
import modal from "@/components/common/AppModal/AppModal.vue";
import { NumericTextBox } from "@progress/kendo-vue-inputs";
import { DatePicker } from "@progress/kendo-vue-dateinputs";
import { Input } from "@progress/kendo-vue-inputs";
import { DropDownList } from "@progress/kendo-vue-dropdowns";
import { MaskedTextBox } from "@progress/kendo-vue-inputs";
import { Checkbox } from "@progress/kendo-vue-inputs";
import AppSnackBar from "@/components/common/AppSnackBar/AppSnackBar.vue";
import { Grid } from "@progress/kendo-vue-grid";
import { RadioButton } from "@progress/kendo-vue-inputs";

import { getProductWidgetDynamicQueriesListService } from "@/services/clubDetails/productWidget.service";
import { Pager } from "@progress/kendo-vue-data-tools";
import { formatDateTime } from "@/utils/helper";

export default {
  name: "productListsModal",
  props: {
    widgetElement: {
      type: Object,
      default: () => {},
    },
  },
  components: {
    kbutton: Button,
    "app-loader": AppLoader,
    modal,
    numericTextBox: NumericTextBox,
    datePicker: DatePicker,
    "k-input": Input,
    dropdownlist: DropDownList,
    MaskedTextBox: MaskedTextBox,
    checkbox: Checkbox,
    AppSnackBar,
    grid: Grid,
    pager: Pager,
    radiobutton: RadioButton,
  },
  data() {
    return {
      isProductListLoading: false,
      productLists: [],
      searchText: "",
      selectedProductList: {},
      clubId: this.$route.query.club_id,

      initialPageState: {
        skip: 0,
        take: 10,
        buttonCount: 5,
        type: "numeric",
        info: true,
        previousNext: true,
        pageSizes: true,
        total: 100,
        pageSizeDefs: [10, 50, 100, 200, 500, 1000],
      },
    };
  },
  mounted() {
    this.selectedProductList = window.structuredClone(
      this.widgetElement?.dynamicProductsList || {}
    );

    const payload = this.payloadGenerator();
    this.getProductWidgetDynamicQueriesList(payload);
  },
  computed: {},
  methods: {
    formatDateTime,
    getProductWidgetDynamicQueriesList(payload) {
      this.isProductListLoading = true;
      getProductWidgetDynamicQueriesListService(payload)
        .then((response) => {
          this.isProductListLoading = false;
          this.productLists = response.data.data?.items;
          this.initialPageState.total = response.data.data?.size;
        })
        .catch((error) => {
          this.isProductListLoading = false;
        });
    },
    productListSelectionHandler(productList) {
      this.selectedProductList = productList;
    },
    debounceSearch(event) {
      clearTimeout(this.debounce);
      this.debounce = setTimeout(() => {
        this.getProductWidgetDynamicQueriesList(this.payloadGenerator());
      }, 600);
    },
    payloadGenerator() {
      return {
        clubId: this.clubId,
        limit: this.initialPageState.take,
        offset: this.initialPageState.skip,
        searchText: this.searchText,
      };
    },
    handleValueChange(value, key) {
      const payload = this.payloadGenerator();
      this.getProductWidgetDynamicQueriesList(payload);
    },
    modalHandler() {
      this.$emit("close", false);
    },
    onSubmitHandler() {
      this.modalHandler();
      this.$emit("addDynamicProductsList", this.selectedProductList);
    },
    handlePageChange: function (event) {
      this.initialPageState.skip = event.skip;
      this.initialPageState.take = event.take;
      const payload = this.payloadGenerator();
      this.getProductWidgetDynamicQueriesList(payload);
    },
  },
};
</script>

<style lang="scss">
@import "./productListsModal.scss";
</style>
