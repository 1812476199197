<template>
    <svg width="33" height="32" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M16.667 0C7.83128 0 0.666992 7.16429 0.666992 16C0.666992 24.8357 7.83128 32 16.667 32C25.5027 32 32.667 24.8357 32.667 16C32.667 7.16429 25.5027 0 16.667 0ZM16.667 6.85714H14.3813V18.2857H25.8098V16H16.667V6.85714ZM2.95271 16C2.95271 23.5643 9.10271 29.7143 16.667 29.7143C24.2313 29.7143 30.3813 23.5643 30.3813 16C30.3813 8.43571 24.2313 2.28571 16.667 2.28571C9.10271 2.28571 2.95271 8.43571 2.95271 16Z" fill="#2B354C" fill-opacity="0.65"/>
    </svg>
</template>
<script>
    export default {
        name: "timerIcon"
    }
</script>
<style scoped></style>