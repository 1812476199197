<template>
    <div class="widget-accordion-body">
        <div class="widget-input-item">
            <div class="d-flex align-items-center justify-content-between">
                <label class="b-form-label">Border <small>(PX)</small></label>
            </div>
            <select class="b-form-select" v-model="column.propertiesArray[this.column.properties.selectedVariationIndex]
                    .tableProperties.tableBorderSelectedStyle.borderStyle
                ">
                <option v-for="data in column.propertiesArray[
                    column.properties.selectedVariationIndex
                ].tableProperties.borderStyleOptions" :key="data.value" :value="data.value">
                    {{ data.label }}
                </option>
            </select>
        </div>
        <div class="widget-input-item">
            <div class="d-flex justify-content-between align-items-center">
                <label class="b-form-label mb-0">Borders Color</label>
                <ColorPicker :show-clear-button="false" :gradient-settings="{ opacity: false }" v-model="column.propertiesArray[
                        this.column.properties.selectedVariationIndex
                    ].tableProperties.tableBorderSelectedStyle.borderColor
                    " />
            </div>
        </div>
        <div class="widget-input-item">
            <div class="widget-input-item">
                <div class="d-flex justify-content-between align-items-center">
                    <label class="b-form-label mb-0">Headers Background</label>
                    <k-switch @change="headersSwitchStatushandler" :value="draggable_editor_data[this.index][this.columnType][
                            this.childIndex
                        ].propertiesArray[this.column.properties.selectedVariationIndex]
                            .tableProperties.headerStyle.isVisible
                        " :size="'small'" />
                </div>
            </div>
            <div class="d-flex justify-content-between align-items-center">
                <label class="b-form-label mb-0">Headers Background Color</label>
                <ColorPicker :show-clear-button="false" :gradient-settings="{ opacity: false }" v-model="column.propertiesArray[
                        this.column.properties.selectedVariationIndex
                    ].tableProperties.headerStyle.backgroundColor
                    " />
            </div>
        </div>

        <div class="widget-input-item">
            <label>Cell padding</label>
            <div class="container-padding">
                <label class="b-form-label">All side <small>(PX)</small></label>
                <div :class="isLock ? 'inputs-button lock' : 'inputs-button'">
                    <div class="inputs" v-if="!isLock">
                        <input type="text" class="b-form-field" placeholder="5px" @focusout="paddingTopWidthHandler($event)"
                            v-model="column.propertiesArray[
                                    this.column.properties.selectedVariationIndex
                                ].tableProperties.table_cell_padding_style.paddingTop
                                " />
                        <input type="text" class="b-form-field" placeholder="5px"
                            @focusout="paddingRightWidthHandler($event)" v-model="column.propertiesArray[
                                    this.column.properties.selectedVariationIndex
                                ].tableProperties.table_cell_padding_style.paddingRight
                                " />
                        <input type="text" class="b-form-field" placeholder="5px"
                            @focusout="paddingBottomWidthHandler($event)" v-model="column.propertiesArray[
                                    this.column.properties.selectedVariationIndex
                                ].tableProperties.table_cell_padding_style.paddingBottom
                                " />
                        <input type="text" class="b-form-field" placeholder="5px"
                            @focusout="paddingLeftWidthHandler($event)" v-model="column.propertiesArray[
                                    this.column.properties.selectedVariationIndex
                                ].tableProperties.table_cell_padding_style.paddingLeft
                                " />
                    </div>
                    <div class="inputs" v-if="isLock">
                        <input @focusout="changeFunc($event)" v-model="isLockData" type="text" class="b-form-field"
                            placeholder="5px" />
                    </div>

                    <button @click="onClickLockBtn($event)" title="Lock" :class="isLock ? 'lock' : ''">
                        <span v-if="!isLock">
                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd"
                                    d="M7 7H13C13.55 7 14 7.45 14 8V14C14 14.55 13.55 15 13 15H5C4.45 15 4 14.55 4 14V11V8C4 7.45 4.45 7 5 7H6V4C6 2.89688 5.10313 2 4 2C2.89688 2 2 2.89688 2 4V7V8H1V4C1 2.34375 2.34375 1 4 1C5.65625 1 7 2.34375 7 4V7ZM8 11C8 11.5531 8.44688 12 9 12C9.55313 12 10 11.5531 10 11C10 10.4469 9.55313 10 9 10C8.44688 10 8 10.4469 8 11Z"
                                    fill="#404A61" />
                            </svg>
                        </span>
                        <span v-if="isLock">
                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd"
                                    d="M12 7H11V4C11 2.34375 9.65625 1 8 1C6.34375 1 5 2.34375 5 4V7H4C3.45 7 3 7.45 3 8V14C3 14.55 3.45 15 4 15H12C12.55 15 13 14.55 13 14V8C13 7.45 12.55 7 12 7ZM6 4C6 2.89688 6.89687 2 8 2C9.10313 2 10 2.89688 10 4V7H6V4ZM7 11C7 11.5531 7.44688 12 8 12C8.55312 12 9 11.5531 9 11C9 10.4469 8.55312 10 8 10C7.44688 10 7 10.4469 7 11Z"
                                    fill="#404A61" />
                            </svg>
                        </span>
                    </button>
                </div>
                <div class="inputs-buttons-name d-flex" v-if="!isLock">
                    <span>Top</span>
                    <span>Right</span>
                    <span>Bottom</span>
                    <span>Left</span>
                </div>
            </div>
        </div>

        <div v-if="draggable_editor_data[this.index][this.columnType][this.childIndex]
                .propertiesArray[this.column.properties.selectedVariationIndex]
                .tableProperties.isDynamic
            " class="widget-input-item">

            <div class="widget-input-item">
                <div class="d-flex justify-content-between align-items-center">
                    <label class="b-form-label mb-0">Static Row Background</label>
                    <k-switch @change="staticRowSwitchStatushandler" :value="draggable_editor_data[this.index][this.columnType][
                            this.childIndex
                        ].propertiesArray[this.column.properties.selectedVariationIndex]
                            .tableProperties.staticRowStyle.isVisible
                        " :size="'small'" />
                </div>
            </div>
            <div class="d-flex justify-content-between align-items-center">
                <label class="b-form-label mb-0">Static Row Background Color</label>
                <ColorPicker :show-clear-button="false" :gradient-settings="{ opacity: false }" v-model="draggable_editor_data[this.index][this.columnType][this.childIndex]
                        .propertiesArray[this.column.properties.selectedVariationIndex]
                        .tableProperties.staticRowStyle.backgroundColor
                    " />
            </div>
        </div>

        <div class="widget-input-item">
            <div class="widget-input-item">
                <div class="d-flex justify-content-between align-items-center">
                    <label class="b-form-label mb-0">Full Table Background</label>
                    <k-switch @change="fullTableSwitchStatushandler" :value="column.propertiesArray[
                            this.column.properties.selectedVariationIndex
                        ].tableProperties.fullTableStyle.isVisible
                        " :size="'small'" />
                </div>
            </div>
            <div class="d-flex justify-content-between align-items-center">
                <label class="b-form-label mb-0">Full Table Background Color</label>
                <ColorPicker :show-clear-button="false" :gradient-settings="{ opacity: false }" v-model="column.propertiesArray[
                        this.column.properties.selectedVariationIndex
                    ].tableProperties.fullTableStyle.backgroundColor
                    " />
            </div>
        </div>
    </div>
</template>

<script>
import paddingComponent from "../../common-components/paddingComponent.vue";
import { ColorPicker, Switch } from "@progress/kendo-vue-inputs";
import borderWidthComponent from "../../common-components/borderWidthComponent.vue";
import { pxInputHandler } from "../../helpers/utility";
export default {
    inject: ["draggable_editor_data"],
    name: "tablePreferenceControl",
    components: {
        ColorPicker,
        "k-switch": Switch,
    },
    props: {
        column: Object,
        index: Number,
        childIndex: Number,
        columnType: String,
        except: Array,
    },
    data() {
        return {
            isLock: false,
            isLockData: "",
            isHeaderCheckedLocal: false,
        };
    },
    mounted() {
        this.isLock = this.draggable_editor_data[this.index][this.columnType][
            this.childIndex
        ].propertiesArray[
            this.column.properties.selectedVariationIndex
        ]?.tableProperties.isPaddingLock;
        if (this.isLock)
            this.isLockData = this.draggable_editor_data[this.index][this.columnType][
                this.childIndex
            ].propertiesArray[
                this.column.properties.selectedVariationIndex
            ].tableProperties.table_cell_padding_style.paddingTop;

        if (
            this.draggable_editor_data[this.index][this.columnType][this.childIndex]
                .propertiesArray[this.column.properties.selectedVariationIndex]
                .tableProperties.isDynamic
        ) {
            this.isHeaderCheckedLocal = this.draggable_editor_data[this.index][
                this.columnType
            ][this.childIndex].propertiesArray[
                this.column.properties.selectedVariationIndex
            ].tableProperties.dynamicTable.isHeaderChecked;
        } else {
            this.isHeaderCheckedLocal = this.draggable_editor_data[this.index][
                this.columnType
            ][this.childIndex].propertiesArray[
                this.column.properties.selectedVariationIndex
            ].tableProperties.staticTable.isHeaderChecked;
        }
    },
    methods: {
        switchStatushandler(e) {
            if (
                this.draggable_editor_data[this.index][this.columnType][this.childIndex]
                    .propertiesArray[this.column.properties.selectedVariationIndex]
                    .tableProperties.isDynamic
            ) {
                this.draggable_editor_data[this.index][this.columnType][
                    this.childIndex
                ].propertiesArray[
                    this.column.properties.selectedVariationIndex
                ].tableProperties.dynamicTable.isHeaderChecked = e.target.value;
            } else {
                this.draggable_editor_data[this.index][this.columnType][
                    this.childIndex
                ].propertiesArray[
                    this.column.properties.selectedVariationIndex
                ].tableProperties.staticTable.isHeaderChecked = e.target.value;
            }
            this.isHeaderCheckedLocal = e.target.value;
        },
        switchIsStaticRowStatushandler(e) {
            if (
                this.draggable_editor_data[this.index][this.columnType][this.childIndex]
                    .propertiesArray[this.column.properties.selectedVariationIndex]
                    .tableProperties.isDynamic
            ) {
                this.draggable_editor_data[this.index][this.columnType][
                    this.childIndex
                ].propertiesArray[
                    this.column.properties.selectedVariationIndex
                ].tableProperties.dynamicTable.isStaticRowChecked = e.target.value;
            }
        },
        headersSwitchStatushandler(e) {
            this.draggable_editor_data[this.index][this.columnType][
                this.childIndex
            ].propertiesArray[
                this.column.properties.selectedVariationIndex
            ].tableProperties.headerStyle.isVisible = e.target.value;
        },
        fullTableSwitchStatushandler(e) {
            this.draggable_editor_data[this.index][this.columnType][
                this.childIndex
            ].propertiesArray[
                this.column.properties.selectedVariationIndex
            ].tableProperties.fullTableStyle.isVisible = e.target.value;
        },
        staticRowSwitchStatushandler(e) {
            this.draggable_editor_data[this.index][this.columnType][
                this.childIndex
            ].propertiesArray[
                this.column.properties.selectedVariationIndex
            ].tableProperties.staticRowStyle.isVisible = e.target.value;
        },
        onClickLockBtn(e) {
            this.isLock = !this.isLock;

            this.paddingOnClickLockBtn(
                this.index,
                this.columnType,
                this.childIndex,
                this.column.properties.selectedVariationIndex,
                e
            );
        },

        paddingOnClickLockBtn(index, columnType, childIndex, variationIndex, e) {
            this.draggable_editor_data[index][columnType][childIndex].propertiesArray[
                variationIndex
            ].tableProperties.isPaddingLock = this.isLock;
            if (
                this.draggable_editor_data[index][columnType][childIndex]
                    .propertiesArray[variationIndex].tableProperties
                    .table_cell_padding_style.paddingTop
            ) {
                this.isLockData = this.draggable_editor_data[index][columnType][
                    childIndex
                ].propertiesArray[
                    variationIndex
                ].tableProperties.table_cell_padding_style.paddingTop;
                this.draggable_editor_data[index][columnType][
                    childIndex
                ].propertiesArray[
                    variationIndex
                ].tableProperties.table_cell_padding_style.paddingRight = this.draggable_editor_data[
                    index
                ][columnType][childIndex].propertiesArray[
                    variationIndex
                ].tableProperties.table_cell_padding_style.paddingTop;
                this.draggable_editor_data[index][columnType][
                    childIndex
                ].propertiesArray[
                    variationIndex
                ].tableProperties.table_cell_padding_style.paddingBottom = this.draggable_editor_data[
                    index
                ][columnType][childIndex].propertiesArray[
                    variationIndex
                ].tableProperties.table_cell_padding_style.paddingTop;
                this.draggable_editor_data[index][columnType][
                    childIndex
                ].propertiesArray[
                    variationIndex
                ].tableProperties.table_cell_padding_style.paddingLeft = this.draggable_editor_data[
                    index
                ][columnType][childIndex].propertiesArray[
                    variationIndex
                ].tableProperties.table_cell_padding_style.paddingTop;
            } else {
                this.draggable_editor_data[index][columnType][
                    childIndex
                ].propertiesArray[
                    variationIndex
                ].tableProperties.table_cell_padding_style.paddingTop = "10px";
                this.draggable_editor_data[index][columnType][
                    childIndex
                ].propertiesArray[
                    variationIndex
                ].tableProperties.table_cell_padding_style.paddingRight = "10px";
                this.draggable_editor_data[index][columnType][
                    childIndex
                ].propertiesArray[
                    variationIndex
                ].tableProperties.table_cell_padding_style.paddingBottom = "10px";
                this.draggable_editor_data[index][columnType][
                    childIndex
                ].propertiesArray[
                    variationIndex
                ].tableProperties.table_cell_padding_style.paddingLeft = "10px";
            }
        },

        changeFunc(e) {
            this.lockHandler(
                this.index,
                this.columnType,
                this.childIndex,
                this.column.properties.selectedVariationIndex,
                e
            );
        },
        lockHandler(index, columnType, childIndex, variationIndex, e) {
            this.isLockData = pxInputHandler(e.target.value);
            this.draggable_editor_data[index][columnType][childIndex].propertiesArray[
                variationIndex
            ].tableProperties.table_cell_padding_style.paddingTop = pxInputHandler(
                e.target.value
            );
            this.draggable_editor_data[index][columnType][childIndex].propertiesArray[
                variationIndex
            ].tableProperties.table_cell_padding_style.paddingRight = pxInputHandler(
                e.target.value
            );
            this.draggable_editor_data[index][columnType][childIndex].propertiesArray[
                variationIndex
            ].tableProperties.table_cell_padding_style.paddingBottom = pxInputHandler(
                e.target.value
            );
            this.draggable_editor_data[index][columnType][childIndex].propertiesArray[
                variationIndex
            ].tableProperties.table_cell_padding_style.paddingLeft = pxInputHandler(
                e.target.value
            );
            // this.draggable_editor_data[index][columnType][childIndex].tableProperties[
            //   variationIndex
            // ].style.textAlign = "center";
        },

        emitFunction(type, e, isFirstValue, isSecondValue) {
            this.$emit("paddingComponent", type, e, isFirstValue);
        },

        paddingLeftWidthHandler(e) {
            this.draggable_editor_data[this.index][this.columnType][
                this.childIndex
            ].propertiesArray[
                this.column.properties.selectedVariationIndex
            ].tableProperties.table_cell_padding_style.paddingLeft = pxInputHandler(
                e.target.value
            );
        },
        paddingBottomWidthHandler(e) {
            this.draggable_editor_data[this.index][this.columnType][
                this.childIndex
            ].propertiesArray[
                this.column.properties.selectedVariationIndex
            ].tableProperties.table_cell_padding_style.paddingBottom = pxInputHandler(
                e.target.value
            );
        },
        paddingRightWidthHandler(e) {
            this.draggable_editor_data[this.index][this.columnType][
                this.childIndex
            ].propertiesArray[
                this.column.properties.selectedVariationIndex
            ].tableProperties.table_cell_padding_style.paddingRight = pxInputHandler(
                e.target.value
            );
        },
        paddingTopWidthHandler(e) {
            this.draggable_editor_data[this.index][this.columnType][
                this.childIndex
            ].propertiesArray[
                this.column.properties.selectedVariationIndex
            ].tableProperties.table_cell_padding_style.paddingTop = pxInputHandler(
                e.target.value
            );
        },
    },
};
</script>

<style scoped></style>
