<div class="builder-top-nav bg-white d-flex justify-content-between">
    <div class="d-flex align-items-center">
        <div class="d-flex justify-content-start align-content-center">
            <h6 class="font-16 mb-0 px-3">
                Preview Mode
            </h6>
        </div>
    </div>
    <div class="mode-switcher d-flex align-items-center">
        <div class="desktop-mobile-switcher">
            <k-button :class="templatePreviewType == 'desktop' ? 'active' : ''" @click="toggleView('desktop')"
                :fill-mode="'flat'">Desktop</k-button>
            <k-button :class="templatePreviewType == 'mobile' ? 'active' : ''" @click="toggleView('mobile')"
                :fill-mode="'flat'">Mobile</k-button>
        </div>
    </div>
    <div class="d-flex align-items-center pe-3">
        <div class="preview-save d-flex">
            <k-button @click="sendTestButtonHandler(true)" :fill-mode="'flat'" :class="'h-38 me-3 '">Send Test
            </k-button>
            <k-button @click="doneButtonHandler" :theme-color="'primary'" :class="'h-38'">Done</k-button>
        </div>
    </div>
</div>
<div class="preview-test-main-body d-flex justify-content-between">
    <div :class="['preview-test-main-body-content px-32 py-16', this.templatePreviewType ==  'mobile' ? 'mobile-responsive' : '']">
        <div class="box bg-white">
            <div class="head">
                <span class="px-12">
                    <svg width="25" height="5" viewBox="0 0 25 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="2.5" cy="2.5" r="2.5" fill="#0052CC" fill-opacity="0.5" />
                        <circle cx="12.5" cy="2.5" r="2.5" fill="#0052CC" fill-opacity="0.5" />
                        <circle cx="22.5" cy="2.5" r="2.5" fill="#0052CC" fill-opacity="0.5" />
                    </svg>
                </span>
            </div>
            <div class="body preview-h-100vh">
                <EmailTemplateMobileAndDesktopPreview :previewType="templatePreviewType" ref="previewRender"
                    :draggable_editor_data=" draggable_editor_data" :global_style="global_style"
                    :templateData="templateData" :catchTemplateHtml="catchTemplateHtml" :link_style="link_style"
                    :wrapperContainerStyle="wrapperContainerStyle" />
            </div>
        </div>
    </div>
    <div class="sidebar bg-white py-24 px-16">
        <div class="preview-test-sidebar-widget">
            <label>Subject Line</label>
            <p>{{campaignData?.campaignSubject?.[0]?.subjectLine || 'No subject line defined'}}</p>
        </div>

        <!-- <div v-if="campaignData?.campaignSubject?.length > 1 && !isCampaignSubjectLineViewMore"
            class="preview-test-sidebar-widget">
            <label>Subject Line 1</label>
            <p>{{campaignData?.campaignSubject[0]?.subjectLine || 'No subject line defined'}}</p>
            <k-button @click="toggleSubjectLineViewMore" :fill-mode="'flat'" :class="'h-38'" :size="'medium'" :icon="''"
                :theme-color="'primary'">View more
            </k-button>
        </div>

        <div v-if="campaignData?.campaignSubject?.length > 1 && isCampaignSubjectLineViewMore"
            v-for="(item, index) in campaignData?.campaignSubject" class="preview-test-sidebar-widget">
            <label>Subject Line {{index + 1}}</label>
            <p>{{item?.subjectLine || 'No subject line defined'}}</p>
            <k-button v-if="index == 0" @click="toggleSubjectLineViewMore" :fill-mode="'flat'" :class="'h-38'"
                :size="'medium'" :icon="''" :theme-color="'primary'">
                View less
            </k-button>
        </div> -->


        <div class="preview-test-sidebar-widget">
            <label>Preview Text</label>
            <p>{{campaignData?.previewText || 'No preview text defined.'}}</p>
        </div>
        <div class="preview-test-sidebar-widget">
            <label>Sender Name</label>
            <p>{{campaignData?.senderName || 'No sender name defined.'}}</p>
        </div>
        <div class="preview-test-sidebar-widget">
            <label>Sender Email Address</label>
            <p>{{campaignData?.senderEmail || 'No sender email defined.'}}</p>
        </div>
        <div class="preview-test-sidebar-widget">
            <label>Recipients</label>
            <ul v-for="(list, index) in campaignData?.campaignAudienceList"
                v-if="campaignData?.campaignAudienceList?.length">
                <li>{{list?.audienceListName}} (List)</li>
            </ul>
            <ul v-for="(segment, index) in campaignData?.campaignSegment" v-if="campaignData?.campaignSegment?.length">
                <li>{{segment?.segmentName}} (Segment)</li>
            </ul>
            <h6 v-if="campaignData?.campaignSegment?.length === 0 && campaignData?.campaignAudienceList?.length === 0"
                class="font-16 opacity-5">No recipients selected.</h6>
        </div>
    </div>
</div>



<SendTestModalComponent :onConfirm="onConfirmSendEmail" :onCancel="sendTestButtonHandler"
    v-if="sendTestModalVisibility" />

<app-loader v-if="isLoading"></app-loader>

<AppSnackBar :message="notifyMessage" :notificationType="notifyType" v-if="isNotify"></AppSnackBar>