<template>
  <div>
    <div
      v-if="isBorderTypeVisible || isBorderColorVisible || isBorderWidthVisible"
      class="widget-input-item"
    >
      <div class="">
        <div
          v-if="
            isBorderTypeVisible &&
            column.propertiesArray[column.properties.selectedVariationIndex][
              this.borderStateKey
            ]
          "
          class="widget-input-item wii-border-style"
        >
          <div class="d-flex align-items-center justify-content-between">
            <label class="b-form-label">Border Style</label>
          </div>
          <select
            class="b-form-select"
            v-model="
              column.propertiesArray[column.properties.selectedVariationIndex][
                this.borderStateKey
              ].borderStyle
            "
          >
            <option
              v-for="data in column.propertiesArray[
                column.properties.selectedVariationIndex
              ].borderStyleOptions"
              :key="data.value"
              :value="data.value"
            >
              {{ data.label }}
            </option>
          </select>
        </div>

        <div
          v-if="
            isBorderColorVisible &&
            column.propertiesArray[column.properties.selectedVariationIndex][
              this.borderStateKey
            ]
          "
          class="widget-input-item wii-border-color"
        >
          <div class="d-flex justify-content-between align-items-center">
            <label class="b-form-label mb-0">Border Color</label>
            <ColorPicker
              :show-clear-button="false"
              :gradient-settings="{ opacity: false }"
              v-model="
                column.propertiesArray[
                  column.properties.selectedVariationIndex
                ][this.borderStateKey].borderColor
              "
            />
          </div>
        </div>

        <div
          v-if="
            isBorderWidthVisible &&
            column.propertiesArray[column.properties.selectedVariationIndex][
              this.borderStateKey
            ]
          "
        >
          <borderWidthComponent
            :column="column"
            :columnType="columnType"
            :index="index"
            :childIndex="childIndex"
            :stateKey="borderStateKey"
            :borderWidthLocked="borderWidthLocked"
          >
          </borderWidthComponent>
        </div>
      </div>
    </div>
    <div v-if="isPaddingVisible" class="widget-input-item">
      <div class="d-flex align-items-center justify-content-between">
        <label class="b-form-label">Item Padding</label>
      </div>
      <div class="">
        <paddingComponent
          :column="column"
          :columnType="columnType"
          :index="index"
          :childIndex="childIndex"
          :stateKey="paddingStateKey"
        />
      </div>
    </div>
  </div>
</template>
  
  <script>
import paddingComponent from "../common-components/paddingComponent.vue";
import { ColorPicker } from "@progress/kendo-vue-inputs";
import borderWidthComponent from "../common-components/borderWidthComponent.vue";

export default {
  inject: ["draggable_editor_data"],
  name: "productItemSettings",
  components: {
    borderWidthComponent,
    paddingComponent,
    ColorPicker,
  },
  props: {
    column: Object,
    index: Number,
    childIndex: Number,
    columnType: String,
    except: Array,
    paddingStateKey: {
      type: String,
      default: "borderStyles",
    },
    borderStateKey: {
      type: String,
      default: "container_style",
    },
    borderWidthLocked: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isBackgroundColorVisible: true,
      isBorderTypeVisible: true,
      isBorderColorVisible: true,
      isBorderWidthVisible: true,
      isPaddingVisible: true,
    };
  },
  mounted() {
    this.isBackgroundColorVisible = this.except?.includes("backgroundColor")
      ? false
      : true;
    this.isBorderTypeVisible = this.except?.includes("borderType")
      ? false
      : true;
    this.isBorderColorVisible = this.except?.includes("borderColor")
      ? false
      : true;
    this.isBorderWidthVisible = this.except?.includes("borderWidth")
      ? false
      : true;
    this.isPaddingVisible = this.except?.includes("padding") ? false : true;
  },
  methods: {},
};
</script>
  
  <style scoped></style>
  