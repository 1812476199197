<template>
  <div class="d-flex justify-content-between">
    <label class="b-form-label">Footer</label>
    <Switch
      :size="'small'"
      v-model="templateData.footer.status"
      @change="onChangeSwitch"
    />
  </div>
  <div v-if="templateData.footer.status" class="widget-input-item">
    <div class="d-flex justify-content-between">
      <p class="font-12 l-height-20 mt-2 mb-4">
        We may automatically add your contact info into the Footer Block.
        Contact information is required in all campaigns to comply with the
        CAN-SPAM Act and international spam law.
      </p>
    </div>
  </div>
  <div v-if="templateData.footer.status" class="widget-input-item">
    <div class="d-flex justify-content-between align-items-center">
      <label class="b-form-label mb-0">Container Color</label>
      <ColorPicker
        :gradient-settings="{ opacity: false }"
        v-model="templateData.footer.containerColor"
      />
    </div>
  </div>
  <div v-if="templateData.footer.status" class="widget-input-item">
    <div class="d-flex justify-content-between align-items-center">
      <label class="b-form-label mb-0">Row Color</label>
      <ColorPicker
        :gradient-settings="{ opacity: false }"
        v-model="templateData.footer.rowColor"
      />
    </div>
  </div>
  <div v-if="templateData.footer.status">
    <div class="d-flex justify-content-between">
      <label class="b-form-label">Footer Information</label>
    </div>
    <div class="widget-editor">
      <TextEditorComponent
        :classes="'builder-editor'"
        :defaultContent="data.content"
        :ktools="ktools"
        :lineHeight="lineHeight"
        @execute="onExecute"
        @change="(e) => onChangeKeditor(e)"
      />

      <!-- <KEditor
        :class="'builder-editor'"
        :tools="ktools"
        :default-content="data.content"
        @change="(e) => onChangeKeditor(e)"
        :paste-html="onPasteHtml"
        @execute="onExecute"
        @clearDefaultValue="clearDefaultValue"
      >
        <template v-slot:MyFontSizeTool="{ props }">
          <div>
            <fontsize
              v-bind="props"
              :style="{
                width: '150px',
                ...props.style,
              }"
            />
          </div>
        </template>
        <template v-slot:FontNameTool="{ props }">
          <div>
            <fontname
              v-bind="props"
              :style="{
                width: '150px',
                ...props.style,
              }"
            />
          </div>
        </template>
        <template v-slot:MyLineHeightTool="{ props }">
          <myLineHeightTool :currentHeight="lineHeight" :view="props.view" />
        </template>
        <template v-slot:MyBackColor="{ props }">
          <customColorPicker v-bind="props" :settings="props.settings" />
        </template>
        <template v-slot:MyForeColor="{ props }">
          <customColorPicker v-bind="props" :settings="props.settings" />
        </template>
        <template v-slot:macroDropdownComponent="{ props }">
          <macroDropdownComponent :view="props.view" />
        </template>
      </KEditor> -->
    </div>
  </div>
</template>

<script>
import kendoTools from "../helpers/kendoTools.js";
import { Switch } from "@progress/kendo-vue-inputs";
import macroDropdownComponent from "../common-components/macroDropdownComponent.vue";
import { Editor, FontName, EditorUtils } from "@progress/kendo-vue-editor";
import { countryList } from "../helpers/countryList";
import { ColorPicker } from "@progress/kendo-vue-inputs";
import customColorPicker from "../common-components/customColorPicker.vue";
import myLineHeightTool from "../common-components/myLineHeightTool.vue";
import { onPasteHtmlIntoEditor } from "@/utils/helper";
import TextEditorComponent from "../common-components/TextEditorComponent.vue";

const {
  pasteCleanup,
  sanitize,
  sanitizeClassAttr,
  sanitizeStyleAttr,
  removeAttribute,
  replaceImageSourcesFromRtf,
} = EditorUtils;
const pasteSettings = {
  attributes: {
    class: sanitizeClassAttr,
    width: () => {},
    height: () => {},
    src: () => {},
    "*": removeAttribute,
  },
};
export default {
  name: "footerContent",
  components: {
    KEditor: Editor,
    macroDropdownComponent,
    Switch,
    ColorPicker,
    customColorPicker,
    fontsize: FontName,
    fontname: FontName,
    myLineHeightTool,
    TextEditorComponent,
  },
  inject: ["templateData"],
  props: {
    data: Object,
  },
  data() {
    return {
      color: null,
      defaultValue: null,
      ktools: [...kendoTools, { render: "macroDropdownComponent" }],
      countryListData: countryList,
      lineHeight: null,
    };
  },
  methods: {
    onChangeKeditor(e) {
      this.templateData.footer.content = e;
    },
    onChangeSwitch(e) {
      this.templateData.footer.status = true;
      this.templateData.footer.isRemovingFooterModalClose = e.target.value;
    },
    onPasteHtml(e) {
      return onPasteHtmlIntoEditor(e);
    },
    onExecute(event) {
      setTimeout(() => {
        this.lineHeight = Number(
          EditorUtils.getInlineStyles(event.target.view.state, {
            name: "line-height",
            value: /^.+$/,
          })[0]
        );
      });

      const { doc, selection } = event.transaction;
      let selectionHtml = "";

      if (!selection.empty) {
        const node = doc.cut(selection.from, selection.to);
        selectionHtml = EditorUtils.getHtml({
          doc: node,
          schema: node.type.schema,
        });
      }
    },
    getSubstring(str, char1, char2) {
      return str.substring(str.indexOf(char1) + 1, str.lastIndexOf(char2));
    },
    clearDefaultValue(val) {
      this.defaultValue = Number(val);
    },
  },
};
</script>

<style scoped></style>
