<template>
  <div>
    <modal
      :class="'max-height-100-145'"
      :title="'Create Product List'"
      :isLoading="addUserLoading"
      :handler="true"
      @modalHandler="this.modalHandler"
      :cssClass="'modal-xl-950'"
    >
      <template v-slot:content>
        <app-loader v-if="isLoading || isProductMetricLoading" />
        <div class="pt-0 px-2 pb-4">
          <div class="d-flex gap-4">
            <div class="k-form-field mb-2 w-100">
              <label class="font-16">Product List Name</label>
              <k-input
                type="text"
                placeholder="Enter a list name"
                aria-required="true"
                v-model="parameterName"
              >
              </k-input>
              <div class="error-msg">{{ errorMessages?.parameterName }}</div>
            </div>
            <div class="mb-2 w-100">
              <label class="font-16 w-100">Select Product Metric</label>
              <dropdownlist
                :class="'w-100-vsm mb-24'"
                :style="{ width: '100%' }"
                :data-items="productMetric"
                :default-item="{ metricName: 'Select', metricValue: 'select' }"
                v-model="specialFilter"
                :text-field="'metricName'"
                :data-item-key="'metricValue'"
              >
              </dropdownlist>
              <div class="error-msg">{{ errorMessages?.specialFilter }}</div>
            </div>
          </div>
          <div>
            <label>Filter by Conditions</label>
            <div class="radius-4 border-normal-1 p-20">
              <div
                v-for="(params, paramIndex) in parameterList"
                :key="params.id"
                class=""
              >
                <p v-if="paramIndex != 0" class="font-16 font-w-500 my-8">
                  And
                </p>
                <div class="d-flex gap-3">
                  <dropdownlist
                    :class="'w-100-vsm'"
                    :style="{ width: '100%' }"
                    :data-items="conditionsType"
                    v-model="params.parameterName"
                    @change="onChangeConditionType($event.value, paramIndex)"
                  >
                  </dropdownlist>
                  <dropdownlist
                    :style="{ width: '100%' }"
                    :data-items="params.parameterName === 'Category' ? categoryConditions : priceConditions"
                    v-model="params.parameterValueType"
                    :disabled="true"
                  >
                  </dropdownlist>

                  <template v-if="params.parameterName === 'Category'">
                    <div class="w-100">
                      <AppMultiselect
                        :class="'mb-24'"
                        :data-items="productCategories"
                        :modelValue="params.parameterFirstValue"
                        @change="(e) => onChangeCategory(e, paramIndex)"
                        :text-field="'name'"
                        :data-item-key="'id'"
                        filterable="true"
                        :placeholder="'All Categories'"
                      />
                      <div class="error-msg">
                        {{
                          errorMessages?.parameterList?.[paramIndex]
                            ?.parameterFirstValue
                        }}
                      </div>
                    </div>
                  </template>

                  <template v-else>
                    <div>
                      <k-input
                        type="number"
                        placeholder=""
                        v-model="params.parameterFirstValue"
                        aria-required="true"
                      >
                      </k-input>
                      <div class="error-msg">
                        {{
                          errorMessages?.parameterList?.[paramIndex]
                            ?.parameterFirstValue
                        }}
                      </div>
                    </div>
                    <span class="h-38 l-height-38">To</span>
                    <div>
                      <k-input
                        type="number"
                        placeholder=""
                        v-model="params.parameterSecondValue"
                        aria-required="true"
                      >
                      </k-input>
                      <div class="error-msg">
                        {{
                          errorMessages?.parameterList?.[paramIndex]
                            ?.parameterSecondValue
                        }}
                      </div>
                    </div>
                  </template>

                  <kbutton
                    @click="removeCondition(paramIndex)"
                    :class="'h-38 w-38'"
                    :icon="'close'"
                  ></kbutton>
                </div>
              </div>
              <div class="new-condition mt-20">
                <kbutton
                  type="button"
                  :class="''"
                  :theme-color="'primary'"
                  :fill-mode="'outline'"
                  @click="addNewCondition"
                >
                  + Add Condition
                </kbutton>
              </div>
            </div>
          </div>
        </div>
      </template>
      <template v-slot:footer>
        <div class="d-flex justify-content-end">
          <div class="me-1">
            <kbutton @click="this.modalHandler" :class="''">Close</kbutton>
          </div>
          <div class="ms-1">
            <kbutton
              @click="onSubmitHandler"
              :theme-color="'primary'"
              :class="''"
            >
              Create Product List</kbutton
            >
          </div>
        </div>
      </template>
    </modal>
  </div>
</template>

<script>
import { Button } from "@progress/kendo-vue-buttons";
import AppLoader from "@/components/common/AppLoader/AppLoader.vue";
import modal from "@/components/common/AppModal/AppModal.vue";
import { NumericTextBox } from "@progress/kendo-vue-inputs";
import { DatePicker } from "@progress/kendo-vue-dateinputs";
import { Input } from "@progress/kendo-vue-inputs";
import { DropDownList } from "@progress/kendo-vue-dropdowns";
import { MaskedTextBox } from "@progress/kendo-vue-inputs";
import { Checkbox } from "@progress/kendo-vue-inputs";
import AppSnackBar from "@/components/common/AppSnackBar/AppSnackBar.vue";
import { Grid } from "@progress/kendo-vue-grid";
import {
  getWebCommanderCategoryService,
  getWebCommanderProductMetricsService,
  saveProductWidgetDynamicQueryService,
} from "@/services/clubDetails/productWidget.service";
import App from "@/App.vue";
import AppMultiselect from "@/components/common/AppMultiselect/AppMultiselect.vue";

export default {
  name: "createProductListModal",
  props: {},
  components: {
    kbutton: Button,
    "app-loader": AppLoader,
    modal,
    numericTextBox: NumericTextBox,
    datePicker: DatePicker,
    "k-input": Input,
    dropdownlist: DropDownList,
    MaskedTextBox: MaskedTextBox,
    checkbox: Checkbox,
    AppSnackBar,
    grid: Grid,
    AppMultiselect,
    App,
  },
  data() {
    return {
      isLoading: false,
      parameterName: "",
      specialFilter: null,
      errorMessages: {},
      productCategories: [],
      isCategoryLoading: false,
      clubId: this.$route.query.club_id,
      isProductMetricLoading: false,
      productMetric: [],
      conditionsType: ["Category", "Price"],
      categoryConditions: ["Includes"],
      priceConditions: ["Is between"],

      parameterList: [
        {
          id: 1,
          parameterName: "Category",
          parameterValueType: "Includes",
          parameterFirstValue: null,
          parameterSecondValue: null,
        },
      ],
    };
  },
  mounted() {
    const payload = {
      clubId: this.clubId,
    };

    this.getWebCommanderProductMetricsList(payload);
    this.getWebCommanderCategoryList(payload);
  },
  computed: {},
  methods: {
    getWebCommanderProductMetricsList(payload) {
      this.isProductMetricLoading = true;
      getWebCommanderProductMetricsService(payload)
        .then((response) => {
          this.isProductMetricLoading = false;
          this.productMetric = response.data?.data;
        })
        .catch((error) => {
          this.isProductMetricLoading = false;
        });
    },

    getWebCommanderCategoryList(payload) {
      this.isCategoryLoading = true;
      getWebCommanderCategoryService(payload)
        .then((response) => {
          this.isCategoryLoading = false;
          this.productCategories = response.data.data?.categories;
        })
        .catch((error) => {
          this.isCategoryLoading = false;
        });
    },
    onChangeConditionType(value, index) {
      console.log(value, index, 'c56');
      if (value == "Category") {
        this.parameterList[index].parameterValueType = "Includes";
      } else {
        this.parameterList[index].parameterValueType = "Is between";
      }
    },
    onChangeCategory(e, index) {
      this.parameterList[index].parameterFirstValue = e.value;
    },
    addNewCondition() {
      this.parameterList.push({
        id: this.parameterList.length + 1,
        parameterName: "Category",
        parameterValueType: "Includes",
        parameterFirstValue: null,
        parameterSecondValue: null,
      });
    },
    removeCondition(index) {
      this.parameterList.splice(index, 1);
    },
    modalHandler() {
      this.$emit("close", false);
    },
    onSubmitHandler() {
      if (this.dataValidation()) {
        this.saveProductList(this.payloadGenerator());
      }
    },
    payloadGenerator() {
      let payload = {
        clubId: this.clubId,
        parameterName: this.parameterName,
        specialFilter: this.specialFilter?.metricValue,
        parameterList: this.parameterList.map((param) => {
          return {
            parameterName:
              param.parameterName == "Category"
                ? "parent"
                : param.parameterName,
            parameterValueType: param.parameterValueType,
            parameterFirstValue:
              param.parameterName == "Category" && param.parameterFirstValue
                ? JSON.stringify(param.parameterFirstValue)
                : param.parameterFirstValue,
            parameterSecondValue: param.parameterSecondValue,
          };
        }),
      };
      return payload;
    },
    saveProductList(payload) {
      this.isLoading = true;
      saveProductWidgetDynamicQueryService(payload)
        .then((response) => {
          this.isLoading = false;

          this.$store.commit("SHOW_NOTIFICATION", {
            message: "Product List created successfully",
            type: "success",
          });

          this.$emit("addDynamicProductsList", {
            ...response.data.data,
            checked: true,
          });
        })
        .catch((error) => {
          this.$store.commit("SHOW_NOTIFICATION", {
            message:
              error.response.data?.Status?.Message ||
              "Something went wrong. Please try again.",
            type: "error",
          });
          this.isLoading = false;
        });
    },
    dataValidation() {
      let isValid = true;
      this.errorMessages = {};
      this.errorMessages.parameterList = [];

      if (!this.parameterName) {
        this.errorMessages.parameterName = "Please enter a list name";
        isValid = false;
      }

      if (!this.specialFilter) {
        this.errorMessages.specialFilter = "Please select a product metric";
        isValid = false;
      }

      if (this.parameterList?.length > 0) {
        this.parameterList.forEach((param, index) => {
          if (param?.parameterName == "Price" && !param?.parameterFirstValue) {
            this.errorMessages.parameterList[index] = {
              ...this.errorMessages?.parameterList?.[index],
              parameterFirstValue: "Please provide Value",
            };
            isValid = false;
          }

          if (param?.parameterName == "Price" && !param?.parameterSecondValue) {
            this.errorMessages.parameterList[index] = {
              ...this.errorMessages?.parameterList?.[index],
              parameterSecondValue: "Please provide Value",
            };
            isValid = false;
          }
        });
      }
      return isValid;
    },
  },
};
</script>

<style></style>
