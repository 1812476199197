<template>
    <svg width="122" height="123" viewBox="0 0 122 123" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0 60.5C0 27.3629 26.8629 0.5 60 0.5H62C95.1371 0.5 122 27.3629 122 60.5V62.5C122 95.6371 95.1371 122.5 62 122.5H60C26.8629 122.5 0 95.6371 0 62.5V60.5Z" fill="white"/>
    <path d="M0 60.5C0 27.3629 26.8629 0.5 60 0.5H62C95.1371 0.5 122 27.3629 122 60.5V62.5C122 95.6371 95.1371 122.5 62 122.5H60C26.8629 122.5 0 95.6371 0 62.5V60.5Z" fill="#0052CC" fill-opacity="0.05"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M70 63.5L68.5 65L62.5 71L50.5 59L40 69.5V47.0094L40.0094 47H76L76.0094 47.0094L76 65H79V47C79 45.35 77.65 44 76 44H40C38.35 44 37 45.35 37 47V68V77C37 78.65 38.35 80 40 80H40.0094H61H64V74H70V63.5ZM62.5 53C64.9844 53 67 55.0156 67 57.5C67 59.9844 64.9844 62 62.5 62C60.0156 62 58 59.9844 58 57.5C58 55.0156 60.0156 53 62.5 53ZM79 77H82H85V80H82H79V83V86H76V83V80H70V77H76V71H79V77Z" fill="#86A2FE"/>
    </svg>
</template>
<script>
    export default {
        name: "noImagesFoundYetIcon"
    }
</script>
<style scoped></style>