<template>
  <div class="widget-accordion-body">
    <!-- <div class="widget-input-item">
        <label class="b-form-label">Width</label>
        <input
          v-model="row.properties[contentType].width"
          type="text"
          class="b-form-field"
          placeholder="auto,100px,100%"
        />
      </div> -->
    <div class="widget-input-item">
      <div class="d-flex justify-content-between align-items-center">
        <label class="b-form-label mb-0">Background Color</label>
        <ColorPicker :gradient-settings="{ opacity: false, }" v-model="row.properties[contentType].backgroundColor" />
      </div>
    </div>

    <div class="widget-input-item">
      <div class="container-padding">
        <label class="b-form-label">Padding</label>
        <div :class="iscolumnPaddingLock ? 'inputs-button lock' : 'inputs-button'">
          <div class="inputs" v-if="!iscolumnPaddingLock">
            <input type="text" class="b-form-field" placeholder="5px" @focusout="columnPaddingTopWidthHandler($event)"
              v-model="row.properties[contentType].paddingTop" />
            <input type="text" class="b-form-field" placeholder="5px" @focusout="columnPaddingRightWidthHandler($event)"
              v-model="row.properties[contentType].paddingRight" />
            <input type="text" class="b-form-field" placeholder="5px" @focusout="columnPaddingBottomWidthHandler($event)"
              v-model="row.properties[contentType].paddingBottom" />
            <input type="text" class="b-form-field" placeholder="5px" @focusout="columnPaddingLeftWidthHandler($event)"
              v-model="row.properties[contentType].paddingLeft" />
          </div>
          <div class="inputs" v-if="iscolumnPaddingLock">
            <input @focusout="columnPaddingChangeFunction($event)" v-model="iscolumnPaddingLockData" type="text"
              class="b-form-field" placeholder="5px" />
          </div>

          <button @click="onClickLockBtn($event)" title="Lock" :class="iscolumnPaddingLock ? 'lock' : ''">
            <span v-if="!iscolumnPaddingLock">
              <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M7 7H13C13.55 7 14 7.45 14 8V14C14 14.55 13.55 15 13 15H5C4.45 15 4 14.55 4 14V11V8C4 7.45 4.45 7 5 7H6V4C6 2.89688 5.10313 2 4 2C2.89688 2 2 2.89688 2 4V7V8H1V4C1 2.34375 2.34375 1 4 1C5.65625 1 7 2.34375 7 4V7ZM8 11C8 11.5531 8.44688 12 9 12C9.55313 12 10 11.5531 10 11C10 10.4469 9.55313 10 9 10C8.44688 10 8 10.4469 8 11Z"
                  fill="#404A61" />
              </svg>
            </span>
            <span v-if="iscolumnPaddingLock">
              <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M12 7H11V4C11 2.34375 9.65625 1 8 1C6.34375 1 5 2.34375 5 4V7H4C3.45 7 3 7.45 3 8V14C3 14.55 3.45 15 4 15H12C12.55 15 13 14.55 13 14V8C13 7.45 12.55 7 12 7ZM6 4C6 2.89688 6.89687 2 8 2C9.10313 2 10 2.89688 10 4V7H6V4ZM7 11C7 11.5531 7.44688 12 8 12C8.55312 12 9 11.5531 9 11C9 10.4469 8.55312 10 8 10C7.44688 10 7 10.4469 7 11Z"
                  fill="#404A61" />
              </svg>
            </span>
          </button>
        </div>
        <div class="inputs-buttons-name d-flex" v-if="!iscolumnPaddingLock">
          <span>Top</span>
          <span>Right</span>
          <span>Bottom</span>
          <span>Left</span>
        </div>
      </div>
    </div>

    <div class="widget-input-item">
      <label class="b-form-label">Border Type</label>
      <div class="col-md-12">
        <select class="b-form-select mb-2" v-model="row.properties[borderType].borderStyle">
          <option v-for="data in row.properties.borderStyleOptions" :key="data.value" :value="data.value">
            {{ data.label }}
          </option>
        </select>
      </div>
    </div>

    <div class="widget-input-item">
      <div class="d-flex justify-content-between align-items-center">
        <label class="b-form-label mb-0">Border Color</label>
        <ColorPicker :gradient-settings="{ opacity: false, }" v-model="row.properties[borderType].borderColor" />
      </div>
    </div>

    <div class="widget-input-item">
      <div class="container-padding">
        <label class="b-form-label">Border Width <small>(PX)</small></label>
        <div :class="isColumnBorderLock ? 'inputs-button lock' : 'inputs-button'">
          <div class="inputs" v-if="!isColumnBorderLock">
            <input type="text" class="b-form-field" placeholder="5px" @focusout="columnBorderTopWidthHandler($event)"
              v-model="row.properties[borderType].borderTopWidth" />
            <input type="text" class="b-form-field" placeholder="5px" @focusout="columnBorderRightWidthHandler($event)"
              v-model="row.properties[borderType].borderRightWidth" />
            <input type="text" class="b-form-field" placeholder="5px" @focusout="columnBorderBottomWidthHandler($event)"
              v-model="row.properties[borderType].borderBottomWidth" />
            <input type="text" class="b-form-field" placeholder="5px" @focusout="columnBorderLeftWidthHandler($event)"
              v-model="row.properties[borderType].borderLeftWidth" />
          </div>
          <div class="inputs" v-if="isColumnBorderLock">
            <input @focusout="columnBorderLockchangeFunc($event)" type="text" class="b-form-field" placeholder="5px"
              v-model="isColumnBorderLockData" />
          </div>

          <button @click="onClickColumnBorderLockBtn($event)" title="lock" :class="isColumnBorderLock ? 'lock' : ''">
            <span v-if="!isColumnBorderLock">
              <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M7 7H13C13.55 7 14 7.45 14 8V14C14 14.55 13.55 15 13 15H5C4.45 15 4 14.55 4 14V11V8C4 7.45 4.45 7 5 7H6V4C6 2.89688 5.10313 2 4 2C2.89688 2 2 2.89688 2 4V7V8H1V4C1 2.34375 2.34375 1 4 1C5.65625 1 7 2.34375 7 4V7ZM8 11C8 11.5531 8.44688 12 9 12C9.55313 12 10 11.5531 10 11C10 10.4469 9.55313 10 9 10C8.44688 10 8 10.4469 8 11Z"
                  fill="#404A61" />
              </svg>
            </span>
            <span v-if="isColumnBorderLock">
              <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M12 7H11V4C11 2.34375 9.65625 1 8 1C6.34375 1 5 2.34375 5 4V7H4C3.45 7 3 7.45 3 8V14C3 14.55 3.45 15 4 15H12C12.55 15 13 14.55 13 14V8C13 7.45 12.55 7 12 7ZM6 4C6 2.89688 6.89687 2 8 2C9.10313 2 10 2.89688 10 4V7H6V4ZM7 11C7 11.5531 7.44688 12 8 12C8.55312 12 9 11.5531 9 11C9 10.4469 8.55312 10 8 10C7.44688 10 7 10.4469 7 11Z"
                  fill="#404A61" />
              </svg>
            </span>
          </button>
        </div>
        <div class="inputs-buttons-name d-flex" v-if="!isColumnBorderLock">
          <span>Top</span>
          <span>Right</span>
          <span>Bottom</span>
          <span>Left</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Input, Switch } from "@progress/kendo-vue-inputs";
import { ColorPicker } from "@progress/kendo-vue-inputs";
import { pxInputHandler } from "../../helpers/utility";
export default {
  inject: ["draggable_editor_data"],
  name: "blockColumnControllers",
  components: {
    KInput: Input,
    Switch,
    ColorPicker,
  },
  data() {
    return {
      isColumnPropertyAccordion: true,
      isRowPropertyAccordion: true,
      isResponsiveAccordion: true,

      isColumnBorderLock: false,
      isColumnBorderLockData: "",
      iscolumnPaddingLock: false,
      iscolumnPaddingLockData: "",
    };
  },
  props: {
    row: Object,
    contentType: String,
    borderType: String,
    index: Number,
  },
  mounted() {
    this.isColumnBorderLock =
      this.draggable_editor_data[this.index]?.properties[
        this.borderType
      ]?.isWidthLock;
    if (this.isColumnBorderLock)
      this.isColumnBorderLockData =
        this.draggable_editor_data[this.index].properties[
          this.borderType
        ].borderTopWidth;

    this.iscolumnPaddingLock =
      this.draggable_editor_data[this.index]?.properties[
        this.contentType
      ]?.isPaddingLock;
    if (this.iscolumnPaddingLock)
      this.iscolumnPaddingLockData =
        this.draggable_editor_data[this.index]?.properties[
          this.contentType
        ].paddingTop;
  },
  methods: {
    onClickColumnBorderLockBtn(e) {
      this.isColumnBorderLock = !this.isColumnBorderLock;
      this.columnBorderlockHandler();
    },
    columnBorderLockchangeFunc(e) {
      this.isColumnBorderLockData = pxInputHandler(e.target.value);
      this.draggable_editor_data[this.index].properties[
        this.borderType
      ].borderTopWidth = pxInputHandler(e.target.value);
      this.draggable_editor_data[this.index].properties[
        this.borderType
      ].borderRightWidth = pxInputHandler(e.target.value);
      this.draggable_editor_data[this.index].properties[
        this.borderType
      ].borderLeftWidth = pxInputHandler(e.target.value);
      this.draggable_editor_data[this.index].properties[
        this.borderType
      ].borderBottomWidth = pxInputHandler(e.target.value);
      // this.draggable_editor_data[this.index].properties[
      //   this.contentType
      // ].textAlign = "center";
    },
    columnBorderTopWidthHandler(e) {
      this.draggable_editor_data[this.index].properties[
        this.borderType
      ].borderTopWidth = pxInputHandler(e.target.value);
    },
    columnBorderRightWidthHandler(e) {
      this.draggable_editor_data[this.index].properties[
        this.borderType
      ].borderRightWidth = pxInputHandler(e.target.value);
    },
    columnBorderBottomWidthHandler(e) {
      this.draggable_editor_data[this.index].properties[
        this.borderType
      ].borderBottomWidth = pxInputHandler(e.target.value);
    },
    columnBorderLeftWidthHandler(e) {
      this.draggable_editor_data[this.index].properties[
        this.borderType
      ].borderLeftWidth = pxInputHandler(e.target.value);
    },
    columnBorderlockHandler() {
      this.draggable_editor_data[this.index].properties[
        this.borderType
      ].isWidthLock = this.isColumnBorderLock;
      if (
        this.draggable_editor_data[this.index].properties[this.borderType]
          .borderTopWidth
      ) {
        this.isColumnBorderLockData =
          this.draggable_editor_data[this.index].properties[
            this.borderType
          ].borderTopWidth;
        this.draggable_editor_data[this.index].properties[
          this.borderType
        ].borderRightWidth =
          this.draggable_editor_data[this.index].properties[
            this.borderType
          ].borderTopWidth;
        this.draggable_editor_data[this.index].properties[
          this.borderType
        ].borderBottomWidth =
          this.draggable_editor_data[this.index].properties[
            this.borderType
          ].borderTopWidth;
        this.draggable_editor_data[this.index].properties[
          this.borderType
        ].borderLeftWidth =
          this.draggable_editor_data[this.index].properties[
            this.borderType
          ].borderTopWidth;
      } else {
        this.draggable_editor_data[this.index].properties[
          this.borderType
        ].borderTopWidth = "0px";
        this.draggable_editor_data[this.index].properties[
          this.borderType
        ].borderRightWidth = "0px";
        this.draggable_editor_data[this.index].properties[
          this.borderType
        ].borderBottomWidth = "0px";
        this.draggable_editor_data[this.index].properties[
          this.borderType
        ].borderLeftWidth = "0px";
      }
    },

    onClickLockBtn(e) {
      this.iscolumnPaddingLock = !this.iscolumnPaddingLock;
      this.paddingOnClickLockBtn(e);
    },

    paddingOnClickLockBtn() {
      this.draggable_editor_data[this.index].properties[
        this.contentType
      ].isPaddingLock = this.iscolumnPaddingLock;
      if (
        this.draggable_editor_data[this.index].properties[this.contentType]
          .paddingTop
      ) {
        this.iscolumnPaddingLockData =
          this.draggable_editor_data[this.index].properties[
            this.contentType
          ].paddingTop;
        this.draggable_editor_data[this.index].properties[
          this.contentType
        ].paddingRight =
          this.draggable_editor_data[this.index].properties[
            this.contentType
          ].paddingTop;
        this.draggable_editor_data[this.index].properties[
          this.contentType
        ].paddingBottom =
          this.draggable_editor_data[this.index].properties[
            this.contentType
          ].paddingTop;
        this.draggable_editor_data[this.index].properties[
          this.contentType
        ].paddingLeft =
          this.draggable_editor_data[this.index].properties[
            this.contentType
          ].paddingTop;
      } else {
        this.draggable_editor_data[this.index].properties[
          this.contentType
        ].paddingTop = "0px";
        this.draggable_editor_data[this.index].properties[
          this.contentType
        ].paddingRight = "0px";
        this.draggable_editor_data[this.index].properties[
          this.contentType
        ].paddingBottom = "0px";
        this.draggable_editor_data[this.index].properties[
          this.contentType
        ].paddingLeft = "0px";
      }
    },
    columnPaddingChangeFunction(e) {
      this.columnPaddingLockHandler(e);
    },
    columnPaddingLockHandler(e) {
      this.iscolumnPaddingLockData = pxInputHandler(e.target.value);
      this.draggable_editor_data[this.index].properties[
        this.contentType
      ].paddingTop = pxInputHandler(e.target.value);
      this.draggable_editor_data[this.index].properties[
        this.contentType
      ].paddingRight = pxInputHandler(e.target.value);
      this.draggable_editor_data[this.index].properties[
        this.contentType
      ].paddingBottom = pxInputHandler(e.target.value);
      this.draggable_editor_data[this.index].properties[
        this.contentType
      ].paddingLeft = pxInputHandler(e.target.value);
      // this.draggable_editor_data[this.index].properties[
      //   this.contentType
      // ].textAlign = "center";
    },
    columnPaddingLeftWidthHandler(e) {
      this.draggable_editor_data[this.index].properties[
        this.contentType
      ].paddingLeft = pxInputHandler(e.target.value);
    },
    columnPaddingBottomWidthHandler(e) {
      this.draggable_editor_data[this.index].properties[
        this.contentType
      ].paddingBottom = pxInputHandler(e.target.value);
    },
    columnPaddingRightWidthHandler(e) {
      this.draggable_editor_data[this.index].properties[
        this.contentType
      ].paddingRight = pxInputHandler(e.target.value);
    },
    columnPaddingTopWidthHandler(e) {
      this.draggable_editor_data[this.index].properties[
        this.contentType
      ].paddingTop = pxInputHandler(e.target.value);
    },
  },
};
</script>

<style scoped></style>
