<template>
  <modal
    :class="'max-height-100-145'"
    title="Manage Table"
    :handler="true"
    @modalHandler="this.onCancel"
    :cssClass="'modal-xl'"
  >
    <template v-slot:content>
      <div class="manage-table-wrap">
        <div class="manage-table-view">
          <div
            v-if="
              draggable_editor_data[this.index][this.columnType][
                this.childIndex
              ].propertiesArray[this.column.properties.selectedVariationIndex]
                .tableProperties.isDynamic
            "
            class="table-toggle"
          >
            <h5 class="font-16 mb-0">Headers</h5>
            <k-switch
              @change="switchStatushandler"
              :size="'small'"
              :checked="
                draggable_editor_data[this.index][this.columnType][
                  this.childIndex
                ].propertiesArray[this.column.properties.selectedVariationIndex]
                  .tableProperties.dynamicTable.isHeaderChecked
              "
            />
          </div>
          <div class="manage-table-single">
            <div class="remove-row">
              <kbutton
                v-if="
                  !draggable_editor_data[this.index][this.columnType][
                    this.childIndex
                  ].propertiesArray[
                    this.column.properties.selectedVariationIndex
                  ].tableProperties.isDynamic
                "
                @click="removeRow"
                :class="''"
                :fill-mode="'flat'"
                :icon="'minus'"
              ></kbutton>
            </div>
            <div class="manage-table">
              <div class="remove-column">
                <kbutton
                  @click="removeColumn"
                  :class="''"
                  :fill-mode="'flat'"
                  :icon="'minus'"
                ></kbutton>
              </div>
              <table
                class="manage-table-first-table"
                cellpadding="10px"
                cellspacing="10px"
              >
                <thead
                  v-if="
                    (draggable_editor_data[this.index][this.columnType][
                      this.childIndex
                    ].propertiesArray[
                      this.column.properties.selectedVariationIndex
                    ].tableProperties.dynamicTable.isHeaderChecked &&
                      draggable_editor_data[this.index][this.columnType][
                        this.childIndex
                      ].propertiesArray[
                        this.column.properties.selectedVariationIndex
                      ].tableProperties.isDynamic) ||
                    (!draggable_editor_data[this.index][this.columnType][
                      this.childIndex
                    ].propertiesArray[
                      this.column.properties.selectedVariationIndex
                    ].tableProperties.isDynamic &&
                      draggable_editor_data[this.index][this.columnType][
                        this.childIndex
                      ].propertiesArray[
                        this.column.properties.selectedVariationIndex
                      ].tableProperties.staticTable.isHeaderChecked)
                  "
                >
                  <tr v-for="(row, rowIndex) in tableHeaders" :key="rowIndex">
                    <th
                      :style="{
                        backgroundColor: column.propertiesArray[
                          column.properties.selectedVariationIndex
                        ].tableProperties.headerStyle.isVisible
                          ? column.propertiesArray[
                              column.properties.selectedVariationIndex
                            ].tableProperties.headerStyle.backgroundColor
                          : '#FFFFFF',
                        border:
                          '1px ' +
                          column.propertiesArray[
                            column.properties.selectedVariationIndex
                          ].tableProperties.tableBorderSelectedStyle
                            .borderStyle +
                          ' ' +
                          column.propertiesArray[
                            column.properties.selectedVariationIndex
                          ].tableProperties.tableBorderSelectedStyle
                            .borderColor,
                      }"
                      v-for="(cell, colIndex) in row"
                      :key="colIndex"
                      :class="selectedCell?.id == cell?.id ? 'active' : ''"
                      @click="selectHeaderCell(rowIndex, colIndex)"
                    >
                      <template v-if="cell.type === 'Image' && cell.content">
                        <table>
                          <tr>
                            <td :align="cell?.img_properties?.alignment">
                              <img
                                :src="cell.content"
                                :alt="cell.properties.alterText"
                              />
                            </td>
                          </tr>
                        </table>
                      </template>
                      <template v-else>
                        <div
                          class="table-widget-text"
                          v-html="cell.content"
                        ></div>
                      </template>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(row, rowIndex) in tableData" :key="rowIndex">
                    <td
                      :style="{
                        backgroundColor: column.propertiesArray[
                          column.properties.selectedVariationIndex
                        ].tableProperties.fullTableStyle.isVisible
                          ? column.propertiesArray[
                              column.properties.selectedVariationIndex
                            ].tableProperties.fullTableStyle.backgroundColor
                          : '#FFFFFF',
                        border:
                          '1px ' +
                          column.propertiesArray[
                            column.properties.selectedVariationIndex
                          ].tableProperties.tableBorderSelectedStyle
                            .borderStyle +
                          ' ' +
                          column.propertiesArray[
                            column.properties.selectedVariationIndex
                          ].tableProperties.tableBorderSelectedStyle
                            .borderColor,
                      }"
                      v-for="(cell, colIndex) in row"
                      :key="colIndex"
                      @click="selectCell(rowIndex, colIndex)"
                      :class="selectedCell?.id == cell?.id ? 'active' : ''"
                    >
                      <template v-if="cell.type === 'Image' && cell.content">
                        <table>
                          <tr>
                            <td :align="cell?.img_properties?.alignment">
                              <img
                                ref="picWidth"
                                :src="cell.content"
                                :width="cell.properties.width"
                                :alt="cell.properties.alterText"
                                @load="table_modify_image_width('picWidth')"
                              />
                            </td>
                          </tr>
                        </table>
                      </template>
                      <template v-else>
                        <div
                          class="table-widget-text"
                          v-html="cell.content"
                        ></div>
                      </template>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div class="add-row">
                <kbutton
                  v-if="
                    !draggable_editor_data[this.index][this.columnType][
                      this.childIndex
                    ].propertiesArray[
                      this.column.properties.selectedVariationIndex
                    ].tableProperties.isDynamic
                  "
                  @click="addRow"
                  :theme-color="'primary'"
                  :class="''"
                  :icon="'plus'"
                ></kbutton>
              </div>
            </div>
            <div class="add-column">
              <kbutton
                @click="addColumn"
                :theme-color="'primary'"
                :class="''"
                :icon="'plus'"
              ></kbutton>
            </div>
          </div>
          <div
            v-if="
              draggable_editor_data[this.index][this.columnType][
                this.childIndex
              ].propertiesArray[this.column.properties.selectedVariationIndex]
                .tableProperties.isDynamic
            "
            class="table-toggle"
          >
            <h5 class="font-16 mb-0">Include a static row</h5>
            <k-switch
              @change="switchStaticRowhandler"
              :size="'small'"
              :checked="
                draggable_editor_data[this.index][this.columnType][
                  this.childIndex
                ].propertiesArray[this.column.properties.selectedVariationIndex]
                  .tableProperties.dynamicTable.isStaticRowChecked
              "
            />
          </div>
          <div class="manage-table-single">
            <div class="remove-row">
              <!-- <kbutton v-if="draggable_editor_data[this.index][this.columnType][this.childIndex]
                                .propertiesArray[this.column.properties.selectedVariationIndex].tableProperties.isDynamic"
                                @click="removeStaticRow" :class="''" :fill-mode="'flat'" :icon="'minus'"></kbutton> -->
            </div>
            <div
              v-if="
                draggable_editor_data[this.index][this.columnType][
                  this.childIndex
                ].propertiesArray[this.column.properties.selectedVariationIndex]
                  .tableProperties.dynamicTable.isStaticRowChecked &&
                draggable_editor_data[this.index][this.columnType][
                  this.childIndex
                ].propertiesArray[this.column.properties.selectedVariationIndex]
                  .tableProperties.isDynamic
              "
              class="manage-table"
            >
              <div class="remove-column">
                <!-- <kbutton :class="''" :fill-mode="'flat'" :icon="'minus'"></kbutton> -->
              </div>
              <table cellpadding="10px" cellspacing="10px">
                <tbody>
                  <tr
                    v-for="(row, rowIndex) in tableStaticRowData"
                    :key="rowIndex"
                  >
                    <td
                      :style="{
                        backgroundColor: column.propertiesArray[
                          column.properties.selectedVariationIndex
                        ].tableProperties.staticRowStyle.isVisible
                          ? column.propertiesArray[
                              column.properties.selectedVariationIndex
                            ].tableProperties.staticRowStyle?.backgroundColor
                          : '#FFFFFF',
                        border:
                          '1px ' +
                          column.propertiesArray[
                            column.properties.selectedVariationIndex
                          ].tableProperties.tableBorderSelectedStyle
                            .borderStyle +
                          ' ' +
                          column.propertiesArray[
                            column.properties.selectedVariationIndex
                          ].tableProperties.tableBorderSelectedStyle
                            .borderColor,
                      }"
                      v-for="(cell, colIndex) in row"
                      :key="colIndex"
                      @click="selectStaticRowCell(rowIndex, colIndex)"
                      :class="selectedCell?.id == cell?.id ? 'active' : ''"
                    >
                      <template v-if="cell.type === 'Image' && cell.content">
                        <table>
                          <tr>
                            <td :align="cell?.img_properties?.alignment">
                              <img
                                ref="picWidth_static"
                                :src="cell.content"
                                :width="cell.properties.width"
                                :alt="cell.properties.alterText"
                                @load="
                                  table_modify_image_width('picWidth_static')
                                "
                              />
                            </td>
                          </tr>
                        </table>
                      </template>
                      <template v-else>
                        <div
                          class="table-widget-text"
                          v-html="cell.content"
                        ></div>
                      </template>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div class="add-row">
                <!-- <kbutton v-if="draggable_editor_data[this.index][this.columnType][this.childIndex]
                                    .propertiesArray[this.column.properties.selectedVariationIndex].tableProperties.isDynamic"
                                    @click="addStaticRow" :theme-color="'primary'" :class="''" :icon="'plus'"></kbutton> -->
              </div>
            </div>
            <div class="add-column">
              <!-- <kbutton :theme-color="'primary'" :class="''" :icon="'plus'"></kbutton> -->
            </div>
          </div>
        </div>
        <div v-if="selectedCell" class="manage-table-controller">
          <div class="mtc-tab-menu">
            <kbutton
              @click="setContent('Text')"
              :icon="'edit-tools'"
              :class="selectedCell.type == 'Text' ? 'active' : ''"
              >Text</kbutton
            >
            <kbutton
              :disabled="selectedCell?.isHeader"
              @click="setContent('Image')"
              :icon="'image'"
              :class="selectedCell.type == 'Image' ? 'active' : ''"
              >Image
            </kbutton>
          </div>
          <div
            class="mtc-tab-content input-link-custom-modal"
            v-if="selectedCell !== null"
          >
            <div
              v-if="
                selectedCell.type === 'Text' || selectedCell.type === 'Data Tag'
              "
            >
              <KEditor
                :class="'builder-editor'"
                :tools="ktools"
                :value="selectedCell.content"
                @execute="onExecute"
                @loaded="editorStyleOnLoaded"
                @change="(e) => onChangeKeditor(e)"
                :paste-html="onPasteHtml"
              >
                <template v-slot:MyFontSizeTool="{ props }">
                  <div>
                    <fontsize
                      v-bind="props"
                      :style="{
                        width: '150px',
                        ...props.style,
                      }"
                    />
                  </div>
                </template>
                <template v-slot:FontNameTool="{ props }">
                  <div>
                    <fontname
                      v-bind="props"
                      :style="{
                        width: '250px',
                        ...props.style,
                      }"
                    />
                  </div>
                </template>
                <template v-slot:MyLineHeightTool="{ props }">
                  <MyLineHeightTool
                    :currentHeight="lineHeight"
                    :view="props.view"
                  />
                </template>
                <template v-slot:MyBackColor="{ props }">
                  <CustomColorPicker
                    v-bind="props"
                    :settings="props.settings"
                  />
                </template>
                <template v-slot:MyForeColor="{ props }">
                  <CustomColorPicker
                    v-bind="props"
                    :settings="props.settings"
                  />
                </template>
                <template v-slot:macroDropdownComponent="{ props }">
                  <MacroDropdownComponent :view="props.view" />
                </template>
              </KEditor>
            </div>
            <div
              v-if="selectedCell.type === 'Image'"
              class="widget-accordion-wrap"
            >
              <div class="widget-accordion-item">
                <div
                  class="widget-accordion-body"
                  style="
                    padding: 0;
                    visibility: visible;
                    opacity: 1;
                    max-height: unset;
                  "
                >
                  <div v-if="isTableDynamic" class="widget-input-item">
                    <label class="b-form-label">Image Source</label>
                    <DropDownList
                      :data-items="imageSourceTypes"
                      :text-field="'text'"
                      :data-item-key="'id'"
                      :value="selectedCell.img_properties.selectedImageSource"
                      @change="onChangeImageSourceTypes"
                    />
                  </div>

                  <div
                    v-if="
                      (isTableDynamic &&
                        [1, 2].includes(
                          selectedCell.img_properties.selectedImageSource?.id
                        )) ||
                      !isTableDynamic
                    "
                  >
                    <div class="widget-input-item">
                      <label class="b-form-label">Image</label>
                      <EditorFileUploader
                        @onChangemodelValue="onChangemodelValue"
                        v-model="imagePreview"
                        @onCancelModelValue="onCancelModelValue"
                        :column="column"
                        :columnType="columnType"
                        :index="index"
                        :childIndex="childIndex"
                      />
                    </div>
                    <div class="widget-input-item">
                      <label class="b-form-label">Image URL</label>
                      <input
                        @input="onChangeImageUrl"
                        type="text"
                        v-model="imagePreview"
                        class="b-form-field"
                      />
                    </div>
                    <div class="widget-input-item"></div>
                  </div>
                  <div class="widget-input-item">
                    <div>
                      <div class="d-flex justify-content-between">
                        <label class="b-form-label">Width</label>
                      </div>
                      <div class="d-flex justify-content-between">
                        <slider
                          :class="'w-100'"
                          :buttons="false"
                          :default-value="autoWidthValue"
                          :value="autoWidthValue"
                          :min="1"
                          :max="100"
                          @change="widthSlider($event)"
                        >
                        </slider>
                        <span class="font-16 ms-3"
                          >{{ Math.round(autoWidthValue) }}%</span
                        >
                      </div>
                    </div>
                  </div>
                  <div class="widget-input-item alignment">
                    <div
                      class="d-flex justify-content-between align-items-center"
                    >
                      <label class="b-form-label mb-0">Alignment</label>
                      <div class="buttons">
                        <button
                          @click="alignmentHandler('left')"
                          :class="
                            isLeftAlign
                              ? 'k-button-solid-base border-0 active'
                              : 'k-button-solid-base border-0'
                          "
                          title="Left Align"
                        >
                          <svg
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M2 3H14V2H2V3ZM2 4H11V5H2V4ZM14 6H2V7H14V6ZM2 8H11V9H2V8ZM14 10H2V11H14V10ZM2 12H11V13H2V12Z"
                              fill="#24243A"
                            />
                          </svg>
                        </button>
                        <button
                          @click="alignmentHandler('center')"
                          :class="
                            iscenterAlign
                              ? 'k-button-solid-base border-0 active'
                              : 'k-button-solid-base border-0'
                          "
                          title="Center Align"
                        >
                          <svg
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M2 3H14V2H2V3ZM4 5H12V4H4V5ZM14 7H2V6H14V7ZM4 9H12V8H4V9ZM14 11H2V10H14V11ZM4 13H12V12H4V13Z"
                              fill="#24243A"
                            />
                          </svg>
                        </button>
                        <button
                          @click="alignmentHandler('right')"
                          :class="
                            isRightAlign
                              ? 'k-button-solid-base border-0 active'
                              : 'k-button-solid-base border-0'
                          "
                          title="Right Align"
                        >
                          <svg
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M14 2V3H2V2H14ZM14 6H2V7H14V6ZM5 5H14V4H5V5ZM2 11V10H14V11H2ZM5 9H14V8H5V9ZM5 13V12H14V13H5Z"
                              fill="#24243A"
                            />
                          </svg>
                        </button>
                      </div>
                    </div>
                  </div>
                  <div class="widget-input-item">
                    <label class="b-form-label">Alternative Text</label>
                    <div class="widget-input-dropdown">
                      <input
                        type="text"
                        class="b-form-field"
                        placeholder="Alt Image"
                        v-model="selectedCell.properties.alterText"
                      />
                    </div>
                  </div>
                  <div
                    v-if="
                      selectedCell.img_properties.selectedActionType?.id == 1
                    "
                    class="widget-input-item"
                  >
                    <label class="b-form-label">Link Address</label>
                    <input
                      type="text"
                      v-model="selectedCell.img_properties.linkUrl"
                      class="b-form-field"
                    />
                  </div>

                  <div class="widget-input-item">
                    <label class="b-form-label">Action Type</label>
                    <!-- <DropDownList :data-items="column.propertiesArray[column.properties.selectedVariationIndex].actionTypeOptions"
                                        :text-field="'label'" :data-item-key="'id'"
                                        :value="column.propertiesArray[column.properties.selectedVariationIndex].selectedActionType"
                                         @change="onChangeActionType" /> -->
                    <select
                      class="b-form-select"
                      v-model="selectedCell.img_properties.selectedActionType"
                    >
                      <option
                        v-for="data in column.propertiesArray[
                          column.properties.selectedVariationIndex
                        ].actionTypeOptions"
                        :key="data.id"
                        :value="data"
                      >
                        {{ data.label }}
                      </option>
                    </select>
                  </div>
                  <div class="widget-input-item">
                    <div
                      class="d-flex justify-content-between align-items-start"
                    >
                      <div>
                        <label>Include Campaign ID</label>
                        <p class="font-14">
                          Track link/tag performance in analytics. Recommended
                          for flow automation users.
                        </p>
                      </div>
                      <k-switch
                        @change="onChangeIncludeCampaignId"
                        :checked="
                          selectedCell.img_properties.isIncludeCampaignId
                        "
                      />
                    </div>
                  </div>
                  <div
                    v-if="
                      selectedCell.img_properties.selectedActionType?.id == 2
                    "
                    class="widget-input-item"
                  >
                    <label class="b-form-label">Select Link URL Tag</label>
                    <DropDownList
                      :data-items="copyTags"
                      @open="onDropdownOpenHandler"
                      :text-field="'Text'"
                      :data-item-key="'Id'"
                      :value="selectedCell.img_properties.selectedLinkUrl"
                      @change="onChange"
                      :filterable="true"
                      @filterchange="filterChange"
                    />
                  </div>
                  <div
                    v-if="
                      isTableDynamic &&
                      [3].includes(
                        selectedCell.img_properties.selectedImageSource?.id
                      )
                    "
                    class="widget-input-item"
                  >
                    <label class="b-form-label">Select Image URL Tag</label>
                    <DropDownList
                      :data-items="copyTags"
                      @open="onDropdownOpenHandler"
                      :text-field="'Text'"
                      :data-item-key="'Id'"
                      :value="selectedCell.img_properties.selectedImageUrl"
                      @change="onChangeImageUrlTag"
                      :filterable="true"
                      @filterchange="filterChange"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- <button @click="printJSON">Print JSON</button> -->
        </div>
      </div>
    </template>
    <template v-slot:footer>
      <div class="d-flex justify-content-end">
        <div class="me-1">
          <kbutton @click="this.onCancel" :class="''">Cancel</kbutton>
        </div>
        <div class="ms-1">
          <kbutton
            @click="this.onSubmitHandler"
            :theme-color="'primary'"
            :class="''"
            >Save</kbutton
          >
        </div>
      </div>
    </template>
  </modal>
</template>

<script>
const editorContentStyle = `
    p {
        // margin:0;
    }
    .k-content > p {
        // margin: 0;
    }
`;
import modal from "@/components/common/AppModal/AppModal.vue";
import { Button } from "@progress/kendo-vue-buttons";
import { Editor, EditorUtils, FontName } from "@progress/kendo-vue-editor";
import kendoTools from "../../helpers/kendoTools";
import EditorFileUploader from "../../common-components/EditorFileUploader.vue";
import { Slider, Switch } from "@progress/kendo-vue-inputs";
import { throttleFunction } from "@/utils/helper";
import { DropDownList } from "@progress/kendo-vue-dropdowns";
import { filterBy } from "@progress/kendo-data-query";
import MyLineHeightTool from "../../common-components/myLineHeightTool.vue";
import CustomColorPicker from "../../common-components/customColorPicker.vue";
import MacroDropdownComponent from "../../common-components/macroDropdownComponent.vue";
import { onPasteHtmlIntoEditor } from "@/utils/helper";

export default {
  name: "manageTableModal",
  inject: ["draggable_editor_data"],
  props: {
    payloadForaddUnassignedVoucher: {
      type: Object,
    },

    onCancel: { type: Function },
    onConfirm: { type: Function },
    column: Object,
    index: Number,
    childIndex: Number,
    columnType: String,
    datas: Array,
    headersData: Array,
    staticRowData: Array,
  },
  components: {
    modal,
    kbutton: Button,
    KEditor: Editor,
    "k-switch": Switch,
    MacroDropdownComponent,
    EditorFileUploader,
    Slider,
    DropDownList,
    MyLineHeightTool,
    fontsize: FontName,
    fontname: FontName,
    CustomColorPicker,
  },
  data() {
    return {
      ktools: [...kendoTools, { render: "macroDropdownComponent" }],
      tableData: this.datas,
      tableHeaders: this.headersData,
      tableStaticRowData: this.staticRowData,
      selectedCell: null,
      imagePreview: null,
      autoWidthValue: 100,

      isTableDynamic: false,

      copyTags: [],
      imageSourceTypes: [
        {
          id: 1,
          text: "Upload Image",
          value: "uploadImage",
        },
        {
          id: 2,
          text: "Image URL",
          value: "imageUrl",
        },
        {
          id: 3,
          text: "Insert Tag",
          value: "insertTag",
        },
      ],

      isLeftAlign: true,
      iscenterAlign: false,
      isRightAlign: false,
    };
  },
  watch: {
    $props: {
      handler() {
        this?.tableImageWidthModifyHandler();
      },
      deep: true,
      immediate: false,
    },

    responsive() {
      this?.tableImageWidthModifyHandler();
    },

    mainContainerWidth() {
      this?.tableImageWidthModifyHandler();
    },
  },
  created() {
    this.tableImageWidthModifyHandler = throttleFunction(
      this.table_modify_image_width,
      100
    );
  },
  mounted() {
    this.isTableDynamic =
      this.draggable_editor_data[this.index][this.columnType][
        this.childIndex
      ].propertiesArray[
        this.column.properties.selectedVariationIndex
      ]?.tableProperties?.isDynamic;

    this.selectedCell = this.tableData[0][0];
    if (this.selectedCell.type == "Image") {
      this.imagePreview = this.selectedCell.content;
      this.autoWidthValue = this.selectedCell.properties.width;
      this.reloadImageAlignment();
    }
  },
  computed: {
    macros() {
      return this.$store.state.commonEmailCampaign
        .emailCampaignTemplateMacroLists;
    },
  },
  methods: {
    onPasteHtml(e) {
      return onPasteHtmlIntoEditor(e);
    },
    reloadImageAlignment() {
      this.isLeftAlign =
        this.selectedCell.img_properties.alignment == "left" ? true : false;
      this.iscenterAlign =
        this.selectedCell.img_properties.alignment == "center" ? true : false;
      this.isRightAlign =
        this.selectedCell.img_properties.alignment == "right" ? true : false;
    },
    alignmentHandler(alignmentType) {
      this.isLeftAlign = alignmentType == "left" ? true : false;
      this.iscenterAlign = alignmentType == "center" ? true : false;
      this.isRightAlign = alignmentType == "right" ? true : false;
      this.selectedCell.img_properties.alignment = alignmentType;
    },
    onChangeImageSourceTypes(event) {
      this.selectedCell.img_properties.selectedImageSource = event.target.value;
      this.selectedCell.content = "";
      this.imagePreview = null;
    },
    onChangeIncludeCampaignId(event) {
      this.selectedCell.img_properties.isIncludeCampaignId = event.target.value;
    },
    onDropdownOpenHandler() {
      this.copyTags = structuredClone(this.macros);
    },
    filterChange(event) {
      this.copyTags = this.filterData(event.filter);
    },
    filterData(filter) {
      const data = this.macros.slice();
      return filterBy(data, filter);
    },
    onChangeImageUrlTag(event) {
      this.selectedCell.content =
        "https://clubeez-v2-staging.s3-ap-southeast-1.amazonaws.com/1b9cfbd1-76e7-4e67-9f97-f7c488eb5d92_638048592605592111.jpg";

      this.selectedCell.img_properties.selectedImageUrl = event.value;

      this.updateCellContent();
    },
    onChange(event) {
      this.selectedCell.img_properties.selectedLinkUrl = event.value;
    },
    onChangeActionType(event) {
      this.draggable_editor_data[this.index][this.columnType][
        this.childIndex
      ].propertiesArray[
        this.column.properties.selectedVariationIndex
      ].selectedActionType = event.target.value;
    },
    table_modify_image_width(refName) {
      const refElement = this.$refs[refName];
      if (refElement) {
        if (
          this.columnType &&
          this.selectedCell.properties.width === "100" &&
          refElement[0]?.clientWidth !== 0
        ) {
          this.selectedCell.properties.width = refElement[0]?.clientWidth;
        } else if (
          this.columnType &&
          !isNaN(+this.selectedCell.properties.width) &&
          +this.selectedCell.properties.width + 20 !==
            refElement[0]?.clientWidth &&
          refElement[0]?.clientWidth !== 0
        ) {
          this.selectedCell.properties.width = refElement[0]?.clientWidth;
        }
      }
    },
    onChangeImageUrl(event) {
      this.selectedCell.content = event.target.value;
      this.updateCellContent();
    },
    onChangemodelValue(e) {
      this.selectedCell.content = e;
      this.updateCellContent();
    },
    onCancelModelValue(e) {
      this.selectedCell.content = e;

      this.updateCellContent();
    },
    widthSlider(e) {
      this.autoWidthValue = Math.round(e.value);
      this.selectedCell.properties.width = Math.round(e.value);
      this.updateCellContent();
    },
    editorStyleOnLoaded(event) {
      const iframeDocument = event.dom.ownerDocument;
      const style = iframeDocument.createElement("style");
      style.appendChild(iframeDocument.createTextNode(editorContentStyle));
      iframeDocument.head.appendChild(style);
    },
    onExecute(event) {
      setTimeout(() => {
        this.lineHeight = EditorUtils.getInlineStyles(event.target.view.state, {
          name: "line-height",
          value: /^.+$/,
        })[0];
      });
      const { doc, selection } = event.transaction;
      let selectionHtml = "";

      if (!selection.empty) {
        const node = doc.cut(selection.from, selection.to);
        selectionHtml = EditorUtils.getHtml({
          doc: node,
          schema: node.type.schema,
        });
      }

      if (this.$refs.htmlAreaRef) {
        this.htmlAreaRefValue = selectionHtml;
      }
    },
    selectCell(rowIndex, colIndex) {
      this.selectedCell = this.tableData[rowIndex][colIndex];

      if (this.selectedCell.type == "Image") {
        this.imagePreview = this.selectedCell.content;
        this.autoWidthValue = this.selectedCell.properties.width;
        this.reloadImageAlignment();
      }

      this.imagePreview =
        this.selectedCell.type === "Image" ? this.selectedCell.content : null;
    },
    selectHeaderCell(rowIndex, colIndex) {
      this.selectedCell = this.headersData[rowIndex][colIndex];

      if (this.selectedCell.type == "Image") {
        this.imagePreview = this.selectedCell.content;
        this.autoWidthValue = this.selectedCell.properties.width;
        this.reloadImageAlignment();
      }

      this.imagePreview =
        this.selectedCell.type === "Image" ? this.selectedCell.content : null;
    },
    selectStaticRowCell(rowIndex, colIndex) {
      this.selectedCell = this.tableStaticRowData[rowIndex][colIndex];

      if (this.selectedCell.type == "Image") {
        this.imagePreview = this.selectedCell.content;
        this.autoWidthValue = this.selectedCell.properties.width;
        this.reloadImageAlignment();
      }

      this.imagePreview =
        this.selectedCell.type === "Image" ? this.selectedCell.content : null;
    },
    setContent(type) {
      var id = this.selectedCell.id;

      // find index with id
      const { rowIndex, colIndex } = this.findSelectedCellIndices();

      this.selectedCell = {
        ...this.selectedCell,
        content: "",
        ...(type == "Image" && {
          img_properties: {
            alignment: "left",
            linkUrl: "",
            selectedImageSource: {
              id: 1,
              text: "Upload Image",
              value: "uploadImage",
            },
            selectedLinkUrl: { Text: "Select Tag", Value: "", Id: 0 },
            selectedImageUrl: { Text: "Select Tag", Value: "", Id: 0 },
            isIncludeCampaignId: false,
            selectedActionType: {
              id: 1,
              label: "Open Website",
              value: "openWebsite",
            },
          },
        }),
      };

      if (this.selectedCell.isHeader)
        this.selectedCell = {
          ...this.selectedCell,
          type,
          content: "",
          id,
          properties: { width: "100", alignment: "20", alterText: "" },
          isHeader: true,
        };
      else if (!this.selectedCell.isHeader && !this.selectedCell.isStaticRow)
        this.selectedCell = {
          ...this.selectedCell,
          type,
          content: "",
          id,
          properties: { width: "100", alignment: "20", alterText: "" },
          isHeader: false,
        };
      else if (this.selectedCell.isStaticRow)
        this.selectedCell = {
          ...this.selectedCell,
          type,
          content: "",
          id,
          properties: { width: "100", alignment: "20", alterText: "" },
          isStaticRow: true,
        };

      if (type == "Text") {
        if (this.selectedCell.hasOwnProperty("img_properties")) {
          delete this.selectedCell.img_properties;
          // this.selectedCell.content = " a"
        }
      }

      this.imagePreview = null;
    },
    updateCellContent() {
      if (!this.selectedCell || !this.selectedCell.content) return;
      const { rowIndex, colIndex } = this.findSelectedCellIndices();
      if (
        rowIndex !== -1 &&
        colIndex !== -1 &&
        !this.selectedCell.isHeader &&
        !this.selectedCell?.isStaticRow
      ) {
        this.tableData[rowIndex][colIndex] = this.selectedCell;
      } else if (
        rowIndex !== -1 &&
        colIndex !== -1 &&
        this.selectedCell?.isHeader &&
        !this.selectedCell?.isStaticRow
      ) {
        this.tableHeaders[rowIndex][colIndex] = this.selectedCell;
      } else if (
        rowIndex !== -1 &&
        colIndex !== -1 &&
        this.selectedCell?.isStaticRow
      ) {
        this.tableStaticRowData[rowIndex][colIndex] = this.selectedCell;
      }
    },
    findSelectedCellIndices() {
      if (!this.selectedCell.isHeader && !this.selectedCell?.isStaticRow) {
        for (let rowIndex = 0; rowIndex < this.tableData.length; rowIndex++) {
          const row = this.tableData[rowIndex];
          const colIndex = row.findIndex(
            (cell) => cell.id === this.selectedCell.id
          );
          if (colIndex !== -1) {
            return { rowIndex, colIndex };
          }
        }
      } else if (
        this.selectedCell.isHeader &&
        !this.selectedCell?.isStaticRow
      ) {
        for (
          let rowIndex = 0;
          rowIndex < this.tableHeaders.length;
          rowIndex++
        ) {
          const row = this.tableHeaders[rowIndex];
          const colIndex = row.findIndex(
            (cell) => cell.id === this.selectedCell.id
          );
          if (colIndex !== -1) {
            return { rowIndex, colIndex };
          }
        }
      } else if (this.selectedCell.isStaticRow) {
        for (
          let rowIndex = 0;
          rowIndex < this.tableStaticRowData.length;
          rowIndex++
        ) {
          const row = this.tableStaticRowData[rowIndex];
          const colIndex = row.findIndex(
            (cell) => cell.id === this.selectedCell.id
          );
          if (colIndex !== -1) {
            return { rowIndex, colIndex };
          }
        }
      }
      return { rowIndex: -1, colIndex: -1 };
    },
    switchStatushandler(e) {
      this.draggable_editor_data[this.index][this.columnType][
        this.childIndex
      ].propertiesArray[
        this.column.properties.selectedVariationIndex
      ].tableProperties.dynamicTable.isHeaderChecked = e.target.value;
    },
    switchStaticRowhandler(e) {
      this.draggable_editor_data[this.index][this.columnType][
        this.childIndex
      ].propertiesArray[
        this.column.properties.selectedVariationIndex
      ].tableProperties.dynamicTable.isStaticRowChecked = e.target.value;
    },
    getCellClass(cell) {
      return {
        "table-cell": true,
        "cell-text": cell.type === "Text",
        "cell-data-tag": cell.type === "Data Tag",
        "cell-image": cell.type === "Image",
      };
    },
    addColumn() {
      const maxColumnCount = 10;

      if (this.tableData?.[0]?.length >= maxColumnCount) return;
      if (this.tableHeaders?.[0]?.length >= maxColumnCount) return;
      if (this.tableStaticRowData?.[0]?.length >= maxColumnCount) return;

      if (this.tableData?.length > 0) {
        this.tableData?.forEach((row) => {
          row.push({
            type: "Text",
            content: "",
            id: this.generateUniqueId(),
            properties: { width: "100", alignment: "20", alterText: "" },
            isHeader: false,
          });
        });
      }

      if (this.tableHeaders?.length > 0) {
        this.tableHeaders?.forEach((row) => {
          row.push({
            type: "Text",
            content: "",
            id: this.generateUniqueId(),
            properties: { width: "100", alignment: "20", alterText: "" },
            isHeader: true,
          });
        });
      }

      if (this.tableStaticRowData?.length > 0) {
        this.tableStaticRowData?.forEach((row) => {
          row.push({
            type: "Text",
            content: "",
            id: this.generateUniqueId(),
            properties: { width: "100", alignment: "20", alterText: "" },
            isStaticRow: true,
          });
        });
      }
    },

    addRow() {
      const newRow = [];
      for (let i = 0; i < this.tableData[0].length; i++) {
        const uniqueId = this.generateUniqueId();
        newRow.push({
          type: "Text",
          content: "",
          id: `${uniqueId}-${i}`,
          properties: { width: "100", alignment: "20", alterText: "" },
          isHeader: false,
        });
      }
      this.tableData.push(newRow);
    },
    addStaticRow() {
      const newRow = [];
      for (let i = 0; i < this.tableData[0].length; i++) {
        const uniqueId = this.generateUniqueId();
        newRow.push({
          type: "Text",
          content: "aaa",
          id: `${uniqueId}-${i}`,
          properties: { width: "100", alignment: "20", alterText: "" },
          isStaticRow: true,
        });
      }
      this.tableStaticRowData.push(newRow);
    },

    removeColumn() {
      if (this.tableData?.length > 0 && this.tableData?.[0]?.length > 1) {
        if (this.tableData?.length > 0) {
          this.tableData.forEach((row) => {
            row.pop();
          });
        }

        if (this.tableHeaders?.length > 0) {
          this.tableHeaders.forEach((row) => {
            row.pop();
          });
        }

        if (this.tableStaticRowData?.length > 0) {
          this.tableStaticRowData.forEach((row) => {
            row.pop();
          });
        }
      }
    },

    removeRow() {
      if (this.tableData.length > 1) {
        this.tableData.pop();
      }
    },
    removeStaticRow() {
      if (this.tableStaticRowData.length > 1) {
        this.tableStaticRowData.pop();
      }
    },
    generateUniqueId() {
      return Math.random().toString(36).substr(2, 9);
    },
    onChangeKeditor(e) {
      this.selectedCell.content = e.html;
      this.updateCellContent();
    },
    onSubmitHandler() {
      this.onConfirm({
        data: this.tableData,
        rows: this.tableData.length,
        columns: this.tableData[0].length,
        headers: this.tableHeaders,
        staticRow: this.staticRowData,
      });
    },
  },
};
</script>

<style lang="scss">
@import "./manageTableModal.scss";
</style>
