<template>
  <div class="d-flex justify-content-between align-items-center">
    <label class="b-form-label mb-0">Alignment</label>
    <div class="buttons">
      <button @click="alignmentHandler('left')" :class="
        isLeftAlign
          ? 'k-button-solid-base border-0 active'
          : 'k-button-solid-base border-0'
      " title="Left Align">
        <alignmentLeft />
      </button>
      <button @click="alignmentHandler('center')" :class="
        iscenterAlign
          ? 'k-button-solid-base border-0 active'
          : 'k-button-solid-base border-0'
      " title="Center Align">
        <alignmentCenter />
      </button>
      <button @click="alignmentHandler('right')" :class="
        isRightAlign
          ? 'k-button-solid-base border-0 active'
          : 'k-button-solid-base border-0'
      " title="Right Align">
        <alignmentRight />
      </button>
    </div>
  </div>
</template>

<script>
import alignmentLeft from '@/components/svg-icons/alignmentLeft.vue';
import alignmentCenter from '@/components/svg-icons/alignmentCenter.vue';
import alignmentRight from '@/components/svg-icons/alignmentRight.vue';


export default {
  inject: ["draggable_editor_data"],
  name: "alignmentComponent",
  components: {
    alignmentLeft,
    alignmentCenter,
    alignmentRight,
  },
  props: {
    column: Object,
    index: Number,
    childIndex: Number,
    columnType: String,
    styleCssClass: Array,
    stateKey: {
      type: String,
      default: "container_style",
    },
  },
  data() {
    return {
      isLeftAlign: false,
      isRightAlign: false,
      iscenterAlign: false,
      isCssMargin: false,
      defaultCss: false,
    };
  },
  mounted() {
    this.isCssMargin = this.styleCssClass == "margin" ? true : false;
    if (!this.isCssMargin) {
      const alignmentType =
        this.draggable_editor_data[this.index][this.columnType][this.childIndex]
          .propertiesArray[this.column.properties.selectedVariationIndex]
          [this.stateKey].textAlign;

      this.isLeftAlign = alignmentType == "left" ? true : false;
      this.iscenterAlign = alignmentType == "center" ? true : false;
      this.isRightAlign = alignmentType == "right" ? true : false;
    }
    if (this.isCssMargin) {
      const alignmentType =
        this.draggable_editor_data[this.index][this.columnType][this.childIndex]
          .propertiesArray[this.column.properties.selectedVariationIndex]
          .alignment;

      this.isLeftAlign = alignmentType == "left" ? true : false;
      this.iscenterAlign = alignmentType == "center" ? true : false;
      this.isRightAlign = alignmentType == "right" ? true : false;
    }
  },
  methods: {
    alignmentHandler(alignmentType) {
      this.isLeftAlign = alignmentType == "left" ? true : false;
      this.iscenterAlign = alignmentType == "center" ? true : false;
      this.isRightAlign = alignmentType == "right" ? true : false;

      if (!this.isCssMargin) {
        this.draggable_editor_data[this.index][this.columnType][
          this.childIndex
        ].propertiesArray[
          this.column.properties.selectedVariationIndex
        ][this.stateKey].textAlign = alignmentType;
      }
      if (this.isCssMargin) {
        this.draggable_editor_data[this.index][this.columnType][
          this.childIndex
        ].propertiesArray[
          this.column.properties.selectedVariationIndex
        ].style.marginRight =
          alignmentType == "left" || alignmentType == "center" ? "auto" : "0";
        this.draggable_editor_data[this.index][this.columnType][
          this.childIndex
        ].propertiesArray[
          this.column.properties.selectedVariationIndex
        ].style.marginLeft =
          alignmentType == "right" || alignmentType == "center" ? "auto" : "0";
        this.draggable_editor_data[this.index][this.columnType][
          this.childIndex
        ].propertiesArray[
          this.column.properties.selectedVariationIndex
        ].style.margin =
          alignmentType == "left"
            ? ""
            : alignmentType == "center"
              ? "auto"
              : alignmentType == "right"
                ? ""
                : "";

        this.draggable_editor_data[this.index][this.columnType][
          this.childIndex
        ].propertiesArray[
          this.column.properties.selectedVariationIndex
        ].alignment = alignmentType;
      }
    },
  },
};
</script>

<style scoped></style>
