<template>
  <div class="widget-accordion-wrap">
    <div
      :class="
        isIconsAccordion
          ? 'widget-accordion-item show'
          : 'widget-accordion-item'
      "
    >
      <div @click="iconsAccordion()" class="widget-accordion-head">
        <span>Social</span>
        <span class="icon">
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M4.06066 11.0607L8.00003 7.12128L11.9394 11.0607L13 10L8.00003 5L3 10L4.06066 11.0607Z"
              fill="#24243A"
            />
          </svg>
        </span>
      </div>
      <div class="widget-accordion-body">
        <div
          v-for="propertyKey in Object.keys(
            column.propertiesArray[column.properties.selectedVariationIndex]
              .iconProperties
          )"
          :key="propertyKey"
          class="widget-input-item social-v2"
        >
          <div class="d-flex justify-content-between align-items-center">
            <label class="b-form-label mb-0">
              {{
                column.propertiesArray[column.properties.selectedVariationIndex]
                  .iconProperties[propertyKey]?.name
              }}
            </label>
            <div class="buttons d-flex">
              <button
                v-for="iconType in column.propertiesArray[
                  column.properties.selectedVariationIndex
                ].iconProperties[propertyKey]?.types"
                :key="iconType"
                @click="updateIconProperties(propertyKey, iconType)"
                :class="
                  column.propertiesArray[
                    column.properties.selectedVariationIndex
                  ].iconProperties[propertyKey].selected == iconType
                    ? 'active'
                    : ''
                "
              >
                {{ iconType.charAt(0).toUpperCase() + iconType.slice(1) }}
              </button>
            </div>
          </div>
        </div>
        <div class="widget-input-item">
          <label class="b-form-label font-16 mb-10">Social Links</label>

          <div class="social-links">
            <Draggable
              :list="
                column.propertiesArray[column.properties.selectedVariationIndex]
                  .mediaData
              "
              itemKey="'name'"
              :component-data="{
                tag: 'div',
                name: 'draggable_social_media_data',
              }"
              handle=".drag-handle"
              v-bind="draggable_social_media_settings"
            >
              <template #item="{ element, index }">
                <div class="social-link p-8 mb-10" :data-controller="index">
                  <div
                    class="d-flex align-items-center justify-content-between mb-2"
                  >
                    <div class="d-flex align-items-center">
                      <k-button
                        v-if="
                          column.propertiesArray[
                            column.properties.selectedVariationIndex
                          ].mediaData.length > 1
                        "
                        :class="'drag-handle'"
                        :icon="'move'"
                        :fill-mode="'flat'"
                      />
                      <label class="b-form-label mb-0">{{
                        element.name == "Apple-Music"
                          ? "Apple Music"
                          : element.name
                      }}</label>
                    </div>
                    <div class="action">
                      <k-button
                        v-if="
                          column.propertiesArray[
                            column.properties.selectedVariationIndex
                          ].mediaData.length > 1
                        "
                        @click="RemoveLinks(element, index)"
                        :icon="'delete'"
                        :fill-mode="'flat'"
                      />
                    </div>
                  </div>
                  <input
                    type="text"
                    class="b-form-field"
                    placeholder="URL"
                    @focusout="linkUpdatecheck($event, index)"
                    :value="element.url"
                  />
                </div>
              </template>
            </Draggable>
          </div>
        </div>

        <div
          v-if="
            column?.propertiesArray[column.properties.selectedVariationIndex]
              .mediaDataSeeMore?.length
          "
          class="widget-input-item"
        >
          <label class="b-form-label">Show More Options</label>
          <div class="more-socials">
            <div
              class="items"
              v-for="(mediaDataSeeMore, index) in column.propertiesArray[
                column.properties.selectedVariationIndex
              ].mediaDataSeeMore"
              :key="index"
            >
              <k-button
                @click="addLinks($event, mediaDataSeeMore, index)"
                :class="'drag-handle'"
                :image-url="mediaDataSeeMore.src"
                :fill-mode="'flat'"
              />
            </div>
          </div>
        </div>

        <div class="widget-input-item alignment">
          <alignmentComponent
            :column="column"
            :columnType="columnType"
            :index="index"
            :childIndex="childIndex"
          />
        </div>

        <div class="widget-input-item font-size">
          <div class="d-flex justify-content-between">
            <label class="b-form-label">Icon Spacing</label>
            <div class="d-flex buttons">
              <button
                @click="minusButtonFrontChange($event)"
                class="k-button-solid-base border-0"
                title="Decrease"
              >
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M3 7V9H13V7H3Z" fill="#6C707D" />
                </svg>
              </button>
              <input
                width="5px"
                type="number"
                @blur="debounceInput($event)"
                :min="0"
                :value="marginInput"
              />
              <button
                @click="plusButtonFrontChange($event)"
                class="k-button-solid-base border-0"
                title="Increase"
              >
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M9 7V3H7V7H3V9H7V13H9V9H13V7H9Z" fill="#6C707D" />
                </svg>
              </button>
            </div>
          </div>
        </div>

        <div class="widget-input-item font-size">
          <div class="d-flex justify-content-between">
            <label class="b-form-label">Vertical Spacing</label>
            <div class="d-flex buttons">
              <button
                @click="
                  column.propertiesArray[
                    column.properties.selectedVariationIndex
                  ].verticalStyle.spacing > 0 &&
                    column.propertiesArray[
                      column.properties.selectedVariationIndex
                    ].verticalStyle.spacing--
                "
                class="k-button-solid-base border-0"
                title="Decrease"
              >
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M3 7V9H13V7H3Z" fill="#6C707D" />
                </svg>
              </button>
              <input
                width="5px"
                class="b-form-field"
                type="number"
                @focusout="updateVerticalSpacing($event)"
                :value="
                  column.propertiesArray[
                    column.properties.selectedVariationIndex
                  ].verticalStyle.spacing
                "
              />
              <button
                @click="
                  column.propertiesArray[
                    column.properties.selectedVariationIndex
                  ].verticalStyle.spacing < 50 &&
                    column.propertiesArray[
                      column.properties.selectedVariationIndex
                    ].verticalStyle.spacing++
                "
                class="k-button-solid-base border-0"
                title="Increase"
              >
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M9 7V3H7V7H3V9H7V13H9V9H13V7H9Z" fill="#6C707D" />
                </svg>
              </button>
            </div>
          </div>
        </div>

        <div class="widget-input-item">
          <div class="d-flex align-items-center justify-content-between">
            <label class="b-form-label">Border <small>(PX)</small></label>
          </div>
          <div class="">
            <div class="row">
              <div class="col-md-12 mb-24">
                <select
                  class="b-form-select"
                  v-model="
                    column.propertiesArray[
                      column.properties.selectedVariationIndex
                    ].generalStyle.borderStyle
                  "
                >
                  <option
                    v-for="data in column.propertiesArray[
                      column.properties.selectedVariationIndex
                    ].borderStyleOptions"
                    :key="data.value"
                    :value="data.value"
                  >
                    {{ data.label }}
                  </option>
                </select>
              </div>

              <div class="widget-input-item">
                <div class="d-flex justify-content-between align-items-center">
                  <label class="b-form-label mb-0">Border Color</label>
                  <ColorPicker
                    :gradient-settings="{ opacity: false }"
                    v-model="
                      column.propertiesArray[
                        column.properties.selectedVariationIndex
                      ].generalStyle.borderColor
                    "
                  />
                </div>
              </div>

              <div>
                <div class="container-padding">
                  <label class="b-form-label"
                    >Border Width <small>(PX)</small></label
                  >
                  <div :class="isLock ? 'inputs-button lock' : 'inputs-button'">
                    <div class="inputs" v-if="!isLock">
                      <input
                        type="text"
                        class="b-form-field"
                        placeholder="5px"
                        @focusout="borderTopWidthHandler($event)"
                        v-model="
                          column.propertiesArray[
                            column.properties.selectedVariationIndex
                          ].generalStyle.borderTopWidth
                        "
                      />
                      <input
                        type="text"
                        class="b-form-field"
                        placeholder="5px"
                        @focusout="borderRightWidthHandler($event)"
                        v-model="
                          column.propertiesArray[
                            column.properties.selectedVariationIndex
                          ].generalStyle.borderRightWidth
                        "
                      />
                      <input
                        type="text"
                        class="b-form-field"
                        placeholder="5px"
                        @focusout="borderBottomWidthHandler($event)"
                        v-model="
                          column.propertiesArray[
                            column.properties.selectedVariationIndex
                          ].generalStyle.borderBottomWidth
                        "
                      />
                      <input
                        type="text"
                        class="b-form-field"
                        placeholder="5px"
                        @focusout="borderLeftWidthHandler($event)"
                        v-model="
                          column.propertiesArray[
                            column.properties.selectedVariationIndex
                          ].generalStyle.borderLeftWidth
                        "
                      />
                    </div>
                    <div class="inputs" v-if="isLock">
                      <input
                        @focusout="changeFunc($event)"
                        type="text"
                        class="b-form-field"
                        placeholder="5px"
                        v-model="lockData"
                      />
                    </div>

                    <button
                      @click="onClickLockBtn()"
                      title="Duplicate"
                      :class="isLock ? 'lock' : ''"
                    >
                      <span v-if="!isLock">
                        <svg
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M7 7H13C13.55 7 14 7.45 14 8V14C14 14.55 13.55 15 13 15H5C4.45 15 4 14.55 4 14V11V8C4 7.45 4.45 7 5 7H6V4C6 2.89688 5.10313 2 4 2C2.89688 2 2 2.89688 2 4V7V8H1V4C1 2.34375 2.34375 1 4 1C5.65625 1 7 2.34375 7 4V7ZM8 11C8 11.5531 8.44688 12 9 12C9.55313 12 10 11.5531 10 11C10 10.4469 9.55313 10 9 10C8.44688 10 8 10.4469 8 11Z"
                            fill="#404A61"
                          />
                        </svg>
                      </span>
                      <span v-if="isLock">
                        <svg
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M12 7H11V4C11 2.34375 9.65625 1 8 1C6.34375 1 5 2.34375 5 4V7H4C3.45 7 3 7.45 3 8V14C3 14.55 3.45 15 4 15H12C12.55 15 13 14.55 13 14V8C13 7.45 12.55 7 12 7ZM6 4C6 2.89688 6.89687 2 8 2C9.10313 2 10 2.89688 10 4V7H6V4ZM7 11C7 11.5531 7.44688 12 8 12C8.55312 12 9 11.5531 9 11C9 10.4469 8.55312 10 8 10C7.44688 10 7 10.4469 7 11Z"
                            fill="#404A61"
                          />
                        </svg>
                      </span>
                    </button>
                  </div>
                  <div class="inputs-buttons-name d-flex" v-if="!isLock">
                    <span>Top</span>
                    <span>Right</span>
                    <span>Bottom</span>
                    <span>Left</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Widget Accordion item-->
    <div
      :class="
        isGeneralAccordion
          ? 'widget-accordion-item show'
          : 'widget-accordion-item'
      "
    >
      <div @click="generalAccordion()" class="widget-accordion-head">
        <span>GENERAL</span>
        <span class="icon">
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M4.06066 11.0607L8.00003 7.12128L11.9394 11.0607L13 10L8.00003 5L3 10L4.06066 11.0607Z"
              fill="#24243A"
            />
          </svg>
        </span>
      </div>
      <generalAccordionComponent
        :column="column"
        :columnType="columnType"
        :index="index"
        :childIndex="childIndex"
        :except="['borderColor', 'borderWidth', 'borderType']"
      />
    </div>
    <div
      :class="
        isRestrictContentToAccordion
          ? 'widget-accordion-item show'
          : 'widget-accordion-item'
      "
    >
      <div @click="restrictContentToAccordion()" class="widget-accordion-head">
        <span>RESTRICT CONTENT TO</span>
        <span class="icon">
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M4.06066 11.0607L8.00003 7.12128L11.9394 11.0607L13 10L8.00003 5L3 10L4.06066 11.0607Z"
              fill="#24243A"
            />
          </svg>
        </span>
      </div>
      <RestrictContentToComponent
        :column="column"
        :columnType="columnType"
        :index="index"
        :childIndex="childIndex"
      />
    </div>
  </div>
</template>

<script>
import Draggable from "vuedraggable";
import { Button } from "@progress/kendo-vue-buttons";
import { Switch } from "@progress/kendo-vue-inputs";
import paddingComponent from "../../common-components/paddingComponent.vue";
import borderWidthComponent from "../../common-components/borderWidthComponent.vue";
import alignmentComponent from "../../common-components/alignmentComponent.vue";
import { ColorPicker } from "@progress/kendo-vue-inputs";
import {
  pxInputHandler,
  pxInputHandlerWithValidation,
} from "../../helpers/utility";
import generalAccordionComponent from "../../common-components/generalAccordionComponent.vue";
import { Input } from "@progress/kendo-vue-inputs";
import RestrictContentToComponent from "../../common-components/restrictContentToComponent.vue";
export default {
  inject: ["draggable_editor_data"],
  name: "socialController",
  components: {
    Draggable,
    "k-button": Button,
    "k-input": Input,
    Switch,
    paddingComponent,
    borderWidthComponent,
    ColorPicker,
    alignmentComponent,
    generalAccordionComponent,
    RestrictContentToComponent,
  },
  props: {
    column: Object,
    index: Number,
    childIndex: Number,
    columnType: String,
  },
  data() {
    return {
      draggable_social_media_settings: {
        animation: 200,
        group: {
          // name: "draggable_editor_data",
          pull: false,
          put: false, // Do not allow items to be put into this list
        },
        disabled: false,
        ghostClass: "ghost",
      },
      isIconsAccordion: true,
      isGeneralAccordion: true,
      isResponsiveDesignAccordion: true,
      isRestrictContentToAccordion: true,
      isLeftAlign: false,
      isRightAlign: false,
      iscenterAlign: false,
      isLock: false,
      lockData: "",
      marginInput: "5",
      isLockData: "",
      iconSize: {
        Small: "32px",
        Medium: "48px",
        Large: "64px",
      },
    };
  },
  mounted() {
    this.marginInput =
      this.draggable_editor_data[this.index][this.columnType][
        this.childIndex
      ].propertiesArray[
        this.column.properties.selectedVariationIndex
      ].style.paddingLeft.split("p")[0];

    this.isLock =
      this.draggable_editor_data[this.index][this.columnType][
        this.childIndex
      ].propertiesArray[
        this.column.properties.selectedVariationIndex
      ]?.isWidthLock;
    if (this.isLock)
      this.lockData =
        this.draggable_editor_data[this.index][this.columnType][
          this.childIndex
        ].propertiesArray[
          this.column.properties.selectedVariationIndex
        ].generalStyle.borderTopWidth;
  },
  methods: {
    updateVerticalSpacing(e) {
      let value = e.target.value;

      if (value < 0) {
        value = 0;
      }

      if (value > 50) {
        value = 50;
      }

      this.column.propertiesArray[
        this.column.properties.selectedVariationIndex
      ].verticalStyle.spacing = value;
    },
    restrictContentToAccordion() {
      this.isRestrictContentToAccordion = !this.isRestrictContentToAccordion;
    },

    updateMediaDataHandler() {
      let URL =
        "https://clubeez-v2-local.s3-ap-southeast-1.amazonaws.com/Campaign/";

      for (const [key, value] of Object.entries(
        this.draggable_editor_data[this.index][this.columnType][this.childIndex]
          .propertiesArray[this.column.properties.selectedVariationIndex]
          .iconProperties
      )) {
        URL += value.selected + "/";
      }

      this.draggable_editor_data[this.index][this.columnType][
        this.childIndex
      ].propertiesArray[
        this.column.properties.selectedVariationIndex
      ].mediaData.forEach((item, key) => {
        item.src = URL + item.name.toLowerCase() + ".png";
      });

      this.draggable_editor_data[this.index][this.columnType][
        this.childIndex
      ].propertiesArray[
        this.column.properties.selectedVariationIndex
      ].iconImgStyle = {
        width:
          this.iconSize[
            this.draggable_editor_data[this.index][this.columnType][
              this.childIndex
            ].propertiesArray[this.column.properties.selectedVariationIndex]
              .iconProperties.iconSize.selected
          ],
        height:
          this.iconSize[
            this.draggable_editor_data[this.index][this.columnType][
              this.childIndex
            ].propertiesArray[this.column.properties.selectedVariationIndex]
              .iconProperties.iconSize.selected
          ],
      };

      this.draggable_editor_data[this.index][this.columnType][
        this.childIndex
      ].propertiesArray[
        this.column.properties.selectedVariationIndex
      ].style.height =
        this.iconSize[
          this.draggable_editor_data[this.index][this.columnType][
            this.childIndex
          ].propertiesArray[
            this.column.properties.selectedVariationIndex
          ].iconProperties.iconSize.selected
        ];
    },

    updateIconProperties(propertyKey, iconType) {
      this.draggable_editor_data[this.index][this.columnType][
        this.childIndex
      ].propertiesArray[
        this.column.properties.selectedVariationIndex
      ].iconProperties[propertyKey].selected = iconType;

      this.updateMediaDataHandler();
    },
    iconsAccordion() {
      this.isIconsAccordion = !this.isIconsAccordion;
    },
    generalAccordion() {
      this.isGeneralAccordion = !this.isGeneralAccordion;
    },
    responsiveDesignAccordion() {
      this.isResponsiveDesignAccordion = !this.isResponsiveDesignAccordion;
    },
    RemoveLinks(element, index) {
      this.emitFunction("RemoveLinks", element, index, false);
    },
    addLinks(event, element, index) {
      this.emitFunction("addLinks", element, index, false);
    },
    minusButtonFrontChange(e) {
      let value = this.marginInput.split("px")[0];
      if (value > 0) {
        this.emitFunction("minusButtonFront", e);
      }
    },

    plusButtonFrontChange(e) {
      let value = this.marginInput.split("px")[0];
      if (value < 50) {
        this.emitFunction("plusButtonFront", e);
      }
    },

    debounceInput(e) {
      this.marginInput = pxInputHandlerWithValidation(e.target.value) + "px";
      e.target.value = e.target.value > 50 ? 50 : e.target.value < 0 ? 0 : e.target.value;
      this.emitFunction("marginInput", e);
    },

    onClickLockBtn(e) {
      this.isLock = !this.isLock;
      this.emitFunction("onClickLockBtn", e, this.isLock);
    },

    changeFunc(e) {
      this.emitFunction("lockChangeFunc", e, this.isLock);
    },

    linkUpdatecheck(e, idx) {
      this.draggable_editor_data[this.index][this.columnType][
        this.childIndex
      ].propertiesArray[
        this.column.properties.selectedVariationIndex
      ].mediaData[idx].url = e.target.value.trim().includes("https://")
        ? e.target.value
        : e.target.value.trim() != ""
        ? "https://" + e.target.value
        : "";
    },

    borderTopWidthHandler(e) {
      this.emitFunction("borderTopWidthHandler", e);
    },
    borderRightWidthHandler(e) {
      this.emitFunction("borderRightWidthHandler", e, this.isLock);
    },
    borderBottomWidthHandler(e) {
      this.emitFunction("borderBottomWidthHandler", e, this.isLock);
    },
    borderLeftWidthHandler(e) {
      this.emitFunction("borderLeftWidthHandler", e, this.isLock);
    },

    emitFunction(type, e, isFirstValue, isSecondValue) {
      this.socialControllerEmit(
        type,
        e,
        this.index,
        this.childIndex,
        this.columnType,
        isFirstValue,
        isSecondValue,
        this.column.properties.selectedVariationIndex
      );
    },
    socialControllerEmit(
      type,
      e,
      index,
      childIndex,
      columnType,
      isFirstValue,
      isSecondValue,
      variationIndex
    ) {
      if (type == "RemoveLinks") {
        var isThere = false;
        this.draggable_editor_data[index][columnType][
          childIndex
        ].propertiesArray[variationIndex].mediaDataSeeMore.forEach(
          (mediaDataSeeMores) => {
            if (e.name == mediaDataSeeMores.name) {
              isThere = true;
            }
          }
        );
        if (!isThere) {
          this.draggable_editor_data[index][columnType][
            childIndex
          ].propertiesArray[variationIndex].mediaDataSeeMore.push(e);
        }
        this.draggable_editor_data[index][columnType][
          childIndex
        ].propertiesArray[variationIndex].mediaData.splice(isFirstValue, 1);
      }
      if (type == "addLinks") {
        var isThere = false;
        this.draggable_editor_data[index][columnType][
          childIndex
        ].propertiesArray[variationIndex].mediaDataSeeMore.forEach(
          (mediaDataSeeMores) => {
            if (e.name == mediaDataSeeMores.name) {
              isThere = true;
            }
          }
        );
        if (isThere) {
          this.draggable_editor_data[index][columnType][
            childIndex
          ].propertiesArray[variationIndex].mediaDataSeeMore.splice(
            isFirstValue,
            1
          );

          this.draggable_editor_data[index][columnType][
            childIndex
          ].propertiesArray[variationIndex].mediaData.push(e);
        }

        this.updateMediaDataHandler();
      }

      if (type == "minusButtonFront") {
        var onlyPxInt =
          this.draggable_editor_data[index][columnType][
            childIndex
          ].propertiesArray[variationIndex].style.paddingLeft.split("p");
        this.draggable_editor_data[index][columnType][
          childIndex
        ].propertiesArray[variationIndex].style.paddingLeft = onlyPxInt[0];
        this.draggable_editor_data[index][columnType][
          childIndex
        ].propertiesArray[variationIndex].style.paddingLeft -= 1;
        if (
          this.draggable_editor_data[index][columnType][childIndex]
            .propertiesArray[variationIndex].style.paddingLeft < 0
        ) {
          this.draggable_editor_data[index][columnType][
            childIndex
          ].propertiesArray[variationIndex].style.paddingLeft = "16";
        }
        this.draggable_editor_data[index][columnType][
          childIndex
        ].propertiesArray[variationIndex].style.paddingLeft =
          this.draggable_editor_data[index][columnType][childIndex]
            .propertiesArray[variationIndex].style.paddingLeft + "px";
        this.marginInput =
          this.draggable_editor_data[index][columnType][
            childIndex
          ].propertiesArray[variationIndex].style.paddingLeft.split("p")[0];

        this.draggable_editor_data[index][columnType][
          childIndex
        ].propertiesArray[variationIndex].style.paddingRight =
          this.draggable_editor_data[index][columnType][
            childIndex
          ].propertiesArray[variationIndex].style.paddingLeft;
      }
      if (type == "plusButtonFront") {
        var onlyPxInt =
          this.draggable_editor_data[index][columnType][
            childIndex
          ].propertiesArray[variationIndex].style.paddingLeft.split("p");
        this.draggable_editor_data[index][columnType][
          childIndex
        ].propertiesArray[variationIndex].style.paddingLeft = parseInt(
          onlyPxInt[0]
        );
        this.draggable_editor_data[index][columnType][
          childIndex
        ].propertiesArray[variationIndex].style.paddingLeft =
          this.draggable_editor_data[index][columnType][childIndex]
            .propertiesArray[variationIndex].style.paddingLeft + 1;
        this.draggable_editor_data[index][columnType][
          childIndex
        ].propertiesArray[variationIndex].style.paddingLeft =
          this.draggable_editor_data[index][columnType][childIndex]
            .propertiesArray[variationIndex].style.paddingLeft + "px";
        this.marginInput =
          this.draggable_editor_data[index][columnType][
            childIndex
          ].propertiesArray[variationIndex].style.paddingLeft.split("p")[0];
        this.draggable_editor_data[index][columnType][
          childIndex
        ].propertiesArray[variationIndex].style.paddingRight =
          this.draggable_editor_data[index][columnType][
            childIndex
          ].propertiesArray[variationIndex].style.paddingLeft;
      }
      if (type == "marginInput") {
        this.draggable_editor_data[index][columnType][
          childIndex
        ].propertiesArray[variationIndex].style.paddingLeft = pxInputHandler(
          e.target.value
        );
        this.marginInput =
          this.draggable_editor_data[index][columnType][
            childIndex
          ].propertiesArray[variationIndex].style.paddingLeft.split("p")[0];
        this.draggable_editor_data[index][columnType][
          childIndex
        ].propertiesArray[variationIndex].style.paddingRight =
          this.draggable_editor_data[index][columnType][
            childIndex
          ].propertiesArray[variationIndex].style.paddingLeft;
      }

      if (type == "onClickLockBtn") {
        this.draggable_editor_data[index][columnType][
          childIndex
        ].propertiesArray[variationIndex].isWidthLock = this.isLock;
        if (
          this.draggable_editor_data[index][columnType][childIndex]
            .propertiesArray[variationIndex].generalStyle.borderTopWidth
        ) {
          this.lockData =
            this.draggable_editor_data[index][columnType][
              childIndex
            ].propertiesArray[variationIndex].generalStyle.borderTopWidth;
          this.draggable_editor_data[index][columnType][
            childIndex
          ].propertiesArray[variationIndex].generalStyle.borderRightWidth =
            this.draggable_editor_data[index][columnType][
              childIndex
            ].propertiesArray[variationIndex].generalStyle.borderTopWidth;
          this.draggable_editor_data[index][columnType][
            childIndex
          ].propertiesArray[variationIndex].generalStyle.borderBottomWidth =
            this.draggable_editor_data[index][columnType][
              childIndex
            ].propertiesArray[variationIndex].generalStyle.borderTopWidth;
          this.draggable_editor_data[index][columnType][
            childIndex
          ].propertiesArray[variationIndex].generalStyle.borderLeftWidth =
            this.draggable_editor_data[index][columnType][
              childIndex
            ].propertiesArray[variationIndex].generalStyle.borderTopWidth;
        } else {
          this.draggable_editor_data[index][columnType][
            childIndex
          ].propertiesArray[variationIndex].generalStyle.borderTopWidth =
            "10px";
          this.draggable_editor_data[index][columnType][
            childIndex
          ].propertiesArray[variationIndex].generalStyle.borderRightWidth =
            "10px";
          this.draggable_editor_data[index][columnType][
            childIndex
          ].propertiesArray[variationIndex].generalStyle.borderBottomWidth =
            "10px";
          this.draggable_editor_data[index][columnType][
            childIndex
          ].propertiesArray[variationIndex].generalStyle.borderLeftWidth =
            "10px";
        }
      }

      if (type == "lockChangeFunc") {
        this.lockData = pxInputHandler(e.target.value);
        this.draggable_editor_data[index][columnType][
          childIndex
        ].propertiesArray[variationIndex].generalStyle.borderTopWidth =
          pxInputHandler(e.target.value);
        this.draggable_editor_data[index][columnType][
          childIndex
        ].propertiesArray[variationIndex].generalStyle.borderRightWidth =
          pxInputHandler(e.target.value);
        this.draggable_editor_data[index][columnType][
          childIndex
        ].propertiesArray[variationIndex].generalStyle.borderLeftWidth =
          pxInputHandler(e.target.value);
        this.draggable_editor_data[index][columnType][
          childIndex
        ].propertiesArray[variationIndex].generalStyle.borderBottomWidth =
          pxInputHandler(e.target.value);
        // this.draggable_editor_data[index][columnType][
        //   childIndex
        // ].propertiesArray[variationIndex].generalStyle.textAlign = "center";
      }
      if (type == "borderTopWidthHandler") {
        this.draggable_editor_data[index][columnType][
          childIndex
        ].propertiesArray[variationIndex].generalStyle.borderTopWidth =
          pxInputHandler(e.target.value);
      }
      if (type == "borderRightWidthHandler") {
        this.draggable_editor_data[index][columnType][
          childIndex
        ].propertiesArray[variationIndex].generalStyle.borderRightWidth =
          pxInputHandler(e.target.value);
      }
      if (type == "borderBottomWidthHandler") {
        this.draggable_editor_data[index][columnType][
          childIndex
        ].propertiesArray[variationIndex].generalStyle.borderBottomWidth =
          pxInputHandler(e.target.value);
      }
      if (type == "borderLeftWidthHandler") {
        this.draggable_editor_data[index][columnType][
          childIndex
        ].propertiesArray[variationIndex].generalStyle.borderLeftWidth =
          pxInputHandler(e.target.value);
      }
    },
  },
};
</script>
