<template>
    <modal :title="'Removing Footer'" :handler="true" :cssClass="'c-modal-small-2'" @modalHandler="modalHandler">
      <template v-slot:content>
        <div class="k-form-field">
          Please make sure that there is an unsubscribe option in your email if you want to remove the footer.
          If you don't include it, our default footer will be added in its place at the bottom of your email.
        </div>
      </template>
      <template v-slot:footer>
        <div class="d-flex justify-content-end">
          <div class="me-1">
            <kbutton @click="modalHandler" :class="'flex-none'">Cancel</kbutton>
          </div>
          <div class="ms-1">
            <kbutton :theme-color="'primary'" @click="save" :class="'flex-none'">Confirm</kbutton>
          </div>
        </div>
      </template>
    </modal>
  </template>
  
  <script>
  import { Dialog, DialogActionsBar } from "@progress/kendo-vue-dialogs";
  import { Button } from "@progress/kendo-vue-buttons";
  import { Input } from "@progress/kendo-vue-inputs";
  import modal from "../../common/AppModal/AppModal";
  
  export default {
    components: {
      "k-dialog": Dialog,
      "dialog-actions-bar": DialogActionsBar,
      kbutton: Button,
      "k-input": Input,
      modal,
    },
    props: {
      preFieldTemplateName: String,
    },
    data: function () {
      return {
      };
    },
    computed: {
    },
    mounted() {
    },
    methods: {
      modalHandler(data) {
        this.$emit("cancelRemoveFooter");
      },
      save(data) {
        
        this.$emit("confirmedRemoveFooter", this.templateName);
        // this.modalHandler();
      },
    },
  };
  </script>
  
  <style scoped>
  
  </style>
  