<template>
  <svg
    width="14"
    height="12"
    viewBox="0 0 14 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.0007 0C13.3689 0 13.6673 0.29848 13.6673 0.666667V11.3333C13.6673 11.7015 13.3689 12 13.0007 12H1.00065C0.632464 12 0.333984 11.7015 0.333984 11.3333V0.666667C0.333984 0.29848 0.632464 0 1.00065 0H13.0007ZM12.334 1.33333H1.66732V10.6667H12.334V1.33333ZM11.0007 2.66667V9.33333H9.66732V2.66667H11.0007Z"
      fill="#24243A"
    />
  </svg>
</template>
        <script>
export default {
  name: "orientationVerticalLeft",
};
</script>
    
    <style scoped></style>