<template>
  <div class="widget-accordion-wrap">
    <!-- Widget Accordion item-->
    <div
      :class="
        isGeneralAccordion
          ? 'widget-accordion-item show'
          : 'widget-accordion-item'
      "
    >
      <div @click="generalAccordion()" class="widget-accordion-head">
        <span>GENERAL</span>
        <span class="icon">
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M4.06066 11.0607L8.00003 7.12128L11.9394 11.0607L13 10L8.00003 5L3 10L4.06066 11.0607Z"
              fill="#24243A"
            />
          </svg>
        </span>
      </div>
      <div class="widget-accordion-body">
        <div class="widget-input-item font-size">
          <div class="d-flex justify-content-between align-items-center">
            <label class="b-form-label mb-0">Container Width</label>
            <div class="d-flex buttons">
              <button
                @click="minusButtonFrontChange($event)"
                class="k-button-solid-base border-0"
                title="Decrease"
              >
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M3 7V9H13V7H3Z" fill="#6C707D" />
                </svg>
              </button>
              <k-input
                width="5px"
                type="number"
                @blur="debounceInput($event)"
                :min="0"
                v-model="marginInput"
              />
              <button
                @click="plusButtonFrontChange($event)"
                class="k-button-solid-base border-0"
                title="Increase"
              >
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M9 7V3H7V7H3V9H7V13H9V9H13V7H9Z" fill="#6C707D" />
                </svg>
              </button>
            </div>
          </div>
        </div>
        <div class="widget-input-item">
          <div class="d-flex justify-content-between align-items-center">
            <label class="b-form-label mb-0">Background Color</label>
            <ColorPicker
              :gradient-settings="{ opacity: false }"
              v-model="global_style.backgroundColor"
            />
          </div>
        </div>
      </div>
    </div>
    <div
      :class="
        isHeaderAccordion
          ? 'widget-accordion-item show'
          : 'widget-accordion-item'
      "
    >
      <div @click="headerAccordion()" class="widget-accordion-head">
        <span>Links</span>
        <span class="icon">
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M4.06066 11.0607L8.00003 7.12128L11.9394 11.0607L13 10L8.00003 5L3 10L4.06066 11.0607Z"
              fill="#24243A"
            />
          </svg>
        </span>
      </div>
      <div class="widget-accordion-body">
        <div
          class="widget-input-item d-flex justify-content-between align-items-center"
        >
          <label class="b-form-label mb-0">Link Color</label>
          <ColorPicker v-model="link_style.color" />
        </div>
        <div
          class="widget-input-item d-flex justify-content-between align-items-center"
        >
          <label class="b-form-label mb-0">Underline</label>
          <Switch
            @change="onChange"
            :on-label="' '"
            :off-label="' '"
            :size="'small'"
            :checked="underlineValue"
          />
        </div>
      </div>
    </div>
    <div
      :class="
        isFooterAccordion
          ? 'widget-accordion-item show'
          : 'widget-accordion-item'
      "
    >
      <div @click="footerAccordion()" class="widget-accordion-head">
        <span>Footer</span>
        <span class="icon">
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M4.06066 11.0607L8.00003 7.12128L11.9394 11.0607L13 10L8.00003 5L3 10L4.06066 11.0607Z"
              fill="#24243A"
            />
          </svg>
        </span>
      </div>
      <div class="widget-accordion-body">
        <div class="widget-input-item">
          <div>
            <footerContent :data="templateData.footer" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Input, Switch } from "@progress/kendo-vue-inputs";

import { ColorPicker } from "@progress/kendo-vue-inputs";
import headerContent from "./headerContent.vue";
import footerContent from "./footerContent.vue";
import { pxInputHandler } from "../helpers/utility";
export default {
  inject: ["draggable_editor_data"],
  name: "generalTools",
  components: {
    KInput: Input,
    ColorPicker,
    Switch,
    headerContent,
    footerContent,
  },
  inject: ["templateData", "wrapperContainerStyle"],
  data() {
    return {
      isGeneralAccordion: true,
      isHeaderAccordion: true,
      isFooterAccordion: true,
      isLeftAlign: false,
      isRightAlign: false,
      iscenterAlign: false,
      checked: false,
      marginInput: "500",
      underlineValue: false,
    };
  },
  props: {
    wrapperContainerStyle: Object,
    // templateData: Object,
    global_style: Object,
    link_style: Object,
    onChangeWidth: { type: Function },
  },
  mounted() {
    this.marginInput = this.wrapperContainerStyle.width.split("p")[0];
    if (this.link_style.textDecoration === "underline") {
      this.underlineValue = true;
    }
    if (this.link_style.textDecoration === "none") {
      this.underlineValue = false;
    }
  },
  methods: {
    generalAccordion() {
      this.isGeneralAccordion = !this.isGeneralAccordion;
    },
    headerAccordion() {
      this.isHeaderAccordion = !this.isHeaderAccordion;
    },
    footerAccordion() {
      this.isFooterAccordion = !this.isFooterAccordion;
    },

    onChange(e) {
      this.underlineValue = e.target.value;
      this.emitFunction("switchOnChange", e, false, false);
    },
    minusButtonFrontChange(e) {
      var onlyPxInt = this.wrapperContainerStyle.width.split("p")[0];
      this.wrapperContainerStyle.width = onlyPxInt;

      this.wrapperContainerStyle.width -= 10;
      if (this.wrapperContainerStyle.width < 0) {
        this.wrapperContainerStyle.width = "500";
      }
      this.wrapperContainerStyle.width =
        this.wrapperContainerStyle.width + "px";
      this.onChangeWidth(this.wrapperContainerStyle.width + "px");
      this.marginInput = this.wrapperContainerStyle.width.split("p")[0];
    },

    plusButtonFrontChange(e) {
      var onlyPxInt = this.wrapperContainerStyle.width.split("p");
      this.wrapperContainerStyle.width = parseInt(onlyPxInt[0]);
      this.wrapperContainerStyle.width = this.wrapperContainerStyle.width + 10;
      if (this.wrapperContainerStyle.width >= 900) {
        this.wrapperContainerStyle.width = "900";
      }
      this.wrapperContainerStyle.width =
        this.wrapperContainerStyle.width + "px";
      this.onChangeWidth(this.wrapperContainerStyle.width + "px");
      this.marginInput = this.wrapperContainerStyle.width.split("p")[0];
    },
    debounceInput(e) {
      this.marginInput = this.pxHandlerLocal(e.target.value);

      this.wrapperContainerStyle.width = this.pxHandlerLocal(e.target.value);
      this.marginInput = this.wrapperContainerStyle.width.split("p")[0];
      this.onChangeWidth(this.pxHandlerLocal(e.target.value));
    },
    pxHandlerLocal(input) {
      if (input.toLowerCase().split("p")[0] <= 0) {
        return "500px";
      }
      if (input.toLowerCase() <= 0) {
        return "500px";
      }
      if (input.toLowerCase() >= 900) {
        return "900px";
      }
      if (input.toLowerCase().endsWith("px")) return input.toLowerCase();
      else {
        return input + "px";
      }
    },

    emitFunction(type, e, isFirstValue, isSecondValue) {
      this.generalToolsEmit(
        type,
        e,
        this.wrapperContainerStyle,
        this.global_style,
        this.link_style,
        isFirstValue,
        isSecondValue
      );
    },
    generalToolsEmit(
      type,
      e,
      wrapperContainerStyle,
      global_style,
      link_style,
      isFirstValue,
      isSecondValue
    ) {
      if (type == "switchOnChange") {
        if (e.target.value) {
          link_style.textDecoration = "underline";
        } else {
          link_style.textDecoration = "none";
        }

        // wrapperContainerStyle.textAlign = "right";
      }
    },
  },
};
</script>

<style scoped></style>
