<template>
    <svg width="33" height="16" viewBox="0 0 33 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="0.333984" y="0.746094" width="32" height="14.5067" rx="6" fill="#2B354C" fill-opacity="0.65"/>
    </svg>
</template>
<script>
    export default {
        name: "buttonIcon"
    }
</script>
<style scoped></style>