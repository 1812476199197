<template>
    <svg aria-hidden="true" focusable="false" data-prefix="fal" data-icon="font" class="svg-inline--fa fa-font fa-w-14 fa-3x" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
        <path fill="currentColor" d="M0,37.215v55v15v300.57h445v-300.57v-15v-55H0z M276.667,277.595H168.333v-70.19h108.334V277.595z M306.667,207.405H415
                v70.19H306.667V207.405z M276.667,307.595v70.19H168.333v-70.19H276.667z M30,207.405h108.333v70.19H30V207.405z M168.333,177.405
                v-70.19h108.334v70.19H168.333z M138.333,107.215v70.19H30v-70.19H138.333z M30,307.595h108.333v70.19H30V307.595z M306.667,377.785
                v-70.19H415v70.19H306.667z M415,177.405H306.667v-70.19H415V177.405z"></path>
    </svg>
</template>
<script>
    export default {
        name: "invoiceIcon"
    }
</script>
<style scoped></style>