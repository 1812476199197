<template>
  <div class="widget-accordion-wrap">
    <!-- Widget Accordion item-->
    <div :class="
      isHtmlAccordionVisible
        ? 'widget-accordion-item show'
        : 'widget-accordion-item'
    ">
      <div @click="toggleHtmlAccordion()" class="widget-accordion-head">
        <span>HTML</span>
        <span class="icon">
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M4.06066 11.0607L8.00003 7.12128L11.9394 11.0607L13 10L8.00003 5L3 10L4.06066 11.0607Z"
              fill="#24243A" />
          </svg>
        </span>
      </div>
      <div class="widget-accordion-body">
        <div class="widget-input-item widget-editor input-link-custom-modal">
          <prism-editor class="my-editor" v-model="
            column.propertiesArray[column.properties.selectedVariationIndex]
              .content
          " :highlight="highlighter" line-numbers></prism-editor>
        </div>
      </div>
    </div>
    <!-- Widget Accordion item-->
    <div :class="
      isGeneralAccordion
        ? 'widget-accordion-item show'
        : 'widget-accordion-item'
    ">
      <div @click="toggleGeneralAccordion()" class="widget-accordion-head">
        <span>General</span>
        <span class="icon">
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M4.06066 11.0607L8.00003 7.12128L11.9394 11.0607L13 10L8.00003 5L3 10L4.06066 11.0607Z"
              fill="#24243A" />
          </svg>
        </span>
      </div>
      <generalAccordionComponent :column="column" :columnType="columnType" :index="index" :childIndex="childIndex" />
    </div>
    <div :class="
      isRestrictContentToAccordion
        ? 'widget-accordion-item show'
        : 'widget-accordion-item'
    ">
      <div @click="restrictContentToAccordion()" class="widget-accordion-head">
        <span>RESTRICT CONTENT TO</span>
        <span class="icon">
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M4.06066 11.0607L8.00003 7.12128L11.9394 11.0607L13 10L8.00003 5L3 10L4.06066 11.0607Z"
              fill="#24243A" />
          </svg>
        </span>
      </div>
      <RestrictContentToComponent :column="column" :columnType="columnType" :index="index" :childIndex="childIndex" />
    </div>
  </div>
</template>

<script>
import { Input, Switch } from "@progress/kendo-vue-inputs";
import generalAccordionComponent from "../../common-components/generalAccordionComponent.vue";
import RestrictContentToComponent from "../../common-components/restrictContentToComponent.vue";

import { PrismEditor } from "vue-prism-editor";
import "vue-prism-editor/dist/prismeditor.min.css";
import { highlight, languages } from "prismjs/components/prism-core";
import "prismjs/components/prism-clike";
import "prismjs/components/prism-javascript";
import "prismjs/themes/prism-tomorrow.css";

export default {
  name: "htmlEditorController",
  components: {
    KInput: Input,
    KSwitch: Switch,
    generalAccordionComponent,
    RestrictContentToComponent,
    PrismEditor,
  },
  props: {
    column: Object,
    index: Number,
    childIndex: Number,
    columnType: String,
  },
  data() {
    return {
      editorOptions: {
        behavioursEnabled: true,
        wrapBehavioursEnabled: true,
        highlightActiveLine: false,
        highlightSelectedWord: false,
        useSoftTabs: true,
        cursorStyle: "smooth",
      },
      isHtmlAccordionVisible: true,
      isGeneralAccordion: true,
      isRestrictContentToAccordion: true,
    };
  },
  methods: {
    highlighter(code) {
      return highlight(code, languages.js);
    },
    toggleHtmlAccordion() {
      this.isHtmlAccordionVisible = !this.isHtmlAccordionVisible;
    },
    toggleGeneralAccordion() {
      this.isGeneralAccordion = !this.isGeneralAccordion;
    },
    restrictContentToAccordion() {
      this.isRestrictContentToAccordion = !this.isRestrictContentToAccordion;
    },
  },
};
</script>

<style scoped>
.my-editor {
  background: #2d2d2d;
  color: #ccc;
  font-family: Fira code, Fira Mono, Consolas, Menlo, Courier, monospace;
  font-size: 14px;
  line-height: 1.5;
  padding: 5px;
  min-height: 298px;
  max-height: 350px;
  overflow-y: auto;
}

.prism-editor__textarea:focus {
  outline: none;
}
</style>
