<template>
  <div class="widget-accordion-wrap">
    <div
      :class="
        isOptionsAccordion
          ? 'widget-accordion-item show'
          : 'widget-accordion-item'
      "
    >
      <div @click="optionsAccordion()" class="widget-accordion-head">
        <span>Options</span>
        <span class="icon">
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M4.06066 11.0607L8.00003 7.12128L11.9394 11.0607L13 10L8.00003 5L3 10L4.06066 11.0607Z"
              fill="#24243A"
            />
          </svg>
        </span>
      </div>
      <div class="widget-accordion-body">
        <div class="widget-input-item border-bottom-0 pb-0 mb-13">
          <div class="d-flex align-items-center justify-content-between">
            <label class="b-form-label">Thickness</label>
            <div class="input-box-with-name">
              <KInput
                :class="'mb-0 h-38 w-80 font-14'"
                width="5px"
                type="number"
                @blur="debounceInput($event)"
                :min="0"
                v-model="fontSizeInput"
              /><span class="box-name">PX</span>
            </div>
          </div>
        </div>
        <div class="widget-input-item border-bottom-0 pb-0 mb-13">
          <div class="d-flex align-items-center justify-content-between">
            <label class="b-form-label">Style</label>
            <div class="">
              <div class="">
                <div class="widget-input-item">
                  <select
                    class="b-form-select min-w-162 border-sky-gray-3 radius-8 ps-3 font-14"
                    v-model="
                      column.propertiesArray[
                        column.properties.selectedVariationIndex
                      ].borderStyles.borderStyle
                    "
                  >
                    <option
                      v-for="data in column.propertiesArray[
                        column.properties.selectedVariationIndex
                      ].borderStyleOptions"
                      :key="data.value"
                      :value="data.value"
                    >
                      {{ data.label }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="widget-input-item border-bottom-0 pb-0 mb-13">
          <div class="d-flex justify-content-between align-items-center">
            <label class="b-form-label mb-0">Color</label>
            <ColorPicker
              :gradient-settings="{ opacity: false }"
              v-model="
                column.propertiesArray[column.properties.selectedVariationIndex]
                  .borderStyles.borderColor
              "
            />
          </div>
        </div>
        <div class="widget-input-item border-bottom-0 pb-0 mb-13">
          <div class="d-flex justify-content-between align-items-center">
            <label class="b-form-label mb-0">Width</label>
            <slider
              :class="'w-100 ms-5'"
              :buttons="false"
              :step="1"
              :default-value="autoWidthValue"
              :value="autoWidthValue"
              :min="1"
              :max="100"
              @change="widthSlider($event)"
            >
            </slider>
            <span class="font-16 ms-3">{{ Math.round(autoWidthValue) }}%</span>
          </div>
        </div>
        <div class="widget-input-item alignment">
          <alignmentComponent
            :column="column"
            :columnType="columnType"
            :index="index"
            :childIndex="childIndex"
            :styleCssClass="['margin']"
          />
        </div>
      </div>
    </div>
    <!-- Widget Accordion item-->
    <div
      :class="
        isGeneralAccordion
          ? 'widget-accordion-item show'
          : 'widget-accordion-item'
      "
    >
      <div @click="generalAccordion()" class="widget-accordion-head">
        <span>GENERAL</span>
        <span class="icon">
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M4.06066 11.0607L8.00003 7.12128L11.9394 11.0607L13 10L8.00003 5L3 10L4.06066 11.0607Z"
              fill="#24243A"
            />
          </svg>
        </span>
      </div>
      <generalAccordionComponent
        :column="column"
        :columnType="columnType"
        :index="index"
        :childIndex="childIndex"
        :except="['borderColor', 'borderWidth', 'borderType']"
      />
    </div>
    <div
      :class="
        isRestrictContentToAccordion
          ? 'widget-accordion-item show'
          : 'widget-accordion-item'
      "
    >
      <div @click="restrictContentToAccordion()" class="widget-accordion-head">
        <span>RESTRICT CONTENT TO</span>
        <span class="icon">
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M4.06066 11.0607L8.00003 7.12128L11.9394 11.0607L13 10L8.00003 5L3 10L4.06066 11.0607Z"
              fill="#24243A"
            />
          </svg>
        </span>
      </div>
      <RestrictContentToComponent
        :column="column"
        :columnType="columnType"
        :index="index"
        :childIndex="childIndex"
      />
    </div>
  </div>
</template>

<script>
import { ColorPicker } from "@progress/kendo-vue-inputs";
import { Switch, Input } from "@progress/kendo-vue-inputs";
import { Slider } from "@progress/kendo-vue-inputs";
import borderWidthComponent from "../../common-components/borderWidthComponent.vue";
import alignmentComponent from "../../common-components/alignmentComponent.vue";
import paddingComponent from "../../common-components/paddingComponent.vue";
import generalAccordionComponent from "../../common-components/generalAccordionComponent.vue";
import RestrictContentToComponent from "../../common-components/restrictContentToComponent.vue";
import { pxInputHandler } from "../../helpers/utility";

export default {
  inject: ["draggable_editor_data"],
  name: "lineController",
  components: {
    ColorPicker,
    Switch,
    Slider,
    borderWidthComponent,
    paddingComponent,
    generalAccordionComponent,
    alignmentComponent,
    RestrictContentToComponent,
    KInput: Input,
  },
  data() {
    return {
      isOptionsAccordion: true,
      isGeneralAccordion: true,
      isResponsiveDesignAccordion: true,
      isRestrictContentToAccordion: true,
      autoWidthValue: 100,
      isLeftAlign: true,
      isRightAlign: false,
      iscenterAlign: false,
      fontSizeInput: 10,
    };
  },
  props: {
    column: Object,
    index: Number,
    childIndex: Number,
    columnType: String,
  },
  mounted() {
    if (
      this.column.propertiesArray[this.column.properties.selectedVariationIndex]
        .style.width != "auto"
    ) {
      this.autoWidthValue =
        this.column.propertiesArray[
          this.column.properties.selectedVariationIndex
        ].style.width.split("%")[0];
    }
    this.fontSizeInput =
      this.draggable_editor_data[this.index][this.columnType][
        this.childIndex
      ].propertiesArray[
        this.column.properties.selectedVariationIndex
      ].borderStyles.borderTopWidth.split("p")[0];
  },
  methods: {
    restrictContentToAccordion() {
      this.isRestrictContentToAccordion = !this.isRestrictContentToAccordion;
    },
    changeLineOrientation(
      index,
      childIndex,
      columnType,
      orientation,
      variationIndex
    ) {
      if (orientation === "Vertical") {
        this.draggable_editor_data[index][columnType][
          childIndex
        ].propertiesArray[variationIndex].style.height = "50px";
        this.draggable_editor_data[index][columnType][
          childIndex
        ].propertiesArray[variationIndex].style.width = "4px";
        this.draggable_editor_data[index][columnType][
          childIndex
        ].propertiesArray[variationIndex].style.maxHeight = "100%";
        this.draggable_editor_data[index][columnType][
          childIndex
        ].propertiesArray[variationIndex].style.maxWidth = "100%";
        this.draggable_editor_data[index][columnType][
          childIndex
        ].propertiesArray[variationIndex].style.margin = "0 5px";
      } else {
        this.draggable_editor_data[index][columnType][
          childIndex
        ].propertiesArray[variationIndex].style.height = "4px";
        this.draggable_editor_data[index][columnType][
          childIndex
        ].propertiesArray[variationIndex].style.width = "50px";
        this.draggable_editor_data[index][columnType][
          childIndex
        ].propertiesArray[variationIndex].style.maxHeight = "100%";
        this.draggable_editor_data[index][columnType][
          childIndex
        ].propertiesArray[variationIndex].style.maxWidth = "100%";
        this.draggable_editor_data[index][columnType][
          childIndex
        ].propertiesArray[variationIndex].style.margin = "5px 0";
      }
    },
    changeLineAlignment(
      index,
      childIndex,
      columnType,
      alignment,
      variationIndex
    ) {
      this.draggable_editor_data[index][columnType][childIndex].propertiesArray[
        variationIndex
      ].style.marginRight =
        alignment == "left" || alignment == "center" ? "auto" : "";
      this.draggable_editor_data[index][columnType][childIndex].propertiesArray[
        variationIndex
      ].style.marginLeft =
        alignment == "right" || alignment == "center" ? "auto" : "";
      this.draggable_editor_data[index][columnType][childIndex].propertiesArray[
        variationIndex
      ].style.margin = alignment == "center" ? "auto" : "";
    },
    optionsAccordion() {
      this.isOptionsAccordion = !this.isOptionsAccordion;
    },
    generalAccordion() {
      this.isGeneralAccordion = !this.isGeneralAccordion;
    },
    responsiveDesignAccordion() {
      this.isResponsiveDesignAccordion = !this.isResponsiveDesignAccordion;
    },
    widthSlider(e) {
      //rounding the value
      const value = Math.round(e.value);
      this.emitFunction("widthSlider", e, value, false);
      this.autoWidthValue = value;
    },
    emitFunction(type, e, isFirstValue, isSecondValue) {
      this.lineControllerEmit(
        type,
        e,
        this.index,
        this.childIndex,
        this.columnType,
        isFirstValue,
        isSecondValue,
        this.column.properties.selectedVariationIndex
      );
    },
    lineControllerEmit(
      type,
      e,
      index,
      childIndex,
      columnType,
      isFirstValue,
      isSecondValue,
      variationIndex
    ) {
      if (type == "RemoveLinks") {
        var isThere = false;
        this.draggable_editor_data[index][columnType][
          childIndex
        ].propertiesArray[variationIndex].mediaDataSeeMore.forEach(
          (mediaDataSeeMores) => {
            if (e.name == mediaDataSeeMores.name) {
              isThere = true;
            }
          }
        );
        if (!isThere) {
          this.draggable_editor_data[index][columnType][
            childIndex
          ].propertiesArray[variationIndex].mediaDataSeeMore.push(e);
        }
        this.draggable_editor_data[index][columnType][
          childIndex
        ].propertiesArray[variationIndex].mediaData.splice(isFirstValue, 1);
      }
      if (type == "addLinks") {
        var isThere = false;
        this.draggable_editor_data[index][columnType][
          childIndex
        ].propertiesArray[variationIndex].mediaDataSeeMore.forEach(
          (mediaDataSeeMores) => {
            if (e.name == mediaDataSeeMores.name) {
              isThere = true;
            }
          }
        );
        if (isThere) {
          this.draggable_editor_data[index][columnType][
            childIndex
          ].propertiesArray[variationIndex].mediaDataSeeMore.splice(
            isFirstValue,
            1
          );
          this.draggable_editor_data[index][columnType][
            childIndex
          ].propertiesArray[variationIndex].mediaData.push(e);
        }
      }
      if (type == "minusButtonFront") {
        var onlyPxInt =
          this.draggable_editor_data[index][columnType][
            childIndex
          ].propertiesArray[variationIndex].style.height.split("p");
        this.draggable_editor_data[index][columnType][
          childIndex
        ].propertiesArray[variationIndex].style.height = onlyPxInt[0];
        this.draggable_editor_data[index][columnType][
          childIndex
        ].propertiesArray[variationIndex].style.height -= 1;
        if (
          this.draggable_editor_data[index][columnType][childIndex]
            .propertiesArray[variationIndex].style.height < 0
        ) {
          this.draggable_editor_data[index][columnType][
            childIndex
          ].propertiesArray[variationIndex].style.height = "16";
        }
        this.draggable_editor_data[index][columnType][
          childIndex
        ].propertiesArray[variationIndex].style.height =
          this.draggable_editor_data[index][columnType][childIndex]
            .propertiesArray[variationIndex].style.height + "px";
      }
      if (type == "plusButtonFront") {
        var onlyPxInt =
          this.draggable_editor_data[index][columnType][
            childIndex
          ].propertiesArray[variationIndex].style.height.split("p");
        this.draggable_editor_data[index][columnType][
          childIndex
        ].propertiesArray[variationIndex].style.height = parseInt(onlyPxInt[0]);
        this.draggable_editor_data[index][columnType][
          childIndex
        ].propertiesArray[variationIndex].style.height =
          this.draggable_editor_data[index][columnType][childIndex]
            .propertiesArray[variationIndex].style.height + 1;
        this.draggable_editor_data[index][columnType][
          childIndex
        ].propertiesArray[variationIndex].style.height =
          this.draggable_editor_data[index][columnType][childIndex]
            .propertiesArray[variationIndex].style.height + "px";
      }
      if (type == "marginInput") {
        this.draggable_editor_data[index][columnType][
          childIndex
        ].propertiesArray[variationIndex].borderStyles.borderTopWidth =
          pxInputHandler(e.target.value);
        this.fontSizeInput =
          this.draggable_editor_data[index][columnType][
            childIndex
          ].propertiesArray[variationIndex].borderStyles.borderTopWidth.split(
            "p"
          )[0];
      }
      if (type == "widthSlider") {
        this.draggable_editor_data[index][columnType][
          childIndex
        ].propertiesArray[variationIndex].style.width = isFirstValue + "%";
      }
    },
    debounceInput(event) {
      let value =
        event.target.value > 100
          ? 100
          : event.target.value < 1
          ? 1
          : event.target.value;
      this.fontSizeInput = value;
      event.target.value = value;
      this.emitFunction("marginInput", event);
    },
  },
};
</script>
