<template>
  <div class="widget-editor">
    <KEditor
      :class="'builder-editor'"
      :tools="ktools"
      :default-content="data.content"
      @change="(e) => onChangeKeditor(e)"
      :paste-html="onPasteHtml"
      @execute="onExecute"
      @clearDefaultValue="clearDefaultValue"
    >
    </KEditor>
  </div>
</template>

<script>
import kendoTools from "../helpers/kendoTools.js";
import { onPasteHtmlIntoEditor } from "@/utils/helper";

import {
  Editor,
  EditorToolsSettings,
  InlineFormat,
  FontName,
  EditorUtils,
} from "@progress/kendo-vue-editor";

const {
  pasteCleanup,
  sanitize,
  sanitizeClassAttr,
  sanitizeStyleAttr,
  removeAttribute,
  replaceImageSourcesFromRtf,
} = EditorUtils;
const pasteSettings = {
  attributes: {
    class: sanitizeClassAttr,
    width: () => {},
    height: () => {},
    src: () => {},
    "*": removeAttribute,
  },
};

export default {
  name: "headerContent",
  components: {
    KEditor: Editor,
  },
  props: {
    data: Object,
  },
  inject: ["templateData"],
  data() {
    return {
      defaultValue: null,
      ktools: kendoTools,
    };
  },
  methods: {
    onChangeKeditor(e) {
      this.templateData.header.content = e.html;
    },
    onPasteHtml(e) {
      return onPasteHtmlIntoEditor(e);
    },
    onExecute(ev) {
      const doc = ev.state.doc;
      const range = ev.transaction.curSelection.ranges[0];
      const from = range.$from.pos;
      const to = range.$to.pos;

      doc.nodesBetween(from, to, (node, pos, _parent, _index) => {
        if (node.marks[0]) {
          const currValue = this.getSubstring(
            node.marks[0].attrs.style,
            ":",
            ";"
          );
          this.defaultValue = Number(currValue);
        } else {
          this.defaultValue = null;
        }
      });
    },
    getSubstring(str, char1, char2) {
      return str.substring(str.indexOf(char1) + 1, str.lastIndexOf(char2));
    },
    clearDefaultValue(val) {
      this.defaultValue = Number(val);
    },
  },
};
</script>

<style scoped></style>
