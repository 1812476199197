<template>
    <svg aria-hidden="true" focusable="false" data-prefix="fal" data-icon="font" class="svg-inline--fa fa-font fa-w-14 fa-3x" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
        <path fill="currentColor" d="M376 232H8c-4.42 0-8 3.58-8 8v32c0 4.42 3.58 8 8 8h368c4.42 0 8-3.58 8-8v-32c0-4.42-3.58-8-8-8z"></path>
    </svg>
</template>
<script>
    export default {
        name: "lineIcon"
    }
</script>
<style scoped></style>