<template>
  <div v-if="column?.properties?.selected">
    <div class="options-header">
      <span>content</span>
      <div class="buttons">
        <button
          v-if="
            [1, 2].includes(column?.propertiesArray.length) &&
            !isFromFlowAutomation
          "
          @click="addVariationHandler(index, childIndex, columnType)"
          class="border-0 bg-transparent ms-2 btn-icon-hover-green"
        >
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M15 3V2H1V3H15ZM15 5H9V4H15V5ZM9 9H15V7H9V9ZM15 12H9V11H15V12ZM9 14H15V13H9V14ZM5 11H3V9H1V7H3V5H5V7H7V9H5V11Z"
              fill="#24243A"
            />
          </svg>
        </button>
        <button class="close ms-2 btn-icon-hover-red" @click="clear_selection">
          <svg
            aria-hidden="true"
            focusable="false"
            data-prefix="fas"
            data-icon="times"
            class="svg-inline--fa fa-times fa-w-11"
            role="img"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 352 512"
          >
            <path
              fill="currentColor"
              d="M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z"
            ></path>
          </svg>
        </button>
      </div>
    </div>
    <div v-if="column.propertiesArray.length > 1" class="scroller-tab">
      <div class="scroller-menu">
        <button
          v-for="(property, propertyIndex) in column?.propertiesArray"
          :key="property"
          @click="
            toggleVariationIndex(index, childIndex, columnType, propertyIndex)
          "
          :class="[
            column?.properties?.selectedVariationIndex == propertyIndex
              ? 'active'
              : '',
          ]"
        >
          Alternative {{ propertyIndex + 1 }}
        </button>
      </div>
      <button
        @click="deleteVariationHandler(index, childIndex, columnType)"
        class="sroller-delete btn-icon-hover-red"
      >
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M13 3H10V2C10 1.45 9.55 1 9 1H6C5.45 1 5 1.45 5 2V3H2V5H3V14C3 14.55 3.45 15 4 15H11C11.55 15 12 14.55 12 14V5H13V3ZM6 3C6.00312 2.99688 6.00313 2.00311 6.00003 2H8.99687L9 2.00312V3H6.00313H6ZM4.00312 14H11V5H10V13H9V5H8V13H7V5H6V13H5V5H4V13.9969L4.00312 14Z"
            fill="#24243A"
          />
        </svg>
      </button>
    </div>
    <div
      v-for="(property, propertyIndex) in column?.propertiesArray"
      :key="property"
    >
      <div
        v-if="
          column.name == 'widget_button' &&
          column.properties.selectedVariationIndex == propertyIndex
        "
      >
        <buttonController
          :column="column"
          :index="index"
          :childIndex="childIndex"
          :columnType="columnType"
        />
      </div>

      <div
        v-if="
          column.name == 'widget_text' &&
          column.properties.selectedVariationIndex == propertyIndex
        "
      >
        <textController
          :column="column"
          :index="index"
          :childIndex="childIndex"
          :columnType="columnType"
        />
      </div>

      <div
        v-if="
          column.name == 'widget_html_editor' &&
          column.properties.selectedVariationIndex == propertyIndex
        "
      >
        <htmlEditorController
          :column="column"
          :index="index"
          :childIndex="childIndex"
          :columnType="columnType"
        />
      </div>

      <div
        v-if="
          column.name == 'widget_table_editor' &&
          column.properties.selectedVariationIndex == propertyIndex
        "
      >
        <tableController
          :column="column"
          :index="index"
          :childIndex="childIndex"
          :columnType="columnType"
        />
      </div>

      <div
        v-if="
          column.name == 'widget_image' &&
          column.properties.selectedVariationIndex == propertyIndex
        "
      >
        <imageController
          :column="column"
          :index="index"
          :childIndex="childIndex"
          :columnType="columnType"
        />
      </div>
      <div
        v-if="
          column.name == 'widget_line' &&
          column.properties.selectedVariationIndex == propertyIndex
        "
      >
        <lineController
          :column="column"
          :index="index"
          :childIndex="childIndex"
          :columnType="columnType"
        />
      </div>
      <div
        v-if="
          column.name == 'widget_spacer' &&
          column.properties.selectedVariationIndex == propertyIndex
        "
      >
        <spacerController
          :column="column"
          :index="index"
          :childIndex="childIndex"
          :columnType="columnType"
        />
      </div>
      <div
        v-if="
          column.name == 'widget_social' &&
          column.properties.selectedVariationIndex == propertyIndex
        "
      >
        <socialController
          :column="column"
          :index="index"
          :childIndex="childIndex"
          :columnType="columnType"
        />
      </div>
      <div
        v-if="
          column.name == 'widget_video' &&
          column.properties.selectedVariationIndex == propertyIndex
        "
      >
        <videoController
          :column="column"
          :index="index"
          :childIndex="childIndex"
          :columnType="columnType"
        />
      </div>

      <div
        v-if="
          column.name == 'widget_product_editor' &&
          column.properties.selectedVariationIndex == propertyIndex
        "
      >
        <productController
          :column="column"
          :index="index"
          :childIndex="childIndex"
          :columnType="columnType"
        />
      </div>

      <div
        v-if="
          column.name == 'widget_navigation' &&
          column.properties.selectedVariationIndex == propertyIndex
        "
      >
        <navigationController
          :column="column"
          :index="index"
          :childIndex="childIndex"
          :columnType="columnType"
        />
      </div>
    </div>
  </div>
</template>

<script>
//button associate components
import buttonController from "../widgets/button/buttonController.vue";
//text associate components
import textController from "../widgets/text/textController.vue";
//image associate components
import imageController from "../widgets/image/imageController.vue";
//Video associate components
import videoController from "../widgets/video/videoController";
//Social associate components
import socialController from "../widgets/social/socialController";
//line associate components
import lineController from "../widgets/line/lineController";
//space associate components
import spacerController from "../widgets/spacer/spacerController";
//product associate components
import productController from "../widgets/product/productController.vue";
//html associate components
import htmlEditorController from "../widgets/htmlEditor/htmlEditorController.vue";
import tableController from "../widgets/table/tableController.vue";
// navigation associate components
import navigationController from "../widgets/navigation/navigationController.vue";
import navigationViewRender from "../widgets/navigation/navigationViewRender.vue";
//general

export default {
  inject: ["draggable_editor_data"],
  name: "componentLoaderWithVariation",
  components: {
    buttonController,
    textController,
    imageController,
    videoController,
    socialController,
    lineController,
    spacerController,
    htmlEditorController,
    tableController,
    productController,
    navigationController,
    navigationViewRender,
  },
  props: {
    column: Object,
    index: Number,
    childIndex: Number,
    columnType: String,
    isFromFlowAutomation: Boolean,
  },
  data() {
    return {};
  },
  mounted() {},
  methods: {
    deleteVariationHandler(index, childIndex, columnType) {
      const variationIndex =
        this.draggable_editor_data[index][columnType][childIndex].properties
          .selectedVariationIndex;
      this.draggable_editor_data[index][columnType][
        childIndex
      ].propertiesArray.splice(variationIndex, 1);
      if (variationIndex == 0) {
        if (
          this.draggable_editor_data[index][columnType][childIndex]
            .propertiesArray.length > 2
        ) {
          this.draggable_editor_data[index][columnType][
            childIndex
          ].properties.selectedVariationIndex = variationIndex + 1;
        } else {
          this.draggable_editor_data[index][columnType][
            childIndex
          ].properties.selectedVariationIndex = 0;
        }
      } else {
        this.draggable_editor_data[index][columnType][
          childIndex
        ].properties.selectedVariationIndex = variationIndex - 1;
      }
    },
    toggleVariationIndex(index, childIndex, columnType, variationIndex) {
      this.draggable_editor_data[index][columnType][
        childIndex
      ].properties.selectedVariationIndex = variationIndex;
    },
    addVariationHandler(index, childIndex, columnType) {
      const variationsCount =
        this.draggable_editor_data[index][columnType][childIndex]
          .propertiesArray.length;
      if (variationsCount < 3) {
        const lastElemnt =
          this.draggable_editor_data[index][columnType][
            childIndex
          ].propertiesArray.slice(-1);
        this.draggable_editor_data[index][columnType][
          childIndex
        ].propertiesArray.push(JSON.parse(JSON.stringify(lastElemnt[0])));
        const lastElementIndex =
          this.draggable_editor_data[index][columnType][childIndex]
            .propertiesArray.length - 1;
        this.draggable_editor_data[index][columnType][
          childIndex
        ].properties.selectedVariationIndex = lastElementIndex;
      } else {
        return;
      }
    },
    clear_selection() {
      this.$emit("clear_selection");
    },
  },
};
</script>

<style scoped></style>
