<template src="./SendTestModalComponent.vue.html"></template>

<script>
import { Dialog, DialogActionsBar } from "@progress/kendo-vue-dialogs";
import { Button } from "@progress/kendo-vue-buttons";
import { Input } from "@progress/kendo-vue-inputs";
import modal from "@/components/common/AppModal/AppModal.vue";
import AppSnackBar from "@/components/common/AppSnackBar/AppSnackBar.vue";
import AppLoader from "@/components/common/AppLoader/AppLoader.vue";
import { getCampaignPreviousTestEmailsService } from "@/services/clubDetails/common.service";

export default {
  components: {
    "k-dialog": Dialog,
    "dialog-actions-bar": DialogActionsBar,
    kbutton: Button,
    "k-input": Input,
    modal,
    AppSnackBar,
    AppLoader,
  },
  props: {
    payload: {
      type: Object,
      required: false,
    },
    modalDetails: {
      //   type: Object,
      required: false,
    },
    onCancel: { type: Function },
    onConfirm: { type: Function },
  },
  data: function () {
    return {
      isLoading: false,
      clubId: this.$route.params.id || this.$route.query.club_id,
      testEmails: "",
      notifyMessage: "",
      notifyType: "",
      isNotify: false,
    };
  },
  computed: {},
  mounted() {
    this.getPreviousEmailData();
  },
  methods: {
    getPreviousEmailData() {
      this.isLoading = true;
      getCampaignPreviousTestEmailsService(this.clubId)
        .then((response) => {
          this.isLoading = false;
          this.testEmails = response.data?.data?.email || "";
        })
        .catch((error) => {
          this.isLoading = false;
          // this.showNotification("Error while fetching previous test emails", "error");
        });
    },
    testEmailsValidation() {
      let testEmails = this.testEmails.split(",");
      if (testEmails.length > 0) {
        for (const [index, email] of testEmails.entries()) {
          if (!this.validateEmail(email)) {
            if (email) {
              this.showNotification(
                ` "${email}" is not a valid email address.`,
                "warning"
              );
            } else {
              this.showNotification(
                "Please enter valid email address.",
                "warning"
              );
            }
            return false;
          }
        }
      } else {
        this.showNotification("Please enter valid email address", "warning");
        return false;
      }
      return true;
    },
    validateEmail(email) {
      var re = /\S+@\S+\.\S+/;
      return re.test(email);
    },
    sendEmailHandler() {
      ;
      if (this.testEmailsValidation()) {
        this.onConfirm(this.testEmails);
      }
    },
    showNotification(message, type) {
      this.notifyMessage = message;
      this.notifyType = type;
      this.isNotify = true;
      setTimeout(
        () => (
          (this.notifyMessage = ""),
          (this.notifyType = "success"),
          (this.isNotify = false)
        ),
        4000
      );
    },
  },
};
</script>

<style></style>
