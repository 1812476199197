<template>
    <svg width="33" height="32" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M8.93433 24.3012L10.9342 21.5897L4.49614 16.0017L10.9342 10.4138L8.93433 7.70229L0.934707 14.646C0.747268 14.8086 0.595952 15.0149 0.491965 15.2496C0.387977 15.4843 0.333984 15.7413 0.333984 16.0017C0.333984 16.2621 0.387977 16.5192 0.491965 16.7539C0.595952 16.9886 0.747268 17.1949 0.934707 17.3575L8.93433 24.3012ZM23.7336 7.70229L21.7337 10.4138L28.1718 16.0017L21.7337 21.5897L23.7336 24.3012L31.7333 17.3575C31.9207 17.1949 32.072 16.9886 32.176 16.7539C32.28 16.5192 32.334 16.2621 32.334 16.0017C32.334 15.7413 32.28 15.4843 32.176 15.2496C32.072 15.0149 31.9207 14.8086 31.7333 14.646L23.7336 7.70229ZM21.0954 0.75339L14.6957 32L11.571 31.2466L17.9707 0L21.0954 0.75339Z" fill="#2B354C" fill-opacity="0.65"/>
    </svg>
</template>
<script>
    export default {
        name: "htmlIcon"
    }
</script>
<style scoped></style>