<template>
  <div class="widget-accordion-wrap">
    <div class="options-header">
      <span>content</span>
      <div class="buttons">
        <button class="close ms-2 btn-icon-hover-red" @click="clear_selection">
          <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="times"
            class="svg-inline--fa fa-times fa-w-11" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 352 512">
            <path fill="currentColor"
              d="M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z">
            </path>
          </svg>
        </button>
      </div>
    </div>
    <!-- Widget Accordion item-->
    <div :class="
      isColumnPropertyAccordion
        ? 'widget-accordion-item show'
        : 'widget-accordion-item'
    ">
      <div @click="columnPropertyAccordion()" class="widget-accordion-head">
        <span>COLUMN PROPERTIES</span>
        <span class="icon">
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M4.06066 11.0607L8.00003 7.12128L11.9394 11.0607L13 10L8.00003 5L3 10L4.06066 11.0607Z"
              fill="#24243A" />
          </svg>
        </span>
      </div>
      <blockColumnControllers :row="row" :index="index" :contentType="'data_content_style'"
        :borderType="'data_content_border_styles'" />
    </div>
    <!-- Widget Accordion item-->
    <!-- <div :class="
      isContainerPropertyAccordion
        ? 'widget-accordion-item show'
        : 'widget-accordion-item'
    ">
      <div @click="rowContainerAccordion()" class="widget-accordion-head">
        <span>CONTAINER PROPERTIES</span>
        <span class="icon">
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M4.06066 11.0607L8.00003 7.12128L11.9394 11.0607L13 10L8.00003 5L3 10L4.06066 11.0607Z"
              fill="#24243A" />
          </svg>
        </span>
      </div>
      <div class="widget-accordion-body">
        <div class="widget-input-item">
          <div class="d-flex justify-content-between align-items-center">
            <label class="b-form-label mb-0">Container Color</label>
            <ColorPicker :gradient-settings="{ opacity: false, }"
              v-model="row.properties.container_properties.backgroundColor" />
          </div>
        </div>
      </div>
    </div> -->
    <div :class="
      isRowPropertyAccordion
        ? 'widget-accordion-item show'
        : 'widget-accordion-item'
    ">
      <div @click="rowPropertyAccordion()" class="widget-accordion-head">
        <span>ROW PROPERTIES</span>
        <span class="icon">
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M4.06066 11.0607L8.00003 7.12128L11.9394 11.0607L13 10L8.00003 5L3 10L4.06066 11.0607Z"
              fill="#24243A" />
          </svg>
        </span>
      </div>
      <BlockRowControllers :row="row" :index="index" />
    </div>
  </div>
</template>

<script>
import blockColumnControllers from "../blockColumnControllers/blockColumnControllers.vue";
import BlockRowControllers from "../blockRowControllers/blockRowControllers.vue";
import { ColorPicker } from "@progress/kendo-vue-inputs";
export default {
  name: "boxCol1Controller",
  components: {
    blockColumnControllers,
    BlockRowControllers,
    ColorPicker
  },
  data() {
    return {
      isColumnPropertyAccordion: true,
      isRowPropertyAccordion: true,
      isContainerPropertyAccordion: true,
    };
  },
  props: {
    row: Object,
    index: Number,
  },
  methods: {
    clear_selection() {
      this.$emit("clear_selection");
    },
    columnPropertyAccordion() {
      this.isColumnPropertyAccordion = !this.isColumnPropertyAccordion;
    },
    rowPropertyAccordion() {
      this.isRowPropertyAccordion = !this.isRowPropertyAccordion;
    },
    rowContainerAccordion() {
      this.isContainerPropertyAccordion = !this.isContainerPropertyAccordion;
    },
  },
};
</script>

<style scoped>

</style>
