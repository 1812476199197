<template>
  <div class="widget-accordion-wrap">
    <!-- Widget Accordion item-->
    <div
      :class="
        isHtmlAccordionVisible
          ? 'widget-accordion-item show'
          : 'widget-accordion-item'
      "
    >
      <div @click="toggleHtmlAccordion()" class="widget-accordion-head">
        <span>Table</span>
        <span class="icon">
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M4.06066 11.0607L8.00003 7.12128L11.9394 11.0607L13 10L8.00003 5L3 10L4.06066 11.0607Z"
              fill="#24243A"
            />
          </svg>
        </span>
      </div>
      <div class="widget-accordion-body">
        <div class="widget-input-item widget-editor input-link-custom-modal">
          <!-- do here -->
          <label class="b-form-label">Table data</label>
          <div class="common-inner-tab">
            <div class="tab-buttons">
              <button
                @click="onClickTabChange('tab1')"
                :class="{ active: activeTab === 'tab1' }"
              >
                Static
              </button>
              <button
                @click="onClickTabChange('tab2')"
                :class="{ active: activeTab === 'tab2' }"
              >
                Dynamic
              </button>
            </div>
            <div class="tab-content">
              <div v-if="activeTab === 'tab1'">
                <p class="font-14 mt-10 mb-24 pb-24 border-bottom-normal-1">
                  Content that is the same for all email recipients.
                </p>
                <div class="generate-box d-flex">
                  <div class="generate-single">
                    <p>Row</p>
                    <NumericTextBox
                      :min="1"
                      v-model="
                        column.propertiesArray[
                          this.column.properties.selectedVariationIndex
                        ].tableProperties.staticTable.rowNumber
                      "
                    />
                  </div>
                  <div class="generate-single">
                    <p>Column</p>
                    <NumericTextBox
                      :min="1"
                      :max="10"
                      v-model="
                        column.propertiesArray[
                          this.column.properties.selectedVariationIndex
                        ].tableProperties.staticTable.columnNumber
                      "
                    />
                  </div>
                  <div class="generate-single">
                    <p></p>
                    <kbutton
                      :disabled="
                        column.propertiesArray[
                          this.column.properties.selectedVariationIndex
                        ].tableProperties.staticTable.rowNumber == 0 ||
                        column.propertiesArray[
                          this.column.properties.selectedVariationIndex
                        ].tableProperties.staticTable.columnNumber == 0
                      "
                      v-if="
                        !column.propertiesArray[
                          this.column.properties.selectedVariationIndex
                        ].tableProperties.staticTable.isUpdate
                      "
                      @click="onClickStaticGenerate"
                      :theme-color="'primary'"
                      >Generate</kbutton
                    >
                    <kbutton
                      :disabled="
                        column.propertiesArray[
                          this.column.properties.selectedVariationIndex
                        ].tableProperties.staticTable.rowNumber == 0 ||
                        column.propertiesArray[
                          this.column.properties.selectedVariationIndex
                        ].tableProperties.staticTable.columnNumber == 0
                      "
                      v-if="
                        column.propertiesArray[
                          this.column.properties.selectedVariationIndex
                        ].tableProperties.staticTable.isUpdate
                      "
                      @click="onClickStaticGenerate"
                      :theme-color="'primary'"
                      >Update</kbutton
                    >
                  </div>
                </div>
              </div>
              <div v-if="activeTab === 'tab2'">
                <p class="font-14 mt-10 mb-24 pb-24 border-bottom-normal-1">
                  Fill in rows with event information.
                </p>
                <div class="generate-box d-flex">
                  <div class="generate-single">
                    <p>Row</p>
                    <NumericTextBox
                      :min="1"
                      :disabled="activeTab == 'tab2'"
                      v-model="
                        column.propertiesArray[
                          this.column.properties.selectedVariationIndex
                        ].tableProperties.dynamicTable.rowNumber
                      "
                    />
                  </div>
                  <div class="generate-single">
                    <p>Column</p>
                    <NumericTextBox
                      :min="1"
                      :max="10"
                      v-model="
                        column.propertiesArray[
                          this.column.properties.selectedVariationIndex
                        ].tableProperties.dynamicTable.columnNumber
                      "
                    />
                  </div>
                  <div class="generate-single">
                    <p></p>
                    <kbutton
                      :disabled="
                        column.propertiesArray[
                          this.column.properties.selectedVariationIndex
                        ].tableProperties.dynamicTable.rowNumber == 0 ||
                        column.propertiesArray[
                          this.column.properties.selectedVariationIndex
                        ].tableProperties.dynamicTable.columnNumber == 0
                      "
                      v-if="
                        !column.propertiesArray[
                          this.column.properties.selectedVariationIndex
                        ].tableProperties.dynamicTable.isUpdate
                      "
                      @click="onClickDynamicGenerate"
                      :theme-color="'primary'"
                      >Generate</kbutton
                    >
                    <kbutton
                      :disabled="
                        column.propertiesArray[
                          this.column.properties.selectedVariationIndex
                        ].tableProperties.dynamicTable.rowNumber == 0 ||
                        column.propertiesArray[
                          this.column.properties.selectedVariationIndex
                        ].tableProperties.dynamicTable.columnNumber == 0
                      "
                      v-if="
                        column.propertiesArray[
                          this.column.properties.selectedVariationIndex
                        ].tableProperties.dynamicTable.isUpdate
                      "
                      @click="onClickDynamicGenerate"
                      :theme-color="'primary'"
                      >Update</kbutton
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Widget Accordion item-->
    <div
      :class="
        isGeneralAccordion
          ? 'widget-accordion-item show'
          : 'widget-accordion-item'
      "
    >
      <div @click="toggleGeneralAccordion()" class="widget-accordion-head">
        <span>General</span>
        <span class="icon">
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M4.06066 11.0607L8.00003 7.12128L11.9394 11.0607L13 10L8.00003 5L3 10L4.06066 11.0607Z"
              fill="#24243A"
            />
          </svg>
        </span>
      </div>
      <generalAccordionComponent
        :column="column"
        :columnType="columnType"
        :index="index"
        :childIndex="childIndex"
      />
    </div>
    <div
      :class="
        isPreferanceAccordion
          ? 'widget-accordion-item show'
          : 'widget-accordion-item'
      "
    >
      <div @click="preferenceAccordion()" class="widget-accordion-head">
        <span>Preference</span>
        <span class="icon">
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M4.06066 11.0607L8.00003 7.12128L11.9394 11.0607L13 10L8.00003 5L3 10L4.06066 11.0607Z"
              fill="#24243A"
            />
          </svg>
        </span>
      </div>
      <tablePreferenceControl
        :column="column"
        :columnType="columnType"
        :index="index"
        :childIndex="childIndex"
      />
    </div>
    <div
      :class="
        isRestrictContentToAccordion
          ? 'widget-accordion-item show'
          : 'widget-accordion-item'
      "
    >
      <div @click="restrictContentToAccordion()" class="widget-accordion-head">
        <span>RESTRICT CONTENT TO</span>
        <span class="icon">
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M4.06066 11.0607L8.00003 7.12128L11.9394 11.0607L13 10L8.00003 5L3 10L4.06066 11.0607Z"
              fill="#24243A"
            />
          </svg>
        </span>
      </div>
      <RestrictContentToComponent
        :column="column"
        :columnType="columnType"
        :index="index"
        :childIndex="childIndex"
      />
    </div>
  </div>

  <manageTableModal
    :staticRowData="staticRowData"
    :headersData="tableHeaders"
    :datas="isDatas"
    :column="column"
    :index="index"
    :childIndex="childIndex"
    :columnType="columnType"
    :onCancel="onCancel"
    :onConfirm="onConfirmStatusChangeModal"
    v-if="isModalTrue"
  >
  </manageTableModal>
</template>

<script>
import { Input, Switch } from "@progress/kendo-vue-inputs";
import { Button } from "@progress/kendo-vue-buttons";
import generalAccordionComponent from "../../common-components/generalAccordionComponent.vue";
import tablePreferenceControl from "./tablePreferenceControl.vue";
import RestrictContentToComponent from "../../common-components/restrictContentToComponent.vue";
import manageTableModal from "./manageTableModal.vue";
import { NumericTextBox } from "@progress/kendo-vue-inputs";

import { PrismEditor } from "vue-prism-editor";
import "vue-prism-editor/dist/prismeditor.min.css";
import { highlight, languages } from "prismjs/components/prism-core";
import "prismjs/components/prism-clike";
import "prismjs/components/prism-javascript";
import "prismjs/themes/prism-tomorrow.css";

export default {
  name: "tableController",
  inject: ["draggable_editor_data"],
  components: {
    KInput: Input,
    kbutton: Button,
    KSwitch: Switch,
    generalAccordionComponent,
    RestrictContentToComponent,
    PrismEditor,
    manageTableModal,
    tablePreferenceControl,
    NumericTextBox,
  },
  props: {
    column: Object,
    index: Number,
    childIndex: Number,
    columnType: String,
  },
  data() {
    return {
      editorOptions: {
        behavioursEnabled: true,
        wrapBehavioursEnabled: true,
        highlightActiveLine: false,
        highlightSelectedWord: false,
        useSoftTabs: true,
        cursorStyle: "smooth",
      },
      isHtmlAccordionVisible: true,
      isGeneralAccordion: true,
      isRestrictContentToAccordion: true,
      isPreferanceAccordion: true,
      activeTab: "tab1",
      isModalTrue: false,
      isDatas: "",
      tableHeaders: "",
      staticRowData: "",
    };
  },
  mounted() {
    this.activeTab = this.draggable_editor_data[this.index][this.columnType][
      this.childIndex
    ].propertiesArray[this.column.properties.selectedVariationIndex]
      .tableProperties.isDynamic
      ? "tab2"
      : "tab1";
  },
  methods: {
    highlighter(code) {
      return highlight(code, languages.js);
    },
    toggleHtmlAccordion() {
      this.isHtmlAccordionVisible = !this.isHtmlAccordionVisible;
    },
    toggleGeneralAccordion() {
      this.isGeneralAccordion = !this.isGeneralAccordion;
    },
    restrictContentToAccordion() {
      this.isRestrictContentToAccordion = !this.isRestrictContentToAccordion;
    },
    preferenceAccordion() {
      this.isPreferanceAccordion = !this.isPreferanceAccordion;
    },
    onClickTabChange(tabName) {
      this.activeTab = tabName;
      // this.draggable_editor_data[this.index][this.columnType][this.childIndex]
      //   .propertiesArray[this.column.properties.selectedVariationIndex].tableProperties.isUpdate = false;
      if (tabName === "tab1") {
        this.draggable_editor_data[this.index][this.columnType][
          this.childIndex
        ].propertiesArray[
          this.column.properties.selectedVariationIndex
        ].tableProperties.isDynamic = false;
      } else {
        this.draggable_editor_data[this.index][this.columnType][
          this.childIndex
        ].propertiesArray[
          this.column.properties.selectedVariationIndex
        ].tableProperties.isDynamic = true;
      }
    },

    generateTableArray(rows, columns, isHeader, isStaticRow = false) {
      const dataArray = [];

      for (let i = 0; i < rows; i++) {
        const row = [];
        for (let j = 0; j < columns; j++) {
          const cell = {
            id: isHeader
              ? `h-${i}-${j}`
              : isStaticRow
              ? `s-${i}-${j}`
              : `b-${i}-${j}`,
            type: "Text",
            content: `Cell ${i + 1}-${j + 1}`,
            properties: { width: "", alignment: "", alterText: "" },
            isHeader,
            isStaticRow,
          };
          row.push(cell);
        }

        dataArray.push(row);
      }
      return dataArray;
    },

    onClickStaticGenerate() {
      const tableData =
        this.draggable_editor_data[this.index][this.columnType][this.childIndex]
          .propertiesArray[this.column.properties.selectedVariationIndex]
          .tableProperties.staticTable;
      tableData.isUpdate = true;
      const rowNo = tableData.rowNumber;
      const columnNo = tableData.columnNumber;
      const rowCount = tableData.tableData.length;
      const columnCount = tableData.tableData[0].length;

      if (rowCount !== rowNo || columnCount !== columnNo) {
        const headersArray = this.generateTableArray(1, columnNo, true);
        const dynamicArray = this.generateTableArray(rowNo, columnNo, false);

        for (let i = 0; i < Math.min(rowCount, rowNo); i++) {
          for (let j = 0; j < Math.min(columnCount, columnNo); j++) {
            dynamicArray[i][j] = tableData.tableData[i][j];
          }
        }

        for (let j = 0; j < Math.min(columnCount, columnNo); j++) {
          headersArray[0][j].content = tableData.headers[0][j].content;
        }

        tableData.tableData = dynamicArray;
        tableData.headers = headersArray;
      }

      this.isDatas = JSON.parse(JSON.stringify(tableData.tableData));
      this.tableHeaders = JSON.parse(JSON.stringify(tableData.headers));
      this.isModalTrue = true;
    },

    onClickDynamicGenerate() {
      const tableData =
        this.draggable_editor_data[this.index][this.columnType][this.childIndex]
          .propertiesArray[this.column.properties.selectedVariationIndex]
          .tableProperties.dynamicTable;
      tableData.isUpdate = true;
      const rowNo = tableData.rowNumber;
      const columnNo = tableData.columnNumber;
      const rowCount = tableData.tableData.length;
      const columnCount = tableData.tableData[0].length;

      if (rowCount !== rowNo || columnCount !== columnNo) {
        const headersArray = this.generateTableArray(1, columnNo, true);
        const dynamicArray = this.generateTableArray(rowNo, columnNo, false);
        const staticRowArray = this.generateTableArray(
          1,
          columnNo,
          false,
          true
        );

        for (let i = 0; i < Math.min(rowCount, rowNo); i++) {
          for (let j = 0; j < Math.min(columnCount, columnNo); j++) {
            dynamicArray[i][j] = tableData.tableData[i][j];
          }
        }

        for (let j = 0; j < Math.min(columnCount, columnNo); j++) {
          headersArray[0][j].content = tableData.headers[0][j].content;
        }

        for (let j = 0; j < Math.min(columnCount, columnNo); j++) {
          staticRowArray[0][j].content = tableData.staticRowData[0][j].content;
        }

        tableData.tableData = dynamicArray;
        tableData.headers = headersArray;
        tableData.staticRowData = staticRowArray;
      }

      this.isDatas = JSON.parse(JSON.stringify(tableData.tableData));
      this.tableHeaders = JSON.parse(JSON.stringify(tableData.headers));
      this.staticRowData = JSON.parse(JSON.stringify(tableData.staticRowData));
      this.isModalTrue = true;
    },
    generateUniqueId() {
      return Math.random().toString(36).substr(2, 9);
    },
    onConfirmStatusChangeModal(e) {
      if (
        this.draggable_editor_data[this.index][this.columnType][this.childIndex]
          .propertiesArray[this.column.properties.selectedVariationIndex]
          .tableProperties.isDynamic
      ) {
        this.draggable_editor_data[this.index][this.columnType][
          this.childIndex
        ].propertiesArray[
          this.column.properties.selectedVariationIndex
        ].tableProperties.dynamicTable.tableData = e.data;

        this.draggable_editor_data[this.index][this.columnType][
          this.childIndex
        ].propertiesArray[
          this.column.properties.selectedVariationIndex
        ].tableProperties.dynamicTable.rowNumber = e.rows;

        this.draggable_editor_data[this.index][this.columnType][
          this.childIndex
        ].propertiesArray[
          this.column.properties.selectedVariationIndex
        ].tableProperties.dynamicTable.columnNumber = e.columns;
        this.draggable_editor_data[this.index][this.columnType][
          this.childIndex
        ].propertiesArray[
          this.column.properties.selectedVariationIndex
        ].tableProperties.dynamicTable.headers = e.headers;
        this.draggable_editor_data[this.index][this.columnType][
          this.childIndex
        ].propertiesArray[
          this.column.properties.selectedVariationIndex
        ].tableProperties.dynamicTable.staticRowData = e.staticRow;
        // this.generateHeaders(e.columns);
      } else {
        this.draggable_editor_data[this.index][this.columnType][
          this.childIndex
        ].propertiesArray[
          this.column.properties.selectedVariationIndex
        ].tableProperties.staticTable.tableData = e.data;

        this.draggable_editor_data[this.index][this.columnType][
          this.childIndex
        ].propertiesArray[
          this.column.properties.selectedVariationIndex
        ].tableProperties.staticTable.rowNumber = e.rows;

        this.draggable_editor_data[this.index][this.columnType][
          this.childIndex
        ].propertiesArray[
          this.column.properties.selectedVariationIndex
        ].tableProperties.staticTable.columnNumber = e.columns;
        this.draggable_editor_data[this.index][this.columnType][
          this.childIndex
        ].propertiesArray[
          this.column.properties.selectedVariationIndex
        ].tableProperties.staticTable.headers = e.headers;
        // this.generateHeaders(e.columns);
      }

      this.isModalTrue = false;
    },
    onCancel() {
      this.isModalTrue = false;
    },
  },
};
</script>

<style scoped>
.my-editor {
  background: #2d2d2d;
  color: #ccc;
  font-family: Fira code, Fira Mono, Consolas, Menlo, Courier, monospace;
  font-size: 14px;
  line-height: 1.5;
  padding: 5px;
  min-height: 298px;
  max-height: 350px;
  overflow-y: auto;
}

.prism-editor__textarea:focus {
  outline: none;
}
</style>
