<template>
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M0 16C0 7.1625 7.1625 0 16 0C24.8375 0 32 7.1625 32 16C32 24.8375 24.8375 32 16 32C7.1625 32 0 24.8375 0 16ZM24 16L12 8V24L24 16Z" fill="#2B354C" fill-opacity="0.65"/>
    </svg>
</template>
<script>
    export default {
        name: "videoIcon"
    }
</script>
<style scoped></style>