<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M2 3H14V2H2V3ZM4 5H12V4H4V5ZM14 7H2V6H14V7ZM4 9H12V8H4V9ZM14 11H2V10H14V11ZM4 13H12V12H4V13Z"
      fill="#24243A"
    />
  </svg>
</template>
    <script>
export default {
  name: "alignmentCenter",
};
</script>
    <style scoped></style>