<template>
  <div class="widget-accordion-body">
    <div v-if="isBorderTypeVisible || isBorderColorVisible || isBorderWidthVisible" class="widget-input-item">
      <div class="">
        <div v-if="
          isBorderTypeVisible &&
          column.propertiesArray[column.properties.selectedVariationIndex]
            .borderStyles
        " class="widget-input-item">
          <div class="d-flex align-items-center justify-content-between">
            <label class="b-form-label">Border <small>(PX)</small></label>
          </div>
          <select class="b-form-select" v-model="
            column.propertiesArray[column.properties.selectedVariationIndex]
              .borderStyles.borderStyle
          ">
            <option v-for="data in column.propertiesArray[
              column.properties.selectedVariationIndex
            ].borderStyleOptions" :key="data.value" :value="data.value">
              {{ data.label }}
            </option>
          </select>
        </div>

        <div v-if="
          isBorderColorVisible &&
          column.propertiesArray[column.properties.selectedVariationIndex]
            .borderStyles
        " class="widget-input-item">
          <div class="d-flex justify-content-between align-items-center">
            <label class="b-form-label mb-0">Border Color</label>
            <ColorPicker :show-clear-button="false" :gradient-settings="{ opacity: false, }" v-model="
              column.propertiesArray[column.properties.selectedVariationIndex]
                .borderStyles.borderColor
            " />
          </div>
        </div>

        <div v-if="
          isBorderWidthVisible &&
          column.propertiesArray[column.properties.selectedVariationIndex]
            .borderStyles
        ">
          <borderWidthComponent :column="column" :columnType="columnType" :index="index" :childIndex="childIndex">
          </borderWidthComponent>
        </div>
      </div>
    </div>

    <div v-if="isPaddingVisible" class="widget-input-item">
      <div class="d-flex align-items-center justify-content-between">
        <label class="b-form-label">Container Padding <small>(PX)</small></label>
      </div>
      <div class="">
        <paddingComponent :column="column" :columnType="columnType" :index="index" :childIndex="childIndex" />
      </div>
    </div>
  </div>
</template>

<script>
import paddingComponent from "../common-components/paddingComponent.vue";
import { ColorPicker } from "@progress/kendo-vue-inputs";
import borderWidthComponent from "../common-components/borderWidthComponent.vue";

export default {
  inject: ["draggable_editor_data"],
  name: "generalAccordionComponent",
  components: {
    borderWidthComponent,
    paddingComponent,
    ColorPicker,
  },
  props: {
    column: Object,
    index: Number,
    childIndex: Number,
    columnType: String,
    except: Array,
  },
  data() {
    return {
      isBackgroundColorVisible: true,
      isBorderTypeVisible: true,
      isBorderColorVisible: true,
      isBorderWidthVisible: true,
      isPaddingVisible: true,
    };
  },
  mounted() {
    this.isBackgroundColorVisible = this.except?.includes("backgroundColor")
      ? false
      : true;
    this.isBorderTypeVisible = this.except?.includes("borderType")
      ? false
      : true;
    this.isBorderColorVisible = this.except?.includes("borderColor")
      ? false
      : true;
    this.isBorderWidthVisible = this.except?.includes("borderWidth")
      ? false
      : true;
    this.isPaddingVisible = this.except?.includes("padding") ? false : true;
  },
  methods: {},
};
</script>

<style scoped></style>
