<template>
    <div class="container-padding">
        <label class="b-form-label">Corners Radius <small>(PX)</small></label>
        <div :class="isLock ? 'lock inputs-button' : 'inputs-button'">
            <div class="inputs" v-if="!isLock">
                <input type="text" class="b-form-field" placeholder="5px" @focusout="cornerTopWidthHandler($event)" v-model="column.propertiesArray[column.properties.selectedVariationIndex][
                        this.stateKey
                    ].borderTopLeftRadius
                    " />

                <input type="text" class="b-form-field" placeholder="5px" @focusout="cornerRightWidthHandler($event)"
                    v-model="column.propertiesArray[column.properties.selectedVariationIndex][
                            this.stateKey
                        ].borderTopRightRadius
                        " />
                <input type="text" class="b-form-field" placeholder="5px" @focusout="cornerBottomWidthHandler($event)"
                    v-model="column.propertiesArray[column.properties.selectedVariationIndex][
                            this.stateKey
                        ].borderBottomLeftRadius
                        " />
                <input type="text" class="b-form-field" placeholder="5px" @focusout="cornerLeftWidthHandler($event)"
                    v-model="column.propertiesArray[column.properties.selectedVariationIndex][
                            this.stateKey
                        ].borderBottomRightRadius
                        " />
            </div>

            <div class="inputs" v-if="isLock">
                <input @focusout="changeFunc($event)" type="text" class="b-form-field" placeholder="5px"
                    v-model="isLockData" />
            </div>

            <button @click="onClickLockBtn($event)" title="lock" :class="isLock ? 'lock' : ''">
                <span v-if="!isLock">
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd"
                            d="M7 7H13C13.55 7 14 7.45 14 8V14C14 14.55 13.55 15 13 15H5C4.45 15 4 14.55 4 14V11V8C4 7.45 4.45 7 5 7H6V4C6 2.89688 5.10313 2 4 2C2.89688 2 2 2.89688 2 4V7V8H1V4C1 2.34375 2.34375 1 4 1C5.65625 1 7 2.34375 7 4V7ZM8 11C8 11.5531 8.44688 12 9 12C9.55313 12 10 11.5531 10 11C10 10.4469 9.55313 10 9 10C8.44688 10 8 10.4469 8 11Z"
                            fill="#404A61" />
                    </svg>
                </span>
                <span v-if="isLock">
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd"
                            d="M12 7H11V4C11 2.34375 9.65625 1 8 1C6.34375 1 5 2.34375 5 4V7H4C3.45 7 3 7.45 3 8V14C3 14.55 3.45 15 4 15H12C12.55 15 13 14.55 13 14V8C13 7.45 12.55 7 12 7ZM6 4C6 2.89688 6.89687 2 8 2C9.10313 2 10 2.89688 10 4V7H6V4ZM7 11C7 11.5531 7.44688 12 8 12C8.55312 12 9 11.5531 9 11C9 10.4469 8.55312 10 8 10C7.44688 10 7 10.4469 7 11Z"
                            fill="#404A61" />
                    </svg>
                </span>
            </button>
        </div>
        <div class="inputs-buttons-name d-flex" v-if="!isLock">
            <span>Top Left</span>
            <span>Top Right</span>
            <span>Bottom Left</span>
            <span>Bottom Right</span>
        </div>
    </div>
</template>
  
<script>
// import { Input, Switch } from "@progress/kendo-vue-inputs";
import { pxInputHandler } from "../helpers/utility";

export default {
    inject: ["draggable_editor_data"],
    name: "borderWidthComponent",
    components: {
        // KInput: Input,
    },
    props: {
        column: Object,
        index: Number,
        childIndex: Number,
        columnType: String,
        stateKey: {
            type: String,
            default: "borderStyles",
        },
    },
    data() {
        return {
            isLock: false,
            isLockData: "",
        };
    },
    mounted() {
        this.isLock =
            this.draggable_editor_data[this.index][this.columnType][
                this.childIndex
            ].propertiesArray[
                this.column.properties.selectedVariationIndex
            ]?.isWidthLock;
        if (this.isLock)
            this.isLockData =
                this.draggable_editor_data[this.index][this.columnType][
                    this.childIndex
                ].propertiesArray[this.column.properties.selectedVariationIndex][
                    this.stateKey
                ].borderTopLeftRadius;
    },
    methods: {
        onClickLockBtn(e) {
            this.isLock = !this.isLock;
            this.onclickLockHandler(
                this.index,
                this.columnType,
                this.childIndex,
                this.column.properties.selectedVariationIndex,
                e
            );
        },

        changeFunc(e) {
            this.lockHandler(
                this.index,
                this.columnType,
                this.childIndex,
                this.column.properties.selectedVariationIndex,
                e
            );
        },
        cornerTopWidthHandler(e) {
            this.draggable_editor_data[this.index][this.columnType][
                this.childIndex
            ].propertiesArray[this.column.properties.selectedVariationIndex][
                this.stateKey
            ].borderTopLeftRadius = pxInputHandler(e.target.value);
        },
        cornerRightWidthHandler(e) {
            this.draggable_editor_data[this.index][this.columnType][
                this.childIndex
            ].propertiesArray[this.column.properties.selectedVariationIndex][
                this.stateKey
            ].borderTopRightRadius = pxInputHandler(e.target.value);
        },
        cornerBottomWidthHandler(e) {
            this.draggable_editor_data[this.index][this.columnType][
                this.childIndex
            ].propertiesArray[this.column.properties.selectedVariationIndex][
                this.stateKey
            ].borderBottomLeftRadius = pxInputHandler(e.target.value);
        },
        cornerLeftWidthHandler(e) {
            this.draggable_editor_data[this.index][this.columnType][
                this.childIndex
            ].propertiesArray[this.column.properties.selectedVariationIndex][
                this.stateKey
            ].borderBottomRightRadius = pxInputHandler(e.target.value);
        },

        emitFunction(type, e, isFirstValue, isSecondValue) {
            this.$emit(
                "borderWidthComponentBorderWidthComponent",
                type,
                e,
                isFirstValue
            );
        },

        lockHandler(index, columnType, childIndex, variationIndex, e) {
            this.isLockData = pxInputHandler(e.target.value);
            this.draggable_editor_data[index][columnType][childIndex].propertiesArray[
                variationIndex
            ][this.stateKey].borderTopLeftRadius = pxInputHandler(e.target.value);
            this.draggable_editor_data[index][columnType][childIndex].propertiesArray[
                variationIndex
            ][this.stateKey].borderTopRightRadius = pxInputHandler(e.target.value);
            this.draggable_editor_data[index][columnType][childIndex].propertiesArray[
                variationIndex
            ][this.stateKey].borderBottomLeftRadius = pxInputHandler(e.target.value);
            this.draggable_editor_data[index][columnType][childIndex].propertiesArray[
                variationIndex
            ][this.stateKey].borderBottomRightRadius = pxInputHandler(e.target.value);
        },
        onclickLockHandler(index, columnType, childIndex, variationIndex, e) {
            this.draggable_editor_data[index][columnType][childIndex].propertiesArray[
                variationIndex
            ].isWidthLock = this.isLock;
            if (
                this.draggable_editor_data[index][columnType][childIndex]
                    .propertiesArray[variationIndex][this.stateKey].borderTopLeftRadius
            ) {
                this.isLockData =
                    this.draggable_editor_data[index][columnType][
                        childIndex
                    ].propertiesArray[variationIndex][this.stateKey].borderTopLeftRadius;
                this.draggable_editor_data[index][columnType][
                    childIndex
                ].propertiesArray[variationIndex][this.stateKey].borderTopRightRadius =
                    this.draggable_editor_data[index][columnType][
                        childIndex
                    ].propertiesArray[variationIndex][this.stateKey].borderTopLeftRadius;
                this.draggable_editor_data[index][columnType][
                    childIndex
                ].propertiesArray[variationIndex][this.stateKey].borderBottomLeftRadius =
                    this.draggable_editor_data[index][columnType][
                        childIndex
                    ].propertiesArray[variationIndex][this.stateKey].borderTopLeftRadius;
                this.draggable_editor_data[index][columnType][
                    childIndex
                ].propertiesArray[variationIndex][this.stateKey].borderBottomRightRadius =
                    this.draggable_editor_data[index][columnType][
                        childIndex
                    ].propertiesArray[variationIndex][this.stateKey].borderTopLeftRadius;
            } else {
                this.draggable_editor_data[index][columnType][
                    childIndex
                ].propertiesArray[variationIndex][this.stateKey].borderTopLeftRadius =
                    "10px";
                this.draggable_editor_data[index][columnType][
                    childIndex
                ].propertiesArray[variationIndex][this.stateKey].borderTopRightRadius =
                    "10px";
                this.draggable_editor_data[index][columnType][
                    childIndex
                ].propertiesArray[variationIndex][this.stateKey].borderBottomLeftRadius =
                    "10px";
                this.draggable_editor_data[index][columnType][
                    childIndex
                ].propertiesArray[variationIndex][this.stateKey].borderBottomRightRadius =
                    "10px";
            }
        },
    },
};
</script>
  
<style scoped></style>
  