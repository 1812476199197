const blocks = [
  {
    id: 1,
    name: "box-column-1",
    menu_content: '<span class="border"></span>',
    data_content: [],
    menu_class: "block-box grid-12",
    properties: {
      type: "box-column-1",
      selected: false,
      borderStyleOptions: [
        {
          label: "Solid",
          value: "solid",
        },
        {
          label: "Dashed",
          value: "dashed",
        },
        {
          label: "Dotted",
          value: "dotted",
        },
      ],
      data_content_width_style: {
        width: "100%",
      },
      data_content_style: {
        width: "100%",
        backgroundColor: "transparent",
        isPaddingLock: false,
        paddingTop: "0px",
        paddingRight: "0px",
        paddingBottom: "0px",
        paddingLeft: "0px",
      },
      data_content_border_styles: {
        borderStyle: "solid",
        borderColor: "#ffffff",
        borderTopWidth: "0px",
        borderRightWidth: "0px",
        borderBottomWidth: "0px",
        borderLeftWidth: "0px",
        isWidthLock: false,
      },
      color_properties: {
        backgroundColor: "transparent",
      },
      container_properties: {
        backgroundColor: "transparent",
      },
      style: {
        src: "",
        fileSize: 0,
        fileName: "",
        isPaddingLock: false,
        paddingTop: "0px",
        paddingRight: "0px",
        paddingBottom: "0px",
        paddingLeft: "0px",
      },
    },
  },
  {
    id: 2,
    name: "box-column-2",
    menu_content: '<span class="border"></span><span class="border"></span>',
    data_content_left: [],
    data_content_right: [],
    menu_class: "block-box grid-1-6",
    properties: {
      type: "box-column-2",
      selected: false,
      borderStyleOptions: [
        {
          label: "Solid",
          value: "solid",
        },
        {
          label: "Dashed",
          value: "dashed",
        },
        {
          label: "Dotted",
          value: "dotted",
        },
      ],
      data_content_left_width_style: {
        width: "50%",
      },
      data_content_left_style: {
        backgroundColor: "transparent",
        paddingTop: "0px",
        paddingRight: "0px",
        paddingBottom: "0px",
        paddingLeft: "0px",
        isPaddingLock: false,
      },
      data_content_left_border_styles: {
        borderStyle: "solid",
        borderColor: "#ffffff",
        borderTopWidth: "0px",
        borderRightWidth: "0px",
        borderBottomWidth: "0px",
        borderLeftWidth: "0px",
        isWidthLock: false,
      },
      data_content_right_width_style: {
        width: "50%",
      },
      data_content_right_style: {
        backgroundColor: "transparent",
        paddingTop: "0px",
        paddingRight: "0px",
        paddingBottom: "0px",
        paddingLeft: "0px",
        isPaddingLock: false,
      },
      data_content_right_border_styles: {
        borderStyle: "solid",
        borderColor: "#ffffff",
        borderTopWidth: "0px",
        borderRightWidth: "0px",
        borderBottomWidth: "0px",
        borderLeftWidth: "0px",
        isWidthLock: false,
      },
      color_properties: {
        backgroundColor: "transparent",
      },
      container_properties: {
        backgroundColor: "transparent",
      },
      style: {
        src: "",
        fileSize: 0,
        fileName: "",
        isPaddingLock: false,
        paddingTop: "0px",
        paddingRight: "0px",
        paddingBottom: "0px",
        paddingLeft: "0px",
      },
    },
  },
  {
    id: 4,
    name: "box-column-2",
    menu_content: '<span class="border"></span><span class="border"></span>',
    data_content_left: [],
    data_content_right: [],
    menu_class: "block-box grid-3-7",
    properties: {
      type: "box-column-2",
      selected: false,
      borderStyleOptions: [
        {
          label: "Solid",
          value: "solid",
        },
        {
          label: "Dashed",
          value: "dashed",
        },
        {
          label: "Dotted",
          value: "dotted",
        },
      ],
      data_content_left_width_style: {
        width: "30%",
      },
      data_content_left_style: {
        backgroundColor: "transparent",
        paddingTop: "0px",
        paddingRight: "0px",
        paddingBottom: "0px",
        paddingLeft: "0px",
        isPaddingLock: false,
      },
      data_content_left_border_styles: {
        borderStyle: "solid",
        borderColor: "#ffffff",
        borderTopWidth: "0px",
        borderRightWidth: "0px",
        borderBottomWidth: "0px",
        borderLeftWidth: "0px",
        isWidthLock: false,
      },
      data_content_right_width_style: {
        width: "70%",
      },
      data_content_right_style: {
        backgroundColor: "transparent",
        paddingTop: "0px",
        paddingRight: "0px",
        paddingBottom: "0px",
        paddingLeft: "0px",
        isPaddingLock: false,
      },
      data_content_right_border_styles: {
        borderStyle: "solid",
        borderColor: "#ffffff",
        borderTopWidth: "0px",
        borderRightWidth: "0px",
        borderBottomWidth: "0px",
        borderLeftWidth: "0px",
        isWidthLock: false,
      },
      color_properties: {
        backgroundColor: "transparent",
      },
      container_properties: {
        backgroundColor: "transparent",
      },
      style: {
        src: "",
        fileSize: 0,
        fileName: "",
        isPaddingLock: false,
        paddingTop: "0px",
        paddingRight: "0px",
        paddingBottom: "0px",
        paddingLeft: "0px",
      },
    },
  },
  {
    id: 5,
    name: "box-column-2",
    menu_content: '<span class="border"></span><span class="border"></span>',
    data_content_left: [],
    data_content_right: [],
    menu_class: "block-box grid-7-3",
    properties: {
      type: "box-column-2",
      selected: false,
      borderStyleOptions: [
        {
          label: "Solid",
          value: "solid",
        },
        {
          label: "Dashed",
          value: "dashed",
        },
        {
          label: "Dotted",
          value: "dotted",
        },
      ],
      data_content_left_width_style: {
        width: "70%",
      },
      data_content_left_style: {
        backgroundColor: "transparent",
        paddingTop: "0px",
        paddingRight: "0px",
        paddingBottom: "0px",
        paddingLeft: "0px",
        isPaddingLock: false,
      },
      data_content_left_border_styles: {
        borderStyle: "solid",
        borderColor: "#ffffff",
        borderTopWidth: "0px",
        borderRightWidth: "0px",
        borderBottomWidth: "0px",
        borderLeftWidth: "0px",
        isWidthLock: false,
      },
      data_content_right_width_style: {
        width: "30%",
      },
      data_content_right_style: {
        backgroundColor: "transparent",
        paddingTop: "0px",
        paddingRight: "0px",
        paddingBottom: "0px",
        paddingLeft: "0px",
        isPaddingLock: false,
      },
      data_content_right_border_styles: {
        borderStyle: "solid",
        borderColor: "#ffffff",
        borderTopWidth: "0px",
        borderRightWidth: "0px",
        borderBottomWidth: "0px",
        borderLeftWidth: "0px",
        isWidthLock: false,
      },
      color_properties: {
        backgroundColor: "transparent",
      },
      container_properties: {
        backgroundColor: "transparent",
      },
      style: {
        src: "",
        fileSize: 0,
        fileName: "",
        isPaddingLock: false,
        paddingTop: "0px",
        paddingRight: "0px",
        paddingBottom: "0px",
        paddingLeft: "0px",
      },
    },
  },
  {
    id: 3,
    name: "box-column-3",
    menu_content:
      '<span class="border"></span><span class="border"></span><span class="border"></span>',
    data_content_left: [],
    data_content_center: [],
    data_content_right: [],
    menu_class: "block-box grid-1-3",
    properties: {
      type: "box-column-3",
      selected: false,
      borderStyleOptions: [
        {
          label: "Solid",
          value: "solid",
        },
        {
          label: "Dashed",
          value: "dashed",
        },
        {
          label: "Dotted",
          value: "dotted",
        },
      ],
      data_content_left_width_style: {
        width: "33.33%",
      },
      data_content_left_style: {
        backgroundColor: "transparent",
        isPaddingLock: false,
        paddingTop: "0px",
        paddingRight: "0px",
        paddingBottom: "0px",
        paddingLeft: "0px",
      },
      data_content_left_border_styles: {
        borderStyle: "solid",
        borderColor: "#ffffff",
        borderTopWidth: "0px",
        borderRightWidth: "0px",
        borderBottomWidth: "0px",
        borderLeftWidth: "0px",
        isWidthLock: false,
      },
      data_content_center_width_style: {
        width: "33.33%",
      },
      data_content_center_style: {
        backgroundColor: "transparent",
        isPaddingLock: false,
        paddingTop: "0px",
        paddingRight: "0px",
        paddingBottom: "0px",
        paddingLeft: "0px",
      },
      data_content_center_border_styles: {
        borderStyle: "solid",
        borderColor: "#ffffff",
        borderTopWidth: "0px",
        borderRightWidth: "0px",
        borderBottomWidth: "0px",
        borderLeftWidth: "0px",
        isWidthLock: false,
      },
      data_content_right_width_style: {
        width: "33.33%",
      },
      data_content_right_style: {
        backgroundColor: "transparent",
        isPaddingLock: false,
        paddingTop: "0px",
        paddingRight: "0px",
        paddingBottom: "0px",
        paddingLeft: "0px",
      },
      data_content_right_border_styles: {
        borderStyle: "solid",
        borderColor: "#ffffff",
        borderTopWidth: "0px",
        borderRightWidth: "0px",
        borderBottomWidth: "0px",
        borderLeftWidth: "0px",
        isWidthLock: false,
      },
      color_properties: {
        backgroundColor: "transparent",
      },
      container_properties: {
        backgroundColor: "transparent",
      },
      style: {
        src: "",
        fileSize: 0,
        fileName: "",
        isPaddingLock: false,
        paddingTop: "0px",
        paddingRight: "0px",
        paddingBottom: "0px",
        paddingLeft: "0px",
      },
    },
  },
];

export default blocks;
