<template>
  <svg
    width="17"
    height="16"
    viewBox="0 0 17 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.0844 2.58398L15.5 3.99961L6.5 12.9996L1.5 7.99961L2.91562 6.58398L6.5 10.1715L14.0844 2.58398Z"
      fill="white"
    />
  </svg>
</template>
<script>
export default {
  name: "tickIcon",
};
</script>
<style scoped></style>