<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.7875 4L12 3H10.0625H8.0625H6.2125L6 4H7.85L6.15 12H4.2125L4 13H5.9375H7.9375H9.7875L10 12H8.15L9.85 4H11.7875Z"
      fill="#24243A"
    />
  </svg>
</template>
      <script>
export default {
  name: "italicIcon",
};
</script>
  
  <style scoped></style>