<template>
  <div class="p-16">
    <div class="bg-image-uploader">
      <div class="bg-img-showcase" v-if="true">
        <div class="btn-input">
          <button>Upload Image</button>
          <input
            class="file-input"
            ref="fileUpload"
            @change="handleFileUpload"
            type="file"
            :accept="accept"
          />
        </div>
      </div>
      <div class="bg-img-uploader-placeholder" v-else>
        <div class="btn-input">
          <button>Upload Image</button>
          <input
            class="file-input"
            ref="fileUpload"
            @change="handleFileUpload"
            type="file"
            :accept="accept"
          />
        </div>
        <div class="content"></div>
      </div>
    </div>
    <div class="upload-files-wrap d-flex flex-wrap mt-18">
      <div class="upload-files-box">
        <div class="main-img">
          <img src="../../../../src/assets/images/upload-dummy.png" alt="" />
        </div>
        <div class="checkbox">
          <checkbox :label="''" />
        </div>
        <div class="delete-button">
          <kbutton :icon="'delete'"></kbutton>
        </div>
      </div>
      <div class="upload-files-box">
        <div class="main-img">
          <img src="../../../../src/assets/images/upload-dummy.png" alt="" />
        </div>
        <div class="checkbox">
          <checkbox :label="''" />
        </div>
        <div class="delete-button">
          <kbutton :icon="'delete'"></kbutton>
        </div>
      </div>
      <div class="upload-files-box">
        <div class="main-img">
          <img src="../../../../src/assets/images/upload-dummy.png" alt="" />
        </div>
        <div class="checkbox">
          <checkbox :label="''" />
        </div>
        <div class="delete-button">
          <kbutton :icon="'delete'"></kbutton>
        </div>
      </div>
      <div class="upload-files-box">
        <div class="main-img">
          <img src="../../../../src/assets/images/upload-dummy.png" alt="" />
        </div>
        <div class="checkbox">
          <checkbox :label="''" />
        </div>
        <div class="delete-button">
          <kbutton :icon="'delete'"></kbutton>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Button } from "@progress/kendo-vue-buttons";
import { Checkbox } from "@progress/kendo-vue-inputs";

export default {
  name: "viewInYourBrowserComponent",
  components: {
    kbutton: Button,
    checkbox: Checkbox,
  },

  data() {
    return {};
  },
  computed: {},
  mounted() {},
  methods: {},
};
</script>

<style></style>
