<template>
  <div class="widget-accordion-wrap text-controller-wrap">
    <!-- Widget Accordion item-->
    <div
      :class="
        isSettingsAccordion
          ? 'widget-accordion-item show'
          : 'widget-accordion-item'
      "
    >
      <div @click="settingsAccordion()" class="widget-accordion-head">
        <span>Settings</span>
        <span class="icon">
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M4.06066 11.0607L8.00003 7.12128L11.9394 11.0607L13 10L8.00003 5L3 10L4.06066 11.0607Z"
              fill="#24243A"
            />
          </svg>
        </span>
      </div>
      <div class="widget-accordion-body">
        <div class="widget-input-item widget-editor input-link-custom-modal">
          <label class="b-form-label">Editor</label>
          <TextEditorComponent
            :defaultContent="
              column.propertiesArray[column.properties.selectedVariationIndex]
                .content
            "
            :ktools="ktools"
            :lineHeight="lineHeight"
            @execute="onExecute"
            @change="
              (e) =>
                onChangeKeditor(
                  e,
                  index,
                  childIndex,
                  columnType,
                  column.properties.selectedVariationIndex
                )
            "
          />
        </div>
      </div>
    </div>
    <!-- Widget Accordion item-->
    <div
      :class="
        isGeneralAccordion
          ? 'widget-accordion-item show'
          : 'widget-accordion-item'
      "
    >
      <div @click="generalAccordion()" class="widget-accordion-head">
        <span>GENERAL</span>
        <span class="icon">
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M4.06066 11.0607L8.00003 7.12128L11.9394 11.0607L13 10L8.00003 5L3 10L4.06066 11.0607Z"
              fill="#24243A"
            />
          </svg>
        </span>
      </div>
      <generalAccordionComponent
        :column="column"
        :columnType="columnType"
        :index="index"
        :childIndex="childIndex"
      />
    </div>

    <!-- Widget Accordion item-->
    <div
      :class="
        isRestrictContentToAccordion
          ? 'widget-accordion-item show'
          : 'widget-accordion-item'
      "
    >
      <div @click="restrictContentToAccordion()" class="widget-accordion-head">
        <span>RESTRICT CONTENT TO</span>
        <span class="icon">
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M4.06066 11.0607L8.00003 7.12128L11.9394 11.0607L13 10L8.00003 5L3 10L4.06066 11.0607Z"
              fill="#24243A"
            />
          </svg>
        </span>
      </div>
      <restrictContentToComponent
        :column="column"
        :columnType="columnType"
        :index="index"
        :childIndex="childIndex"
      />
    </div>
  </div>
</template>

<script>
const editorContentStyle = `
    p {
        // margin:0;
    }
    .k-content > p {
        // margin: 0;
    }
`;
import { Input, Switch } from "@progress/kendo-vue-inputs";
import { Editor, EditorUtils, FontName } from "@progress/kendo-vue-editor";
import paddingComponent from "../../common-components/paddingComponent.vue";
import { Checkbox } from "@progress/kendo-vue-inputs";
import { RadioButton } from "@progress/kendo-vue-inputs";
import borderWidthComponent from "../../common-components/borderWidthComponent.vue";
import restrictContentToComponent from "../../common-components/restrictContentToComponent.vue";
import { ColorPicker } from "@progress/kendo-vue-inputs";
import generalAccordionComponent from "../../common-components/generalAccordionComponent.vue";
import TextEditorComponent from "../../common-components/TextEditorComponent.vue";
import MacroDropdownComponent from "../../common-components/macroDropdownComponent.vue";
import kendoTools from "../../helpers/kendoTools";
import customColorPicker from "../../common-components/customColorPicker.vue";
import MyLineHeightTool from "../../common-components/myLineHeightTool.vue";
import { onPasteHtmlIntoEditor } from "@/utils/helper";

export default {
  inject: ["draggable_editor_data"],
  name: "textController",
  components: {
    KInput: Input,
    Switch,
    KEditor: Editor,
    paddingComponent,
    checkbox: Checkbox,
    radiobutton: RadioButton,
    borderWidthComponent,
    ColorPicker,
    restrictContentToComponent,
    generalAccordionComponent,
    TextEditorComponent,
    MacroDropdownComponent,
    fontsize: FontName,
    fontname: FontName,
    customColorPicker,
    MyLineHeightTool,
  },
  props: {
    column: Object,
    index: Number,
    childIndex: Number,
    columnType: String,
  },
  data() {
    return {
      ktools: [...kendoTools, { render: "macroDropdownComponent" }],
      isSettingsAccordion: true,
      isGeneralAccordion: true,
      isResponsiveDesignAccordion: true,
      isRestrictContentToAccordion: true,
      lineHeight: null,
    };
  },
  mounted() {},
  methods: {
    onPasteHtml(e) {
      return onPasteHtmlIntoEditor(e);
    },
    editorStyleOnLoaded(event) {
      const iframeDocument = event.dom.ownerDocument;
      const style = iframeDocument.createElement("style");
      style.appendChild(iframeDocument.createTextNode(editorContentStyle));
      iframeDocument.head.appendChild(style);
    },
    onChangeKeditor(e, index, childIndex, columnType, variationIndex) {
      if (typeof e === "string") {
        this.draggable_editor_data[index][columnType][
          childIndex
        ].propertiesArray[variationIndex].content = e;
      } else {
        console.warn("Invalid content received from editor:", e);
      }
    },
    onExecute(event) {
      setTimeout(() => {
        //
        //   name: 'line-height',
        //   value: /^.+$/,
        // })[0], "c92");
        this.lineHeight = EditorUtils.getInlineStyles(event.target.view.state, {
          name: "line-height",
          value: /^.+$/,
        })[0];
      });
      const { doc, selection } = event.transaction;
      let selectionHtml = "";

      if (!selection.empty) {
        const node = doc.cut(selection.from, selection.to);
        selectionHtml = EditorUtils.getHtml({
          doc: node,
          schema: node.type.schema,
        });
      }

      if (this.$refs.htmlAreaRef) {
        this.htmlAreaRefValue = selectionHtml;
      }
    },
    settingsAccordion() {
      this.isSettingsAccordion = !this.isSettingsAccordion;
    },
    generalAccordion() {
      this.isGeneralAccordion = !this.isGeneralAccordion;
    },
    responsiveDesignAccordion() {
      this.isResponsiveDesignAccordion = !this.isResponsiveDesignAccordion;
    },
    restrictContentToAccordion() {
      this.isRestrictContentToAccordion = !this.isRestrictContentToAccordion;
    },

    emitFunction(type, e, isFirstValue, isSecondValue) {
      this.textControllerEmit(
        type,
        e,
        this.index,
        this.childIndex,
        this.columnType,
        isFirstValue,
        isSecondValue,
        this.column.properties.selectedVariationIndex
      );
    },
    textControllerEmit(
      type,
      e,
      index,
      childIndex,
      columnType,
      isFirstValue,
      isSecondValue,
      variationIndex
    ) {
      if (type == "underLineButton") {
        if (isSecondValue && isFirstValue) {
          this.draggable_editor_data[index][columnType][
            childIndex
          ].propertiesArray[variationIndex].style.textDecoration =
            "line-through underline";
        } else if (isFirstValue) {
          this.draggable_editor_data[index][columnType][
            childIndex
          ].propertiesArray[variationIndex].style.textDecoration = "underline";
        } else if (isSecondValue) {
          this.draggable_editor_data[index][columnType][
            childIndex
          ].propertiesArray[variationIndex].style.textDecoration =
            "line-through";
        } else {
          this.draggable_editor_data[index][columnType][
            childIndex
          ].propertiesArray[variationIndex].style.textDecoration = "";
        }
      }
      if (type == "strikeThrough") {
        if (isFirstValue && isSecondValue) {
          this.draggable_editor_data[index][columnType][
            childIndex
          ].propertiesArray[variationIndex].style.textDecoration =
            "line-through underline";
        } else if (isFirstValue) {
          this.draggable_editor_data[index][columnType][
            childIndex
          ].propertiesArray[variationIndex].style.textDecoration =
            "line-through";
        } else if (isSecondValue) {
          this.draggable_editor_data[index][columnType][
            childIndex
          ].propertiesArray[variationIndex].style.textDecoration = "underline";
        } else {
          this.draggable_editor_data[index][columnType][
            childIndex
          ].propertiesArray[variationIndex].style.textDecoration = "";
        }
      }
    },
  },
};
</script>

<style scoped></style>
