<template>
  <div class="widget-accordion-body">
    <div class="widget-input-item">
      <div class="all-side option">
        <div class="row">
          <div class="col-md-12">
            <checkbox
              :class="'b-form-label'"
              :label="'Restrict this content to specific audience'"
              v-model="
                column.propertiesArray[column.properties.selectedVariationIndex]
                  .isRestricted
              "
            />
            <hr class="border-top-normal-1" />
            <div
              v-if="
                column.propertiesArray[column.properties.selectedVariationIndex]
                  .isRestricted
              "
            >
              <label for="">Select saved segments</label>

              <DropDownList
                :data-items="copySegmentsData"
                @open="onDropdownOpenHandler"
                :text-field="'segmentName'"
                :data-item-key="'id'"
                :value="selectedSegment"
                @change="onChange"
                :filterable="true"
                @filterchange="filterChange"
                :default-item="{
                  id: 0,
                  segmentName: 'Select Segment',
                }"
              />
              <!-- 
              <div class="mt-2">
                <select class="b-form-select" v-model="column.propertiesArray[
                  column.properties.selectedVariationIndex
                ].segmentId
                  ">
                  <option key="0" value="0" v-if="segmentsData?.length == 0">
                    No segment found or created.
                  </option>
                  <option v-for="data in segmentsData" :key="data.id" :value="data.id">
                    {{
                      data.segmentName.length > 34 ? data.segmentName.substring(0, 34) + "..." : data.segmentName
                    }}
                  </option>
                </select>
              </div> -->
            </div>
            <!-- <div>
                  <radiobutton :checked="false" :label="'Create Segments'" />
                  <div class="ml-18 mt-2">
                    <button
                      class="k-button-solid-base border-0 px-3 py-2"
                      title="Duplicate"
                    >
                      Create Segments
                    </button>
                  </div>
                </div> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Input, Switch } from "@progress/kendo-vue-inputs";
import { Checkbox } from "@progress/kendo-vue-inputs";
import { RadioButton } from "@progress/kendo-vue-inputs";
import { DropDownList } from "@progress/kendo-vue-dropdowns";
import { filterBy } from "@progress/kendo-data-query";

export default {
  inject: ["draggable_editor_data"],
  name: "restrictContentToComponent",
  components: {
    KInput: Input,
    checkbox: Checkbox,
    radiobutton: RadioButton,
    DropDownList,
  },
  props: {
    column: Object,
    index: Number,
    childIndex: Number,
    columnType: String,
  },
  data() {
    return {
      isLock: false,
      copySegmentsData: [],
      selectedSegment: null,
    };
  },
  computed: {
    segmentsData() {
      return this.$store.state.segments.segmentsLists?.items;
    },
  },

  mounted() {
    if (
      !this.draggable_editor_data[this.index][this.columnType][this.childIndex]
        .propertiesArray[this.column.properties.selectedVariationIndex]
        .segmentId
    ) {
      this.draggable_editor_data[this.index][this.columnType][
        this.childIndex
      ].propertiesArray[
        this.column.properties.selectedVariationIndex
      ].segmentId = Number(this.segmentsData?.[0]?.id) || 0;

      // this.selectedSegment = this.segmentsData?.[0];
    } else {
      this.selectedSegment = this.segmentsData?.find(
        (item) =>
          item.id ===
          this.draggable_editor_data[this.index][this.columnType][
            this.childIndex
          ].propertiesArray[this.column.properties.selectedVariationIndex]
            .segmentId
      );
    }
  },

  methods: {
    onDropdownOpenHandler() {
      this.copySegmentsData = structuredClone(this.segmentsData);
    },
    filterChange(event) {
      this.copySegmentsData = this.filterData(event.filter);
      // this.$store.commit("SET_EMAIL_CAMPAIGN_TEMPLATE_MACRO_LISTS", this.filterData(event.filter));
    },
    filterData(filter) {
      const data = this.segmentsData.slice();
      return filterBy(data, filter);
    },
    onChange(event) {
      this.draggable_editor_data[this.index][this.columnType][
        this.childIndex
      ].propertiesArray[
        this.column.properties.selectedVariationIndex
      ].segmentId = Number(event?.value?.id);
      this.selectedSegment = event?.value;
    },
  },
};
</script>

<style scoped></style>
