<template>
  <modal :title="'Save as template'" :handler="true" :cssClass="'c-modal-small-2'" @modalHandler="modalHandler">
    <template v-slot:content>
      <div class="k-form-field">
        <label>Template Name</label>
        <k-input type="text" v-model="templateName" @keyup="nameError = ''" placeholder="Enter template name">
        </k-input>
        <span class="k-form-error">{{ nameError }}</span>
      </div>
    </template>
    <template v-slot:footer>
      <div class="d-flex justify-content-end">
        <div class="me-1">
          <kbutton @click="modalHandler" :class="'flex-none'">Cancel</kbutton>
        </div>
        <div class="ms-1">
          <kbutton :theme-color="'primary'" @click="save" :class="'flex-none'">Save</kbutton>
        </div>
      </div>
    </template>
  </modal>
</template>

<script>
import { Dialog, DialogActionsBar } from "@progress/kendo-vue-dialogs";
import { Button } from "@progress/kendo-vue-buttons";
import { Input } from "@progress/kendo-vue-inputs";
import modal from "../../common/AppModal/AppModal";

export default {
  components: {
    "k-dialog": Dialog,
    "dialog-actions-bar": DialogActionsBar,
    kbutton: Button,
    "k-input": Input,
    modal,
  },
  props: {
    preFieldTemplateName: String,
  },
  data: function () {
    return {
      templateName: "",
      nameError: "",
    };
  },
  computed: {
    // listDetails() {
    //   return this.$store.getters.listDetails;
    // },
  },
  mounted() {
    // this.templateName = this.preFieldTemplateName;
  },
  methods: {
    modalHandler(data) {
      this.$emit("closeEditModal");
    },
    save(data) {
      if (this.templateName.trim() == this.preFieldTemplateName.trim() && !this.$route.query?.nodeId) {
        this.nameError = "Campaign and Template name can not be same.";
        return;
      }
      if (this.templateName.trim() == "") {
        this.nameError = "Template name is required.";
        return;
      }
      if (this.templateName.trim().length >= 25) {
        this.nameError = "Template name should not exceed 25 characters.";
        return;
      }
      this.$emit("saveTemplateNameModal", this.templateName);
      // this.modalHandler();
    },
  },
};
</script>

<style scoped>

</style>
