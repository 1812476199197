const widgets = [
  {
    id: 1,
    name: "widget_text",
    render_component: "textViewRender",
    controller_component: "textController",
    icon_component: "textIcon",
    type: "Text",
    properties: {
      selected: false,
      selectedVariationIndex: 0,
    },
    propertiesArray: [
      {
        type: "text_editor",
        selected: false,
        content: "This is a new Text block. Change the text.",
        isWidthLock: false,
        isPaddingLock: false,
        isRestricted: false,
        segmentId: 0,
        style: {
          width: "100%",
          height: "auto",
          padding: "0 5px",
          color: "#000000",
        },
        borderStyles: {
          borderTopWidth: "0px",
          borderRightWidth: "0px",
          borderBottomWidth: "0px",
          borderLeftWidth: "0px",
          borderStyle: "solid",
          borderColor: "#000000",
          borderRadius: "0px 0px 0px 0px",
        },
        borderStyleOptions: [
          {
            label: "Solid",
            value: "solid",
          },
          {
            label: "Dashed",
            value: "dashed",
          },
          {
            label: "Dotted",
            value: "dotted",
          },
        ],
        container_style: {
          paddingTop: "10px",
          paddingRight: "10px",
          paddingBottom: "10px",
          paddingLeft: "10px",
          textAlign: "left",
        },
      },
    ],
  },
  {
    id: 2,
    name: "widget_image",
    render_component: "imageViewRender",
    controller_component: "imageController",
    icon_component: "imageIcon",
    type: "Image",
    properties: {
      selectedVariationIndex: 0,
      selected: false,
    },
    propertiesArray: [
      {
        type: "image",
        src: "",
        fileSize: 0,
        fileName: "",
        defaultSrc:
          "https://clubeez-v2-staging.s3-ap-southeast-1.amazonaws.com/1b9cfbd1-76e7-4e67-9f97-f7c488eb5d92_638048592605592111.jpg",
        altText: "",
        imgUrl: "",
        urlTarget: "_self",
        isWidthLock: false,
        isPaddingLock: false,
        isRestricted: false,
        segmentId: 0,

        selectedTag: { Text: "Select Tag", Value: "", Id: 0 },
        isIncludeCampaignId: false,
        selectedActionType: {
          id: 1,
          label: "Open Website",
          value: "openWebsite",
        },

        actionTypeOptions: [
          {
            id: 1,
            label: "Open Website",
            value: "openWebsite",
          },
          {
            id: 2,
            label: "Insert Tag",
            value: "insertTag",
          },
        ],

        img_width: {
          width: "600px",
        },

        urlTargetOptions: [
          {
            label: "Same Tab",
            value: "_self",
          },
          {
            label: "New Tab",
            value: "_blank",
          },
        ],
        alignOptions: [
          {
            label: "Left",
            value: "left",
          },
          {
            label: "Center",
            value: "center",
          },
          {
            label: "Right",
            value: "right",
          },
        ],
        selected: false,
        isAutoWidthCheck: false,
        style: {
          display: "table",
          width: "auto",
          maxWidth: "100%",
        },
        borderStyles: {
          borderRadius: "0px 0px 0px 0px", // top,right,bottom,left
          borderTopWidth: "0px",
          borderRightWidth: "0px",
          borderBottomWidth: "0px",
          borderLeftWidth: "0px",
          borderStyle: "solid",
          borderColor: "#000000",
          boxSizing: "border-box",
        },
        borderStyleOptions: [
          {
            label: "Solid",
            value: "solid",
          },
          {
            label: "Dashed",
            value: "dashed",
          },
          {
            label: "Dotted",
            value: "dotted",
          },
        ],
        container_style: {
          paddingTop: "10px",
          paddingRight: "10px",
          paddingBottom: "10px",
          paddingLeft: "10px",
          textAlign: "center",
        },
      },
    ],
  },
  {
    id: 3,
    name: "widget_button",
    render_component: "buttonViewRender",
    controller_component: "buttonController",
    icon_component: "buttonIcon",
    type: "Button",
    properties: {
      selectedVariationIndex: 0,
      selected: false,
    },
    propertiesArray: [
      {
        type: "button",
        buttonText: "Button",
        actionType: "openWebsite",
        buttonStyle: "rectangle",
        buttonUrl: "",
        urlTarget: "_blank",
        emailsForSendEmail: "",
        isWidthLock: false,
        isPaddingLock: false,
        emailSubject: "",
        emailBody: "",
        isAutoWidthCheck: false,
        isRestricted: false,
        segmentId: 0,

        selectedTag: { Text: "Select Tag", Value: "", Id: 0 },
        isIncludeCampaignId: false,

        urlTargetOptions: [
          {
            label: "Same Window",
            value: "_self",
          },
          {
            label: "New Window",
            value: "_blank",
          },
        ],
        alignOptions: [
          {
            label: "Left",
            value: "left",
          },
          {
            label: "Center",
            value: "center",
          },
          {
            label: "Right",
            value: "right",
          },
        ],

        button_width: {
          width: "100%",
        },

        actionTypeOptions: [
          {
            label: "Open Website",
            value: "openWebsite",
          },
          {
            label: "Send Email",
            value: "sendEmail",
          },
          {
            label: "Insert Tag",
            value: "insertTag",
          },
        ],
        buttonTypeOptions: [
          {
            label: "Rectangle",
            value: "rectangle",
          },
          {
            label: "Rounded Corners",
            value: "roundedCorners",
          },
          {
            label: "Rounded",
            value: "rounded",
          },
        ],
        borderStyleOptions: [
          {
            label: "Solid",
            value: "solid",
          },
          {
            label: "Dashed",
            value: "dashed",
          },
          {
            label: "Dotted",
            value: "dotted",
          },
        ],
        selected: false,
        style: {
          maxWidth: "100%",
          boxSizing: "border-box",
          width: "100%",
          lineHeight: "25px",
          // height: "44px",
          // borderWidth: "2px 2px 2px 2px", // top, right, bottom, left
          padding: "8px 0 8px 0", // top, right, bottom, left
          // marginLeft: "10px",
          // marginRight: "10px",
          color: "#fff",
          backgroundColor: "#000",
          // lineHeight: "initial",
          fontSize: "16px",
          textDecoration: "none",
          display: "inline-block",
          textAlign: "center",
          fontWeight: "",
          fontStyle: "",
          borderRadius: "0px",
          // textOverflow: "ellipsis",
          // whiteSpace: "nowrap",
          // overflow: "hidden",
        },
        borderStyles: {
          borderTopWidth: "0px", // top, right, bottom, left
          borderRightWidth: "0px", // top, right, bottom, left
          borderBottomWidth: "0px", // top, right, bottom, left
          borderLeftWidth: "0px", // top, right, bottom, left
          borderStyle: "solid",
          borderColor: "#000000",
          // borderRadius: "0px 0px 0px 0px", // top,right,bottom,left
          boxSizing: "border-box",
        },
        container_style: {
          // padding: "10px 10px 10px 10px", // top, right, bottom, left
          paddingTop: "10px",
          paddingRight: "10px",
          paddingBottom: "10px",
          paddingLeft: "10px",
          textAlign: "center",
        },
      },
    ],
  },
  {
    id: 4,
    name: "widget_social",
    render_component: "socialViewRender",
    controller_component: "socialController",
    icon_component: "socialIcon",
    type: "Social",
    properties: {
      selectedVariationIndex: 0,
      selected: false,
    },
    propertiesArray: [
      {
        iconType: "circle",
        iconStyle: "medium",
        iconTheme: "colored",
        isWidthLock: false,
        isPaddingLock: false,
        isRestricted: false,
        segmentId: 0,
        iconProperties: {
          iconSize: {
            name: "Icon Size",
            types: ["Small", "Medium", "Large"],
            selected: "Small",
          },
          iconStyle: {
            name: "Style",
            types: ["Original", "Rounded", "Squared"],
            selected: "Original",
          },
          iconTheme: {
            name: "Theme",
            types: ["Colored", "Dark", "Light"],
            selected: "Colored",
          },
        },
        mediaData: [
          {
            name: "Facebook",
            url: "https://www.facebook.com",
            src: "https://clubeez-v2-local.s3-ap-southeast-1.amazonaws.com/Campaign/Small/Original/Colored/facebook.png",
          },
          {
            name: "Twitter",
            url: "https://www.twitter.com",
            src: "https://clubeez-v2-local.s3-ap-southeast-1.amazonaws.com/Campaign/Small/Original/Colored/twitter.png",
          },
          {
            name: "LinkedIn",
            url: "https://www.linkedin.com",
            src: "https://clubeez-v2-local.s3-ap-southeast-1.amazonaws.com/Campaign/Small/Original/Colored/linkedin.png",
          },
          {
            name: "Instagram",
            url: "https://www.instagram.com",
            src: "https://clubeez-v2-local.s3-ap-southeast-1.amazonaws.com/Campaign/Small/Original/Colored/instagram.png",
          },
        ],
        mediaDataSeeMore: [
          // {
          //   name: "Twitter",
          //   url: "https://www.twitter.com",
          //   src: "https://clubeez-v2-local.s3-ap-southeast-1.amazonaws.com/Campaign/Small/Original/Colored/twitter.png",
          // },
          // {
          //   name: "Facebook",
          //   url: "https://www.facebook.com",
          //   src: "https://clubeez-v2-local.s3-ap-southeast-1.amazonaws.com/Campaign/Small/Original/Colored/facebook.png",
          // },
          // {
          //   name: "LinkedIn",
          //   url: "https://www.linkedin.com",
          //   src: "https://clubeez-v2-local.s3-ap-southeast-1.amazonaws.com/Campaign/Small/Original/Colored/linkedin.png",
          // },
          {
            name: "Pinterest",
            url: "https://www.pinterest.com",
            src: "https://clubeez-v2-local.s3-ap-southeast-1.amazonaws.com/Campaign/Small/Original/Colored/pinterest.png",
          },
          {
            name: "Vimeo",
            url: "https://www.vimeo.com",
            src: "https://clubeez-v2-local.s3-ap-southeast-1.amazonaws.com/Campaign/Small/Original/Colored/vimeo.png",
          },
          {
            name: "Youtube",
            url: "https://www.youtube.com",
            src: "https://clubeez-v2-local.s3-ap-southeast-1.amazonaws.com/Campaign/Small/Original/Colored/youtube.png",
          },
          {
            name: "Snapchat",
            url: "https://www.snapchat.com",
            src: "https://clubeez-v2-local.s3-ap-southeast-1.amazonaws.com/Campaign/Small/Original/Colored/snapchat.png",
          },
          {
            name: "Whatsapp",
            url: "https://www.whatsapp.com",
            src: "https://clubeez-v2-local.s3-ap-southeast-1.amazonaws.com/Campaign/Small/Original/Colored/whatsapp.png",
          },
          {
            name: "Reddit",
            url: "https://reddit.com",
            src: "https://clubeez-v2-local.s3-ap-southeast-1.amazonaws.com/Campaign/Small/Original/Colored/reddit.png",
          },
          {
            name: "Messenger",
            url: "https://www.messenger.com",
            src: "https://clubeez-v2-local.s3-ap-southeast-1.amazonaws.com/Campaign/Small/Original/Colored/messenger.png",
          },
          {
            name: "Tripadvisor",
            url: "https://www.tripadvisor.com",
            src: "https://clubeez-v2-local.s3-ap-southeast-1.amazonaws.com/Campaign/Small/Original/Colored/tripadvisor.png",
          },
          {
            name: "RSS",
            url: "https://rss.com",
            src: "https://clubeez-v2-local.s3-ap-southeast-1.amazonaws.com/Campaign/Small/Original/Colored/rss.png",
          },
          {
            name: "Apple-Music",
            url: "https://music.apple.com/us/browse",
            src: "https://clubeez-v2-local.s3-ap-southeast-1.amazonaws.com/Campaign/Small/Original/Colored/apple-music.png",
          },
          {
            name: "Spotify",
            url: "https://open.spotify.com/",
            src: "https://clubeez-v2-local.s3-ap-southeast-1.amazonaws.com/Campaign/Small/Original/Colored/spotify.png",
          },
          {
            name: "SoundCloud",
            url: "https://soundcloud.com/",
            src: "https://clubeez-v2-local.s3-ap-southeast-1.amazonaws.com/Campaign/Small/Original/Colored/soundcloud.png",
          },
          {
            name: "Medium",
            url: "https://medium.com/",
            src: "https://clubeez-v2-local.s3-ap-southeast-1.amazonaws.com/Campaign/Small/Original/Colored/medium.png",
          },
          {
            name: "Skype",
            url: "https://www.skype.com/en",
            src: "https://clubeez-v2-local.s3-ap-southeast-1.amazonaws.com/Campaign/Small/Original/Colored/skype.png",
          },
          {
            name: "Flickr",
            url: "https://www.flickr.com/",
            src: "https://clubeez-v2-local.s3-ap-southeast-1.amazonaws.com/Campaign/Small/Original/Colored/flickr.png",
          },
          {
            name: "Github",
            url: "https://github.com/",
            src: "https://clubeez-v2-local.s3-ap-southeast-1.amazonaws.com/Campaign/Small/Original/Colored/github.png",
          },
          {
            name: "Discord",
            url: "https://discord.com/",
            src: "https://clubeez-v2-local.s3-ap-southeast-1.amazonaws.com/Campaign/Small/Original/Colored/discord.png",
          },
          {
            name: "Telegram",
            url: "https://www.telegram.org",
            src: "https://clubeez-v2-local.s3-ap-southeast-1.amazonaws.com/Campaign/Small/Original/Colored/telegram.png",
          },
          {
            name: "Tiktok",
            url: "https://www.tiktok.com/en/",
            src: "https://clubeez-v2-local.s3-ap-southeast-1.amazonaws.com/Campaign/Small/Original/Colored/tiktok.png",
          },
          // {
          //   name: "Instagram",
          //   url: "https://www.instagram.com/",
          //   src: "https://clubeez-v2-local.s3-ap-southeast-1.amazonaws.com/Campaign/Small/Original/Colored/instagram.png",
          // },
          {
            name: "GoodReads",
            url: "https://www.goodreads.com/",
            src: "https://clubeez-v2-local.s3-ap-southeast-1.amazonaws.com/Campaign/Small/Original/Colored/goodreads.png",
          },
          {
            name: "Line",
            url: "https://line.me/en/",
            src: "https://clubeez-v2-local.s3-ap-southeast-1.amazonaws.com/Campaign/Small/Original/Colored/line.png",
          },
          {
            name: "Link",
            url: "https://www.yourlink",
            src: "https://clubeez-v2-local.s3-ap-southeast-1.amazonaws.com/Campaign/Small/Original/Colored/link.png",
          },
          {
            name: "Twitch",
            url: "https://www.twitch.tv/",
            src: "https://clubeez-v2-local.s3-ap-southeast-1.amazonaws.com/Campaign/Small/Original/Colored/twitch.png",
          },
          {
            name: "Website",
            url: "https://www.yourwebsite",
            src: "https://clubeez-v2-local.s3-ap-southeast-1.amazonaws.com/Campaign/Small/Original/Colored/website.png",
          },
        ],
        alignOptions: [
          {
            label: "Left",
            value: "left",
          },
          {
            label: "Center",
            value: "center",
          },
          {
            label: "Right",
            value: "right",
          },
        ],
        borderStyleOptions: [
          {
            label: "Solid",
            value: "solid",
          },
          {
            label: "Dashed",
            value: "dashed",
          },
          {
            label: "Dotted",
            value: "dotted",
          },
        ],
        main_width: {
          width: "100%",
        },
        style: {
          paddingLeft: "5px",
          paddingRight: "5px",
          height: "32px",
          display: "inline-block",
          textDecoration: "none",
          color: "inherit",
        },
        verticalStyle: {
          spacing: "5",
        },
        iconImgStyle: {
          width: "32px",
          height: "32px",
        },
        container_style: {
          paddingTop: "10px",
          paddingRight: "10px",
          paddingBottom: "10px",
          paddingLeft: "10px",
          textAlign: "center",
        },
        //We Need general Style for differentiate container and icon box
        generalStyle: {
          borderStyle: "solid",
          borderTopWidth: "0px", // top, right, bottom, left
          borderRightWidth: "0px", // top, right, bottom, left
          borderBottomWidth: "0px", // top, right, bottom, left
          borderLeftWidth: "0px", // top, right, bottom, left
          borderColor: "black",
        },
      },
    ],
  },
  {
    id: 5,
    name: "widget_line",
    render_component: "lineViewRender",
    controller_component: "lineController",
    icon_component: "dividerIcon",
    type: "Divider",
    properties: {
      selectedVariationIndex: 0,
      selected: false,
    },
    propertiesArray: [
      {
        alignment: "left",
        // isWidthLock: false,
        isPaddingLock: false,
        isRestricted: false,
        segmentId: 0,
        borderStyleOptions: [
          {
            label: "Solid Line",
            value: "solid",
          },
          {
            label: "Dashed Line",
            value: "dashed",
          },
          {
            label: "Dotted Line",
            value: "dotted",
          },
          {
            label: "Double Line",
            value: "double",
          },
        ],
        style: {
          orientation: "Horizontal",
          width: "100%",
          height: "2px",
          minWidth: "10px",
          maxWidth: "100%",
          minHeight: "4px",
          maxHeight: "100%",
          margin: "auto",
          marginRight: "0",
          marginLeft: "0",
          display: "block",
        },
        line_width: {
          width: "100%",
        },
        borderStyles: {
          borderTopWidth: "2px",
          borderStyle: "solid",
          borderColor: "#000000",
        },
        container_style: {
          paddingTop: "10px",
          paddingRight: "0px",
          paddingBottom: "10px",
          paddingLeft: "0px",
          overflowWrap: "break-word",
          wordBreak: "break-word",
        },
      },
    ],
  },
  {
    id: 7,
    name: "widget_video",
    render_component: "videoViewRender",
    controller_component: "videoController",
    icon_component: "videoIcon",
    type: "Video",
    properties: {
      selectedVariationIndex: 0,
      selected: false,
    },
    propertiesArray: [
      {
        alignOptions: [
          {
            label: "Left",
            value: "left",
          },
          {
            label: "Center",
            value: "center",
          },
          {
            label: "Right",
            value: "right",
          },
        ],
        style: {
          maxHeight: "100%",
          display: "inline-table",
          width: "100%",
          height: "auto",
          backgroundColor: "transparent",
        },
        video_width: {
          width: "100%",
        },
        borderStyles: {
          borderTopWidth: "0px",
          borderRightWidth: "0px",
          borderBottomWidth: "0px",
          borderLeftWidth: "0px",
          borderStyle: "solid",
          borderColor: "#000000",
          borderRadius: "0px 0px 0px 0px",
        },
        borderStyleOptions: [
          {
            label: "Solid",
            value: "solid",
          },
          {
            label: "Dashed",
            value: "dashed",
          },
          {
            label: "Dotted",
            value: "dotted",
          },
        ],
        container_style: {
          paddingTop: "10px",
          paddingRight: "10px",
          paddingBottom: "10px",
          paddingLeft: "10px",
          textAlign: "center",
        },
        videoUrl: "",
        videoThumbnail: "https://cdn.tools.unlayer.com/video/placeholder.png",
        isLoading: false,
        isRestricted: false,
        segmentId: 0,
      },
    ],
  },
  {
    id: 8,
    name: "widget_spacer",
    render_component: "spacerViewRender",
    controller_component: "spacerController",
    icon_component: "spacerIcon",
    type: "Spacer",
    properties: {
      selectedVariationIndex: 0,
      selected: false,
    },
    propertiesArray: [
      {
        alignment: "left",
        isWidthLock: false,
        isRestricted: false,
        segmentId: 0,
        // isPaddingLock:false,
        style: {
          orientation: "Horizontal",
          height: "20px",
          minWidth: "20px",
          maxWidth: "100%",
          minHeight: "4px",
          maxHeight: "100%",
          backgroundColor: "#fff",
          margin: "auto",
          marginRight: "0",
          marginLeft: "0",
          display: "block",
        },
        borderStyles: {
          borderTopWidth: "0px", // top, right, bottom, left
          borderRightWidth: "0px", // top, right, bottom, left
          borderBottomWidth: "0px", // top, right, bottom, left
          borderLeftWidth: "0px", // top, right, bottom, left
          borderStyle: "solid",
          borderColor: "#fff",
        },
        borderStyleOptions: [
          {
            label: "Solid",
            value: "solid",
          },
          {
            label: "Dashed",
            value: "dashed",
          },
          {
            label: "Dotted",
            value: "dotted",
          },
        ],
        spacer_width: {
          width: "100%",
        },
        container_style: {
          // padding: "2px 10px",
        },
      },
    ],
  },
  {
    id: 9,
    name: "widget_html_editor",
    render_component: "htmlEditorViewRender",
    controller_component: "htmlEditorController",
    icon_component: "htmlIcon",
    type: "HTML",
    properties: {
      selectedVariationIndex: 0,
      selected: false,
    },
    propertiesArray: [
      {
        type: "html_editor",
        editable: false,
        selected: false,
        isRestricted: false,
        segmentId: 0,
        content: "<p>Start editing here...</p>",
        style: {
          width: "100%",
          height: "auto",
        },
        borderStyles: {
          borderTopWidth: "0px",
          borderRightWidth: "0px",
          borderBottomWidth: "0px",
          borderLeftWidth: "0px",
          borderStyle: "solid",
          borderColor: "#000000",
          borderRadius: "0px 0px 0px 0px",
        },
        borderStyleOptions: [
          {
            label: "Solid",
            value: "solid",
          },
          {
            label: "Dashed",
            value: "dashed",
          },
          {
            label: "Dotted",
            value: "dotted",
          },
        ],
        container_style: {
          paddingTop: "10px",
          paddingRight: "10px",
          paddingBottom: "10px",
          paddingLeft: "10px",
          textAlign: "left",
        },
      },
    ],
  },
  {
    id: 10,
    name: "widget_table_editor",
    render_component: "tableViewRender",
    controller_component: "tableController",
    icon_component: "tableIcon",
    type: "Table",
    properties: {
      selectedVariationIndex: 0,
      selected: false,
    },
    propertiesArray: [
      {
        type: "table_editor",
        editable: false,
        selected: false,
        isRestricted: false,
        segmentId: 0,

        selectedTag: { Text: "Select Tag", Value: "", Id: 0 },
        isIncludeCampaignId: false,
        selectedActionType: {
          id: 1,
          label: "Open Website",
          value: "openWebsite",
        },

        actionTypeOptions: [
          {
            id: 1,
            label: "Open Website",
            value: "openWebsite",
          },
          {
            id: 2,
            label: "Insert Tag",
            value: "insertTag",
          },
        ],

        tableProperties: {
          isDynamic: false,
          headerStyle: {
            backgroundColor: "#FFFFFF",
            isVisible: true,
          },
          staticRowStyle: {
            backgroundColor: "#FFFFFF",
            isVisible: true,
          },
          fullTableStyle: {
            isVisible: false,
            backgroundColor: "#FFFFFF",
          },
          table_cell_padding_style: {
            paddingTop: "10px",
            paddingRight: "10px",
            paddingBottom: "10px",
            paddingLeft: "10px",
            textAlign: "left",
          },
          tableBorderSelectedStyle: {
            borderStyle: "solid",
            borderColor: "#000000",
          },
          borderStyleOptions: [
            {
              label: "Solid",
              value: "solid",
            },
            {
              label: "Dashed",
              value: "dashed",
            },
            {
              label: "Dotted",
              value: "dotted",
            },
          ],
          staticTable: {
            isUpdate: false,
            isHeaderChecked: true,
            isStaticRowChecked: true,
            rowNumber: 1,
            columnNumber: 3,
            headers: [
              [
                {
                  id: "h-0-0",
                  type: "Text",
                  content: "Header Cell 1-1",
                  properties: { width: "100", alognment: "", alterText: "" },
                  isHeader: true,
                },
                {
                  id: "h-0-1",
                  type: "Text",
                  content: "Header Cell 1-2",
                  properties: { width: "100", alognment: "", alterText: "" },
                  isHeader: true,
                  isHeader: true,
                },
                {
                  id: "h-0-2",
                  type: "Text",
                  content: "Header Cell 1-3",
                  properties: { width: "100", alognment: "", alterText: "" },
                  isHeader: true,
                  isHeader: true,
                },
              ],
            ],
            tableData: [
              [
                {
                  id: "b-0-0",
                  type: "Text",
                  content: "Body Cell 1-1",
                  properties: { width: "100", alognment: "", alterText: "" },
                  isHeader: false,
                },
                {
                  id: "b-0-1",
                  type: "Text",
                  content: "Body Cell 1-2",
                  properties: { width: "100", alognment: "", alterText: "" },
                  isHeader: false,
                },
                {
                  id: "b-0-2",
                  type: "Text",
                  content: "Body Cell 1-3",
                  properties: { width: "100", alognment: "", alterText: "" },
                  isHeader: false,
                },
              ],
            ],
          },
          dynamicTable: {
            isUpdate: false,
            isHeaderChecked: false,
            isStaticRowChecked: false,
            rowNumber: 1,
            columnNumber: 3,
            headers: [
              [
                {
                  id: "h-0-0",
                  type: "Text",
                  content: "Header Cell 1-1",
                  properties: { width: "100", alognment: "", alterText: "" },
                  isHeader: true,
                },
                {
                  id: "h-0-1",
                  type: "Text",
                  content: "Header Cell 1-2",
                  properties: { width: "100", alognment: "", alterText: "" },
                  isHeader: true,
                },
                {
                  id: "h-0-2",
                  type: "Text",
                  content: "Header Cell 1-3",
                  properties: { width: "100", alognment: "", alterText: "" },
                  isHeader: true,
                },
              ],
            ],
            tableData: [
              [
                {
                  id: "b-0-0",
                  type: "Text",
                  content: "Body Cell 1-1",
                  properties: { width: "100", alignment: "", alterText: "" },
                  isHeader: false,
                },
                {
                  id: "b-0-1",
                  type: "Text",
                  content: "Body Cell 1-2",
                  properties: { width: "100", alignment: "", alterText: "" },
                  isHeader: false,
                },
                {
                  id: "b-0-2",
                  type: "Text",
                  content: "Body Cell 1-3",
                  properties: { width: "100", alignment: "", alterText: "" },
                  isHeader: false,
                },
              ],
            ],
            staticRowData: [
              [
                {
                  id: "s-0-0",
                  type: "Text",
                  content: "Static Row Cell 1-1",
                  properties: { width: "100", alignment: "", alterText: "" },
                  isStaticRow: true,
                },
                {
                  id: "s-0-1",
                  type: "Text",
                  content: "Static Row Cell 1-2",
                  properties: { width: "100", alignment: "", alterText: "" },
                  isStaticRow: true,
                },
                {
                  id: "s-0-2",
                  type: "Text",
                  content: "Static Row Cell 1-3",
                  properties: { width: "100", alignment: "", alterText: "" },
                  isStaticRow: true,
                },
              ],
            ],
          },
        },
        content: "<p>Start editing here...</p>",
        style: {
          width: "100%",
          height: "auto",
        },
        borderStyles: {
          borderTopWidth: "0px",
          borderRightWidth: "0px",
          borderBottomWidth: "0px",
          borderLeftWidth: "0px",
          borderStyle: "solid",
          borderColor: "#000000",
          borderRadius: "0px 0px 0px 0px",
        },
        borderStyleOptions: [
          {
            label: "Solid",
            value: "solid",
          },
          {
            label: "Dashed",
            value: "dashed",
          },
          {
            label: "Dotted",
            value: "dotted",
          },
        ],
        container_style: {
          paddingTop: "10px",
          paddingRight: "10px",
          paddingBottom: "10px",
          paddingLeft: "10px",
          textAlign: "left",
        },
      },
    ],
  },
  {
    id: 11,
    name: "widget_product_editor",
    render_component: "productViewRender",
    controller_component: "productController",
    icon_component: "productIcon",
    type: "Product",
    properties: {
      selectedVariationIndex: 0,
      selected: false,
    },
    propertiesArray: [
      {
        type: "product_editor",
        productAddProcessType: "Static",
        activeTextTab: "name",
        productsList: [],
        productIds: [],
        dynamicProductsList: {},
        defaultProduct: {
          id: 1,
          name: "Product Name",
          basePrice: "xxx.xx",
          image: null,
          salePrice: "xxx.xx",
        },
        img_width: {
          width: "600px",
        },
        buttonProperties: {
          btn_text: "Shop Now",
          isBorderApply: true,
          style: {
            maxWidth: "100%",
            boxSizing: "border-box",
            lineHeight: "25px",
            padding: "8px 0 8px 0",
            color: "#fff",
            backgroundColor: "#0052CC",
            fontSize: "16px",
            textDecoration: "none",
            display: "inline-block",
            textAlign: "center",
            fontWeight: "",
            fontStyle: "",
            borderRadius: "0px",
          },
        },
        itemsVisibility: {
          name: {
            isVisible: true,
            isLinked: false,
            link: "",
            text: "Product Name",
            tab: ["Static", "Dynamic"],
          },
          price: {
            isVisible: true,
            text: "Price",
            tab: ["Static", "Dynamic"],
          },
          description: {
            isVisible: false,
            text: "Description",
            tab: ["Static"],
          },
          salePrice: {
            isVisible: false,
            text: "Sale Price",
            tab: ["Static", "Dynamic"],
          },
        },
        layoutOrientation: {
          isHorizontal: true,
          isVerticalRight: false,
          isVerticalLeft: false,
        },
        productsPerRow: 3,
        numberOfRows: 1,
        maxImageHeight: 100,

        text_name_font_size: 14,
        text_name_color: "#0052CC",
        text_name_font_family: {
          text: "Arial",
          value: "Arial, 'Helvetica Neue', Helvetica, sans-serif",
        },
        text_name_alignment: {
          isLeft: true,
          isCenter: false,
          isRight: false,
          isIndent: false,
        },
        text_name_font_style: {
          isBold: true,
          isItalic: false,
          isUnderline: false,
          isStrikethrough: false,
        },

        text_description_font_size: 12,
        text_description_color: "#0052CC",
        text_description_font_family: {
          text: "Arial",
          value: "Arial, 'Helvetica Neue', Helvetica, sans-serif",
        },
        text_description_font_style: {
          isBold: false,
          isItalic: false,
          isUnderline: false,
          isStrikethrough: false,
        },

        button_name_font_family: {
          text: "Arial",
          value: "Arial, 'Helvetica Neue', Helvetica, sans-serif",
        },
        button_name_font_style: {
          isBold: false,
          isItalic: false,
          isUnderline: false,
          isStrikethrough: false,
        },
        button_font_size: 12,
        buttonBorderStyles: {
          borderTopWidth: "0px",
          borderRightWidth: "0px",
          borderBottomWidth: "0px",
          borderLeftWidth: "0px",
          borderStyle: "solid",
          borderColor: "#000000",
          boxSizing: "border-box",
        },
        buttonCornerStyles: {
          borderTopLeftRadius: "3px",
          borderTopRightRadius: "3px",
          borderBottomLeftRadius: "3px",
          borderBottomRightRadius: "3px",
        },

        buttonPaddingstyle: {
          paddingTop: "8px",
          paddingRight: "10px",
          paddingBottom: "8px",
          paddingLeft: "10px",
        },
        buttonContainerStyle: {
          textAlign: "center",
        },

        text_price_font_size: 12,
        text_price_color: "#404A61",
        text_price_font_family: {
          text: "Arial",
          value: "Arial, 'Helvetica Neue', Helvetica, sans-serif",
        },
        text_price_alignment: {
          isLeft: true,
          isCenter: false,
          isRight: false,
          isIndent: false,
        },
        text_price_font_style: {
          isBold: false,
          isItalic: false,
          isUnderline: false,
          isStrikethrough: false,
        },

        text_sale_price_font_size: 12,
        text_sale_price_color: "#404A61",
        text_sale_price_font_family: {
          text: "Arial",
          value: "Arial, 'Helvetica Neue', Helvetica, sans-serif",
        },
        text_sale_price_font_style: {
          isBold: false,
          isItalic: false,
          isUnderline: false,
          isStrikethrough: false,
        },

        item_padding_style: {
          paddingTop: "5px",
          paddingRight: "5px",
          paddingBottom: "5px",
          paddingLeft: "5px",
        },
        item_border_styles: {
          borderTopWidth: "1px",
          borderRightWidth: "1px",
          borderBottomWidth: "1px",
          borderLeftWidth: "1px",
          borderStyle: "solid",
          borderColor: "#E9EBEF",
          borderRadius: "0px 0px 0px 0px",
        },

        editable: false,
        selected: false,
        isRestricted: false,
        segmentId: 0,

        isIncludeCampaignId: false,

        tableProperties: {
          isDynamic: false,
          headerStyle: {
            backgroundColor: "#FFFFFF",
            isVisible: true,
          },
          staticRowStyle: {
            backgroundColor: "#FFFFFF",
            isVisible: true,
          },
          fullTableStyle: {
            isVisible: false,
            backgroundColor: "#FFFFFF",
          },
          table_cell_padding_style: {
            paddingTop: "10px",
            paddingRight: "10px",
            paddingBottom: "10px",
            paddingLeft: "10px",
            textAlign: "left",
          },
          tableBorderSelectedStyle: {
            borderStyle: "solid",
            borderColor: "#000000",
          },
          borderStyleOptions: [
            {
              label: "Solid",
              value: "solid",
            },
            {
              label: "Dashed",
              value: "dashed",
            },
            {
              label: "Dotted",
              value: "dotted",
            },
          ],
        },
        content: "<p>Start editing here...</p>",
        style: {
          width: "100%",
          height: "auto",
        },
        borderStyles: {
          borderTopWidth: "0px",
          borderRightWidth: "0px",
          borderBottomWidth: "0px",
          borderLeftWidth: "0px",
          borderStyle: "solid",
          borderColor: "#000000",
          borderRadius: "0px 0px 0px 0px",
        },
        borderStyleOptions: [
          {
            label: "Solid",
            value: "solid",
          },
          {
            label: "Dashed",
            value: "dashed",
          },
          {
            label: "Dotted",
            value: "dotted",
          },
        ],
        container_style: {
          paddingTop: "5px",
          paddingRight: "5px",
          paddingBottom: "5px",
          paddingLeft: "5px",
          textAlign: "center",
        },
      },
    ],
  },
  {
    id: 12,
    name: "widget_navigation",
    render_component: "navigationViewRender",
    controller_component: "navigationController",
    icon_component: "navigationIcon",
    type: "Navigation",
    properties: {
      selectedVariationIndex: 0,
      selected: false,
    },
    propertiesArray: [
      {
        navigations: [
          {
            name: "Home",
            url: "",
            isBold: false,
            isItalic: false,
            color: "#000000",
          },
          {
            name: "About",
            url: "",
            isBold: false,
            isItalic: false,
            color: "#000000",
          },
          {
            name: "Sale",
            url: "",
            isBold: false,
            isItalic: false,
            color: "#000000",
          },
        ],

        columnSpace: 10,
        tableWidth: 0,
        containerMaxWidth: null,
        calculatedRowsWatchData: null,
        getTextWidthWatchData: null,
        textAlign: "center",
        font_family: {
          text: "Arial",
          value: "Arial, 'Helvetica Neue', Helvetica, sans-serif",
        },

        isWidthLock: false,
        isPaddingLock: false,
        isRestricted: false,
        segmentId: 0,

        selectedTag: { Text: "Select Tag", Value: "", Id: 0 },
        isIncludeCampaignId: false,

        selected: false,
        style: {
          maxWidth: "100%",
          boxSizing: "border-box",
          width: "100%",
          lineHeight: "25px",
          padding: "8px 0 8px 0",
          color: "#fff",
          backgroundColor: "#FFF",
          fontSize: "16px",
          textDecoration: "none",
          display: "inline-block",
          textAlign: "center",
          fontWeight: "",
          fontStyle: "",
          borderRadius: "0px",
        },
        borderStyleOptions: [
          {
            label: "Solid",
            value: "solid",
          },
          {
            label: "Dashed",
            value: "dashed",
          },
          {
            label: "Dotted",
            value: "dotted",
          },
        ],
        borderStyles: {
          borderTopWidth: "0px", // top, right, bottom, left
          borderRightWidth: "0px", // top, right, bottom, left
          borderBottomWidth: "0px", // top, right, bottom, left
          borderLeftWidth: "0px", // top, right, bottom, left
          borderStyle: "solid",
          borderColor: "#000000",
          boxSizing: "border-box",
        },
        container_style: {
          paddingTop: "10px",
          paddingRight: "10px",
          paddingBottom: "10px",
          paddingLeft: "10px",
          textAlign: "center",
        },
      },
    ],
  },
];

export default widgets;
