<template>
    <svg width="33" height="32" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M12.307 0H21.017L32.667 32H26.267L23.937 25.6H9.39699L7.06699 32H0.666992L12.307 0ZM16.667 5.62L11.727 19.2H21.607L16.667 5.62Z" fill="#2B354C" fill-opacity="0.65"/>
    </svg>
</template>
<script>
    export default {
        name: "textIcon"
    }
</script>
<style scoped></style>