<template>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M3 11L22 2L13 21L11 13L3 11Z" stroke="#404A61" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
</template>
<script>
    export default {
        name: "navigationIcon."
    }
</script>
<style scoped></style>