<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M10 9V2H12V9C12 11.2 9.99062 12 8 12C6.00938 12 4 11.2 4 9V2H6V9C6 9.3625 6.10938 9.5 6.32188 9.63438C6.69063 9.86562 7.3 10 8 10C8.7 10 9.30937 9.86562 9.67812 9.63438C9.89062 9.5 10 9.3625 10 9ZM12 14V13H4V14H12Z"
      fill="#24243A"
    />
  </svg>
</template>
        <script>
export default {
  name: "underLineIcon",
};
</script>
    
    <style scoped></style>