<template>
    <svg width="33" height="32" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M27.9703 10.1818H22.1522V4.36364C22.1522 3.20615 21.6924 2.09646 20.8742 1.27792C20.0557 0.459736 18.946 0 17.7885 0H4.69762C3.54013 0 2.43044 0.459736 1.61191 1.27792C0.793721 2.09643 0.333984 3.20615 0.333984 4.36364V17.4545C0.333984 18.612 0.793721 19.7217 1.61191 20.5403C2.43042 21.3584 3.54013 21.8182 4.69762 21.8182H10.5158V27.6364C10.5158 28.7938 10.9755 29.9035 11.7937 30.7221C12.6122 31.5403 13.722 32 14.8794 32H27.9703C29.1278 32 30.2375 31.5403 31.0561 30.7221C31.8742 29.9036 32.334 28.7938 32.334 27.6364V14.5455C32.334 13.388 31.8742 12.2783 31.0561 11.4597C30.2376 10.6416 29.1278 10.1818 27.9703 10.1818ZM4.69762 18.9091C4.31191 18.9091 3.94177 18.7558 3.66905 18.4831C3.39633 18.2104 3.24308 17.8403 3.24308 17.4545V4.36364C3.24308 3.97792 3.39633 3.60779 3.66905 3.33506C3.94177 3.06234 4.31191 2.90909 4.69762 2.90909H17.7885C18.1742 2.90909 18.5444 3.06234 18.8171 3.33506C19.0898 3.60779 19.2431 3.97792 19.2431 4.36364V10.1818H14.8794C13.722 10.1818 12.6123 10.6416 11.7937 11.4597C10.9755 12.2782 10.5158 13.388 10.5158 14.5455V18.9091H4.69762ZM19.2431 13.0909V17.4545C19.2431 17.8403 19.0898 18.2104 18.8171 18.4831C18.5444 18.7558 18.1742 18.9091 17.7885 18.9091H13.4249V14.5455C13.4249 14.1597 13.5781 13.7896 13.8509 13.5169C14.1236 13.2442 14.4937 13.0909 14.8794 13.0909H19.2431ZM29.4249 27.6364C29.4249 28.0221 29.2716 28.3922 28.9989 28.6649C28.7262 28.9377 28.3561 29.0909 27.9703 29.0909H14.8794C14.4937 29.0909 14.1236 28.9377 13.8509 28.6649C13.5781 28.3922 13.4249 28.0221 13.4249 27.6364V21.8182H17.7885C18.946 21.8182 20.0557 21.3584 20.8742 20.5403C21.6924 19.7217 22.1522 18.612 22.1522 17.4545V13.0909H27.9703C28.3561 13.0909 28.7262 13.2442 28.9989 13.5169C29.2716 13.7896 29.4249 14.1597 29.4249 14.5455V27.6364Z" fill="#2B354C" fill-opacity="0.65"/>
    </svg>
</template>
<script>
    export default {
        name: "dividerIcon"
    }
</script>
<style scoped></style>