const pxInputHandler = (input) => {
  if (input.toLowerCase().split("p")[0] <= 0) {
    return "0px";
  }
  if (input.toLowerCase() <= 0) {
    return "0px";
  }
  if (input.toLowerCase().endsWith("px")) return input.toLowerCase();
  else {
    return input + "px";
  }
};

const pxInputHandlerWithValidation = (input) => {
  if (input.toLowerCase().split("p")[0] <= 0) {
    return "0px";
  }
  if (input.toLowerCase() <= 0) {
    return "0px";
  }
  if (input.toLowerCase().endsWith("px")) {
    let value =
      input.toLowerCase().split("px")[0] > 50
        ? 50 + "px"
        : input.toLowerCase().split("px")[0] < 0
        ? 0 + "px"
        : input.toLowerCase();
    return value;
  } else {
    return input > 50 ? 50 : input < 0 ? 0 : input + "px";
  }
};

const parcentageInputHandler = (input) => {
  if (input.toLowerCase().split("%")[0] <= 0) {
    return "0%";
  }
  if (input.toLowerCase() <= 0) {
    return "0%";
  }
  if (input.toLowerCase().endsWith("%")) return input.toLowerCase();
  else {
    return input + "%";
  }
};

export { pxInputHandler, pxInputHandlerWithValidation, parcentageInputHandler };
