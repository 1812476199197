<template>
  <AppLoader v-if="isFileUploading" />
  <div>
    <AppSnackBar :message="notifyMessage" :notificationType="notifyType" v-if="isNotify"></AppSnackBar>
    <div class="bg-image-uploader">
      <div class="bg-img-showcase" v-if="true">
        <div class="btn-input">
          <button>Upload Image</button>
          <input class="file-input" ref="fileUpload" @change="handleFileUpload" type="file" :accept="accept" />
        </div>
        <div class="img-info">
          <div class="d-flex flex-nowrap justify-content-between align-items-center mt-3" v-if="this.modelValue">
            <div class="img-wrap">
              <img :src="this.modelValue" alt="map" />
              <k-button @click="handleCancel" :rounded="'full'" :size="'small'" :icon="'close'" :class="'remove-img'"
                :theme-color="'dark'" />
            </div>
            <div class="bg-img-file">
              <span class="text" v-if="fileName">{{ fileName }}</span>
              <p class="file-size" v-if="fileSize">
                {{ fileSize }}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="bg-img-uploader-placeholder" v-else>
        <div class="btn-input">
          <button>Upload Image</button>
          <input class="file-input" ref="fileUpload" @change="handleFileUpload" type="file" :accept="accept" />
        </div>
        <div class="content"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { Button } from "@progress/kendo-vue-buttons";
import AppSnackBar from "@/components/common/AppSnackBar/AppSnackBar.vue";
import { HTTP } from "@/service";
import { configuration } from "@/configurationProvider";
import AppLoader from "@/components/common/AppLoader/AppLoader.vue";

export default {
  inject: ["draggable_editor_data"],
  name: "EditorFileUploader",
  components: {
    "k-button": Button,
    AppSnackBar,
    AppLoader,
  },
  props: {
    accept: {
      type: String,
      default: "image/png, image/gif, image/jpeg",
    },
    modelValue: String,
    filename: String,
    column: Object,
    index: Number,
    childIndex: Number,
    columnType: String,
    isFrom: String,
  },
  data() {
    return {
      file: "",
      fileData: {},
      notifyMessage: "",
      notifyType: "",
      notify: false,
      isNotify: false,
      fileSize: "",
      fileName: "",
      isFileUploading: false,
      isFileUploaded: false,
    };
  },

  mounted() {
    this.dataReload();
  },

  methods: {
    handleFileUpload(event) {
      this.file = event.target.files[0];
      const image = event.target.files[0];

      if (this.file.size > 10485760) {
        ;
        this.showNotification(
          "File size cannot be greater than 10mb",
          "warning"
        );
        this.handleCancel();
      } else if (!this.file.type.includes("image")) {
        this.showNotification("File type not supported", "warning");
        this.handleCancel();
      } else {
        this.isFileUploading = true;

        this.dataSaverFunction(image);

        const reader = new FileReader();
        reader.readAsDataURL(image);
        const bodyFormData = new FormData();
        bodyFormData.append("photoPath", image);

        HTTP.post(
          configuration.apiBaseUrl +
          "/api/v1/Document/UploadFileToAWS?clubId=" +
          this.$route.query.club_id,
          bodyFormData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        )
          .then((response) => {
            this.isFileUploading = false;
            if (response) {
              this.fileData = response;
              this.$emit("update:modelValue", response?.data?.data?.url);
              this.$emit("onChangemodelValue", response?.data?.data?.url);
              this.isFileUploaded = true;
            }
          })
          .catch((err) => {
            this.isFileUploading = false;
            this.showNotification("Something went wrong", "error");
          });
      }
    },
    dataSaverFunction(image) {
      this.fileSize = this.formatFileSize(image?.size);
      this.fileName = image?.name;
      if (this?.isFrom == "rowProperty") {
        this.draggable_editor_data[this.index].properties.style.fileSize =
          this.formatFileSize(image?.size);

        this.draggable_editor_data[this.index].properties.style.fileName =
          image?.name;
      } else {
        this.draggable_editor_data[this.index][this.columnType][
          this.childIndex
        ].propertiesArray[
          this.column.properties.selectedVariationIndex
        ].fileSize = this.formatFileSize(image?.size);

        this.draggable_editor_data[this.index][this.columnType][
          this.childIndex
        ].propertiesArray[
          this.column.properties.selectedVariationIndex
        ].fileName = image?.name;
      }
    },
    dataReload() {
      if (this.isFrom == "rowProperty") {
        this.fileSize =
          this.draggable_editor_data[this.index].properties.style.fileSize;
        this.fileName =
          this.draggable_editor_data[this.index].properties.style.fileName;
      } else {
        this.fileSize =
          this.draggable_editor_data[this.index][this.columnType][
            this.childIndex
          ].propertiesArray[
            this.column.properties.selectedVariationIndex
          ].fileSize;
        this.fileName =
          this.draggable_editor_data[this.index][this.columnType][
            this.childIndex
          ].propertiesArray[
            this.column.properties.selectedVariationIndex
          ].fileName;
      }
    },
    handleCancel() {
      this.$refs.fileUpload.value = null;
      this.file = "";
      this.$emit("cancel");
      this.$emit("update:modelValue", "");
      this.$emit("onCancelModelValue", "");
      this.isFileUploaded = false;
    },
    showNotification(message, type) {
      this.notifyMessage = message;
      this.notifyType = type;
      this.isNotify = true;
      setTimeout(
        () => (
          (this.notifyMessage = ""),
          (this.notifyType = "success"),
          (this.isNotify = false)
        ),
        2000
      );
    },
    formatFileSize(bytes, decimalPoint = 2) {
      let units = ["B", "KB", "MB", "GB", "TB", "PB"];
      let i = 0;
      for (i; bytes > 1024; i++) {
        bytes /= 1024;
      }
      return parseFloat(bytes.toFixed(decimalPoint)) + " " + units[i];
    },
  },
};
</script>

<style scoped></style>
