<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.9997 2C14.3679 2 14.6663 2.29848 14.6663 2.66667V13.3333C14.6663 13.7015 14.3679 14 13.9997 14H1.99967C1.63149 14 1.33301 13.7015 1.33301 13.3333V2.66667C1.33301 2.29848 1.63149 2 1.99967 2H13.9997ZM13.333 3.33333H2.66634V12.6667H13.333V3.33333ZM11.9997 4.66667V6H3.99967V4.66667H11.9997Z"
      fill="#24243A"
    />
  </svg>
</template>
      <script>
export default {
  name: "orientationHorizontal",
};
</script>
  
  <style scoped></style>