<template>
    <svg width="33" height="32" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M30.381 0H2.95246C1.69075 0 0.666748 1.19467 0.666748 2.66667V29.3333C0.666748 30.8053 1.69075 32 2.95246 32H30.381C31.6427 32 32.6667 30.8053 32.6667 29.3333V2.66667C32.6667 1.19467 31.6427 0 30.381 0ZM21.2382 8V13.3333H12.0953V8H21.2382ZM21.2382 16V21.3333H12.0953V16H21.2382ZM2.95246 8H9.8096V13.3333H2.95246V8ZM2.95246 16H9.8096V21.3333H2.95246V16ZM2.95246 29.3333V24H9.8096V29.3333H2.95246ZM12.0953 29.3333V24H21.2382V29.3333H12.0953ZM30.381 29.3333H23.5239V24H30.381V29.3333ZM30.381 21.3333H23.5239V16H30.381V21.3333ZM30.381 13.3333H23.5239V8H30.381V13.3333Z" fill="#2B354C" fill-opacity="0.65"/>
    </svg>
</template>
<script>
    export default {
        name: "tableIcon"
    }
</script>
<style scoped></style>