<template>
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M15.3523 1.50204C15.5505 1.39183 15.7735 1.33398 16.0003 1.33398C16.2271 1.33398 16.4501 1.39183 16.6483 1.50204L28.6483 8.1687C28.8561 8.28423 29.0291 8.45318 29.1496 8.65807C29.2701 8.86296 29.3337 9.09634 29.3337 9.33404V22.6674C29.3337 22.9051 29.2701 23.1384 29.1496 23.3433C29.0291 23.5482 28.8561 23.7172 28.6483 23.8327L16.6483 30.4994C16.4501 30.6096 16.2271 30.6674 16.0003 30.6674C15.7735 30.6674 15.5505 30.6096 15.3523 30.4994L3.35233 23.8327C3.14459 23.7172 2.97151 23.5482 2.85101 23.3433C2.73052 23.1384 2.66698 22.9051 2.66699 22.6674V9.33404C2.66698 9.09634 2.73052 8.86296 2.85101 8.65807C2.97151 8.45318 3.14459 8.28423 3.35233 8.1687L15.3523 1.50204ZM5.33366 11.6007L14.667 16.7847V27.0687L5.33366 21.882V11.6007ZM17.3337 27.0674L26.667 21.8834V11.6007L17.3337 16.7847V27.0687V27.0674ZM16.0003 14.4754L25.2537 9.33404L16.0003 4.19271L6.74699 9.33404L16.0003 14.4754Z" fill="#6C7281"/>
    </svg>
</template>
<script>
    export default {
        name: "productIcon"
    }
</script>
<style scoped></style>