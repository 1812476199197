<template>
  <div class="widget-accordion-wrap img-controller-wrap">
    <!-- Widget Accordion item-->
    <div :class="isActionAccordion
      ? 'widget-accordion-item show'
      : 'widget-accordion-item'
      ">
      <div @click="actionAccordion()" class="widget-accordion-head">
        <span>IMAGE</span>
        <span class="icon">
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M4.06066 11.0607L8.00003 7.12128L11.9394 11.0607L13 10L8.00003 5L3 10L4.06066 11.0607Z"
              fill="#24243A" />
          </svg>
        </span>
      </div>
      <div class="widget-accordion-body">
        <div class="widget-input-item">
          <label class="b-form-label">Image</label>
          <EditorFileUploader v-model="column.propertiesArray[column.properties.selectedVariationIndex]
            .src
            " :column="column" :columnType="columnType" :index="index" :childIndex="childIndex" />
        </div>
        <div class="widget-input-item">
          <label class="b-form-label">Image URL</label>
          <input type="text" v-model="column.propertiesArray[column.properties.selectedVariationIndex]
            .src
            " class="b-form-field" />
        </div>
        <div class="widget-input-item">
          <div>
            <div class="d-flex justify-content-between">
              <label class="b-form-label">Width</label>

              <!-- <checkbox
                :class="'b-form-label'"
                :label="'Auto Width'"
                :checked="isAutoWidthCheck"
                @change="autoWidthSet($event)"
              /> -->
            </div>
            <div class="d-flex justify-content-between">
              <input type="text" @focusout="widthChangeHandler($event)" :value="column.propertiesArray[
                column.properties.selectedVariationIndex
              ].img_width.width
                " class="b-form-field" />
              <!-- <slider :class="'w-100'" :buttons="false" :default-value="autoWidthValue" :disabled="isAutoWidthCheck"
                :value="autoWidthValue" :min="1" :max="100" @change="widthSlider($event)">
              </slider>
              <span class="font-16 ms-3">{{ Math.round(autoWidthValue) }}%</span> -->
            </div>
          </div>
        </div>

        <div class="widget-input-item alignment">
          <alignmentComponent :column="column" :columnType="columnType" :index="index" :childIndex="childIndex" />
        </div>

        <div class="widget-input-item border-bottom-0 mb-0">
          <label class="b-form-label">Alternative Text</label>
          <div class="widget-input-dropdown">
            <input type="text" class="b-form-field" placeholder="Alt Image" v-model="column.propertiesArray[column.properties.selectedVariationIndex]
              .altText
              " />
          </div>
        </div>
        <div class="widget-input-item">
          <label class="b-form-label">Action Type</label>
          <!-- <DropDownList :data-items="column.propertiesArray[column.properties.selectedVariationIndex].actionTypeOptions"
            :text-field="'label'" :data-item-key="'id'"
            :value="column.propertiesArray[column.properties.selectedVariationIndex].selectedActionType"
             @change="onChangeActionType" /> -->
          <select class="b-form-select" v-model="column.propertiesArray[column.properties.selectedVariationIndex]
            .selectedActionType
            ">
            <option v-for="data in column.propertiesArray[
              column.properties.selectedVariationIndex
            ].actionTypeOptions" :key="data.id" :value="data">
              {{ data.label }}
            </option>
          </select>
        </div>

        <div v-if="column.propertiesArray[column.properties.selectedVariationIndex]
          .selectedActionType?.id == 2
          " class="widget-input-item">
          <label class="b-form-label">Select Tag</label>
          <DropDownList :data-items="copyTags" @open="onDropdownOpenHandler" :text-field="'Text'" :data-item-key="'Id'"
            :value="column.propertiesArray[column.properties.selectedVariationIndex]
              .selectedTag
              "  @change="onChange" :filterable="true"
            @filterchange="filterChange" />
        </div>
        <div v-if="column.propertiesArray[column.properties.selectedVariationIndex]
          .selectedActionType?.id == 1
          " class="widget-input-item">
          <label class="b-form-label">Link Address</label>
          <input type="text" v-model="column.propertiesArray[column.properties.selectedVariationIndex]
            .imgUrl
            " class="b-form-field" />
        </div>
        <div class="widget-input-item">
          <div class="d-flex justify-content-between align-items-start">
            <div>
              <label>Include Campaign ID</label>
              <p class="font-14">Track link/tag performance in analytics. Recommended for flow automation users.</p>
            </div>
            <Switch @change="onChangeIncludeCampaignId" :checked="column.propertiesArray[column.properties.selectedVariationIndex]
              .isIncludeCampaignId" />
          </div>
        </div>
      </div>
    </div>
    <!-- Widget Accordion item-->
    <div :class="isGeneralsAccordion
      ? 'widget-accordion-item show'
      : 'widget-accordion-item'
      ">
      <div @click="generalsAccordion()" class="widget-accordion-head">
        <span>GENERAL</span>
        <span class="icon">
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M4.06066 11.0607L8.00003 7.12128L11.9394 11.0607L13 10L8.00003 5L3 10L4.06066 11.0607Z"
              fill="#24243A" />
          </svg>
        </span>
      </div>
      <generalAccordionComponent :column="column" :columnType="columnType" :index="index" :childIndex="childIndex" />
    </div>
    <div :class="isRestrictContentToAccordion
      ? 'widget-accordion-item show'
      : 'widget-accordion-item'
      ">
      <div @click="restrictContentToAccordion()" class="widget-accordion-head">
        <span>RESTRICT CONTENT TO</span>
        <span class="icon">
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M4.06066 11.0607L8.00003 7.12128L11.9394 11.0607L13 10L8.00003 5L3 10L4.06066 11.0607Z"
              fill="#24243A" />
          </svg>
        </span>
      </div>
      <RestrictContentToComponent :column="column" :columnType="columnType" :index="index" :childIndex="childIndex" />
    </div>
  </div>
</template>

<script>
import { Input, Switch } from "@progress/kendo-vue-inputs";
import EditorFileUploader from "../../common-components/EditorFileUploader.vue";

import { Slider } from "@progress/kendo-vue-inputs";
import { Checkbox } from "@progress/kendo-vue-inputs";
import { ColorPicker } from "@progress/kendo-vue-inputs";
import borderWidthComponent from "../../common-components/borderWidthComponent.vue";
import alignmentComponent from "../../common-components/alignmentComponent.vue";
import widthSliderComponent from "../../common-components/widthSliderComponent.vue";
import paddingComponent from "../../common-components/paddingComponent.vue";
import generalAccordionComponent from "../../common-components/generalAccordionComponent.vue";
import RestrictContentToComponent from "../../common-components/restrictContentToComponent.vue";
import { pxInputHandler } from "../../helpers/utility";
import { DropDownList } from "@progress/kendo-vue-dropdowns";
import { filterBy } from '@progress/kendo-data-query';


export default {
  inject: ["draggable_editor_data", "wrapperContainerStyle"],
  name: "imageController",
  components: {
    KInput: Input,
    Switch,
    EditorFileUploader,
    Slider,
    Checkbox,
    ColorPicker,
    borderWidthComponent,
    paddingComponent,
    alignmentComponent,
    widthSliderComponent,
    generalAccordionComponent,
    RestrictContentToComponent,
    DropDownList
  },
  data() {
    return {
      isActionAccordion: true,
      isImageOptionsAccordion: true,
      isGeneralsAccordion: true,
      isResponsiveDesignAccordion: true,
      isRestrictContentToAccordion: true,

      isAutoWidthCheck: false,
      autoWidthValue: 100,
      isAutoWidthCheck: false,
      isLeftAlign: false,
      isRightAlign: false,
      iscenterAlign: false,
      isUploadingImage: false,
      copyTags: [],
    };
  },
  props: {
    column: Object,
    index: Number,
    childIndex: Number,
    columnType: String,
  },
  computed: {
    macros() {
      return this.$store.state.commonEmailCampaign
        .emailCampaignTemplateMacroLists;
    },
  },
  mounted() {
    this.isAutoWidthCheck =
      this.column.propertiesArray[
        this.column.properties.selectedVariationIndex
      ].isAutoWidthCheck;

    if (
      this.column.propertiesArray[this.column.properties.selectedVariationIndex]
        .style?.maxWidth != "auto" &&
      !this.isAutoWidthCheck
    ) {
      this.autoWidthValue =
        this.column.propertiesArray[
          this.column.properties.selectedVariationIndex
        ]?.style?.maxWidth?.split("%")[0];
    }
  },
  methods: {
    onDropdownOpenHandler() {
      this.copyTags = structuredClone(this.macros);
    },
    filterChange(event) {
      this.copyTags = this.filterData(event.filter);
    },
    filterData(filter) {
      const data = this.macros.slice();
      return filterBy(data, filter);
    },
    onChange(event) {
      this.draggable_editor_data[this.index][this.columnType][
        this.childIndex
      ].propertiesArray[
        this.column.properties.selectedVariationIndex
      ].selectedTag = event.value
    },
    onChangeActionType(event) {
      this.draggable_editor_data[this.index][this.columnType][
        this.childIndex
      ].propertiesArray[
        this.column.properties.selectedVariationIndex
      ].selectedActionType = event.target.value;
    },
    onChangeIncludeCampaignId(event) {
      this.draggable_editor_data[this.index][this.columnType][
        this.childIndex
      ].propertiesArray[
        this.column.properties.selectedVariationIndex
      ].isIncludeCampaignId = event.target.value;
    },
    widthChangeHandler(e) {
      this.draggable_editor_data[this.index][this.columnType][
        this.childIndex
      ].propertiesArray[
        this.column.properties.selectedVariationIndex
      ].img_width.width = pxInputHandler(e.target.value);
    },
    restrictContentToAccordion() {
      this.isRestrictContentToAccordion = !this.isRestrictContentToAccordion;
    },
    alignmentHandler(alignmentType) {
      this.isLeftAlign = alignmentType == "left" ? true : false;
      this.iscenterAlign = alignmentType == "center" ? true : false;
      this.isRightAlign = alignmentType == "right" ? true : false;

      this.draggable_editor_data[this.index][this.columnType][
        this.childIndex
      ].propertiesArray[
        this.column.properties.selectedVariationIndex
      ].container_style.textAlign = alignmentType;
    },

    autoWidthSet() {
      this.isAutoWidthCheck = !this.isAutoWidthCheck;

      if (!this.isAutoWidthCheck) {
        this.autoWidthValue = "100";
      }

      this.draggable_editor_data[this.index][this.columnType][
        this.childIndex
      ].propertiesArray[
        this.column.properties.selectedVariationIndex
      ].isAutoWidthCheck = this.isAutoWidthCheck;

      if (!this.isAutoWidthCheck) {
        this.draggable_editor_data[this.index][this.columnType][
          this.childIndex
        ].propertiesArray[
          this.column.properties.selectedVariationIndex
        ].style.width = "100%";
      }
      if (this.isAutoWidthCheck) {
        this.draggable_editor_data[this.index][this.columnType][
          this.childIndex
        ].propertiesArray[
          this.column.properties.selectedVariationIndex
        ].style.width = "auto";
      }
    },
    widthSlider(e) {
      this.autoWidthValue = Math.round(e.value);
      this.draggable_editor_data[this.index][this.columnType][
        this.childIndex
      ].propertiesArray[
        this.column.properties.selectedVariationIndex
      ].style.width = this.autoWidthValue + "%";
    },
    actionAccordion() {
      this.isActionAccordion = !this.isActionAccordion;
    },
    imageOptionsAccordion() {
      this.isImageOptionsAccordion = !this.isImageOptionsAccordion;
    },
    generalsAccordion() {
      this.isGeneralsAccordion = !this.isGeneralsAccordion;
    },
    responsiveDesignAccordion() {
      this.isResponsiveDesignAccordion = !this.isResponsiveDesignAccordion;
    },
  },
};
</script>

<style scoped></style>
