<template>
  <div>
    <div class="d-flex justify-content-between">
      <label class="b-form-label">{{ lebel }} Width</label>

      <!-- <checkbox :class="'b-form-label'" :label="'Auto Width'" :checked="isAutoWidthCheck"
        @change="autoWidthSet($event)" /> -->
    </div>
    <div class="d-flex justify-content-between">
      <slider :class="'w-100'" :buttons="false" :default-value="autoWidthValue" :disabled="isAutoWidthCheck"
        :value="autoWidthValue" :min="1" :max="100" @change="widthSlider($event)">
      </slider>
      <span class="font-16 ms-3">{{ Math.round(autoWidthValue) }}%</span>
    </div>
  </div>
</template>

<script>
import { Slider } from "@progress/kendo-vue-inputs";
import { Checkbox } from "@progress/kendo-vue-inputs";
export default {
  inject: ["draggable_editor_data"],
  name: "widthSliderComponent",
  components: {
    Slider,
    Checkbox,
  },
  props: {
    column: Object,
    index: Number,
    childIndex: Number,
    columnType: String,
    lebel: String,
  },
  data() {
    return {
      isAutoWidthCheck: false,
      autoWidthValue: 100,
    };
  },
  mounted() {
    this.isAutoWidthCheck =
      this.column.propertiesArray[
        this.column.properties.selectedVariationIndex
      ].isAutoWidthCheck;

    if (
      this.column.propertiesArray[this.column.properties.selectedVariationIndex]
        .style.width != "auto" &&
      !this.isAutoWidthCheck
    ) {
      this.autoWidthValue =
        this.column.propertiesArray[
          this.column.properties.selectedVariationIndex
        ].style.width.split("%")[0];
    }
  },
  methods: {
    autoWidthSet(e) {
      this.isAutoWidthCheck = !this.isAutoWidthCheck;
      if (!this.isAutoWidthCheck) {
        // this.autoWidthValue = "100";
      }

      this.draggable_editor_data[this.index][this.columnType][
        this.childIndex
      ].propertiesArray[
        this.column.properties.selectedVariationIndex
      ].isAutoWidthCheck = this.isAutoWidthCheck;
      if (!this.isAutoWidthCheck) {
        this.draggable_editor_data[this.index][this.columnType][
          this.childIndex
        ].propertiesArray[
          this.column.properties.selectedVariationIndex
        ].style.width = `${Math.round(this.autoWidthValue)}%`;
      }
      if (this.isAutoWidthCheck) {
        this.draggable_editor_data[this.index][this.columnType][
          this.childIndex
        ].propertiesArray[
          this.column.properties.selectedVariationIndex
        ].style.width = "auto";
      }
    },
    widthSlider(e) {
      this.autoWidthValue = Math.round(e.value);
      this.draggable_editor_data[this.index][this.columnType][
        this.childIndex
      ].propertiesArray[
        this.column.properties.selectedVariationIndex
      ].style.width = this.autoWidthValue + "%";
    },
  },
};
</script>

<style scoped>

</style>
