<template>
  <MultiSelect
    :data-item-key="dataItemKey"
    :text-field="textField"
    :placeholder="placeholder"
    @filterchange="onFilterChange"
    :filterable="filterable"
    :data-items="options"
    :disabled="disabled"
    :class="className"
    :value="modelValue"
    @change="onSelectChange"
  ></MultiSelect>
</template>

<script>
import { MultiSelect } from "@progress/kendo-vue-dropdowns";
import { filterBy } from "@progress/kendo-data-query";

export default {
  name: "AppMultiselect",
  components: {
    MultiSelect,
  },
  props: {
    modelValue: {
      type: Array,
      default: () => [],
      required: false,
    },
    textField: {
      type: String,
      default: "label",
      required: false,
    },
    dataItemKey: {
      type: String,
      default: "id",
      required: false,
    },
    dataItems: {
      type: Array,
      required: true,
    },
    placeholder: {
      type: String,
      default: "Select Option",
      required: false,
    },
    label: {
      type: String,
      default: "",
      required: false,
    },
    filterable: {
      type: Boolean,
      default: false,
    },
    closeOnSelect: {
      type: Boolean,
      default: false,
    },
    canDeselect: {
      type: Boolean,
      default: false,
    },
    canClear: {
      type: Boolean,
      default: false,
    },
    mode: String,
    placeholder: String,
    disabled: Boolean,
    className: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      selectedItems: [],
      options: this.dataItems,
    };
  },
  mounted() {
    this.selectedItems = this.modelValue;
  },
  watch: {
    dataItems: {
      handler: function (newVal) {
        this.options = newVal;
      },
      deep: true,
    },
    modelValue: {
      handler: function (newVal) {},
      deep: true,
    },
  },
  methods: {
    onFilterChange(e) {
      this.options = filterBy(this.dataItems.slice(), e.filter);
    },
    onSelectChange(e) {
      this.$emit("change", e);
    },
  },
};
</script>

<style>
.k-multiselect .k-chip-label {
  white-space: normal;
  text-overflow: normal;
  word-break: break-word;
}
</style>
