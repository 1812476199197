<template>
    <svg width="33" height="24" viewBox="0 0 33 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M32.334 3.2V0H0.333984V3.2H32.334ZM32.334 8.8V5.6H0.333984V8.8H32.334ZM32.334 24V11.2H0.333984V24H32.334Z" fill="#2B354C" fill-opacity="0.65"/>
    </svg>
</template>
<script>
    export default {
        name: "footerIcon"
    }
</script>
<style scoped></style>