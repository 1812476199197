<template>
  <div class="widget-accordion-wrap">
    <div :class="
          isOptionsAccordion
            ? 'widget-accordion-item show'
            : 'widget-accordion-item'
        ">
      <div @click="optionsAccordion()" class="widget-accordion-head">
        <span>Spacer</span>
        <span class="icon">
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M4.06066 11.0607L8.00003 7.12128L11.9394 11.0607L13 10L8.00003 5L3 10L4.06066 11.0607Z"
              fill="#24243A" />
          </svg>
        </span>
      </div>
      <div class="widget-accordion-body">
        <div class="widget-input-item spacer-height">
          <div class="d-flex justify-content-between align-items-center">
            <label class="b-form-label">Spacer Height</label>
            <div class="d-flex buttons">
              <button @click="minusButtonFrontChange($event)" class="k-button-solid-base border-0" title="Decrease">
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M3 7V9H13V7H3Z" fill="#6C707D" />
                </svg>
              </button>

              <KInput width="5px" type="number" @blur="debounceInput($event)" :min="0" v-model="styleHeightLocal" />
              <button @click="plusButtonFrontChange($event)" class="k-button-solid-base border-0" title="Increase">
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M9 7V3H7V7H3V9H7V13H9V9H13V7H9Z" fill="#6C707D" />
                </svg>
              </button>
            </div>
          </div>
        </div>
        <div class="widget-input-item">
          <div class="d-flex justify-content-between align-items-center">
            <label class="b-form-label mb-0">Background Color</label>
            <ColorPicker :gradient-settings="{ opacity: false, }" v-model="
                          column.propertiesArray[column.properties.selectedVariationIndex]
                            .style.backgroundColor
                        " />
          </div>
        </div>
        <!-- <div class="widget-input-item">
          <label class="b-form-label">Border Style</label>
          <div class="widget-input-item">
            <div class="d-flex align-items-center justify-content-between">
              <label class="b-form-label">Border <small>(PX)</small></label>
            </div>

            <select class="b-form-select" v-model="
              column.propertiesArray[column.properties.selectedVariationIndex]
                .borderStyles.borderStyle
            ">
              <option v-for="data in column.propertiesArray[
                column.properties.selectedVariationIndex
              ].borderStyleOptions" :key="data.value" :value="data.value">
                {{ data.label }}
              </option>
            </select>
          </div>
        </div> -->
        <!-- <div class="widget-input-item">
          <div class="d-flex justify-content-between align-items-center">
            <label class="b-form-label mb-0">Border color</label>
            <ColorPicker v-model="
              column.propertiesArray[column.properties.selectedVariationIndex]
                .borderStyles.borderColor
            " />
          </div>
        </div> -->
        <!-- <div class="widget-input-item">
          <borderWidthComponent :column="column" :columnType="columnType" :index="index" :childIndex="childIndex">
          </borderWidthComponent>
        </div> -->
      </div>
    </div>
    <div :class="
          isRestrictContentToAccordion
            ? 'widget-accordion-item show'
            : 'widget-accordion-item'
        ">
      <div @click="restrictContentToAccordion()" class="widget-accordion-head">
        <span>RESTRICT CONTENT TO</span>
        <span class="icon">
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M4.06066 11.0607L8.00003 7.12128L11.9394 11.0607L13 10L8.00003 5L3 10L4.06066 11.0607Z"
              fill="#24243A" />
          </svg>
        </span>
      </div>
      <RestrictContentToComponent :column="column" :columnType="columnType" :index="index" :childIndex="childIndex" />
    </div>
  </div>
</template>

<script>
import { ColorPicker } from "@progress/kendo-vue-inputs";
import { Switch, Input } from "@progress/kendo-vue-inputs";
import borderWidthComponent from "../../common-components/borderWidthComponent.vue";
import RestrictContentToComponent from "../../common-components/restrictContentToComponent.vue";
import { pxInputHandler } from "../../helpers/utility";

export default {
  inject: ["draggable_editor_data"],
  name: "spacerController",
  components: {
    ColorPicker,
    Switch,
    borderWidthComponent,
    KInput: Input,
    RestrictContentToComponent,
  },
  data() {
    return {
      isOptionsAccordion: true,
      isGeneralAccordion: true,
      isResponsiveDesignAccordion: true,
      isRestrictContentToAccordion: true,
      styleHeightLocal: 20,
    };
  },
  mounted() {
    this.styleHeightLocal =
      this.draggable_editor_data[this.index][this.columnType][
        this.childIndex
      ].propertiesArray[
        this.column.properties.selectedVariationIndex
      ].style.height.split("p")[0];
  },
  props: {
    column: Object,
    index: Number,
    childIndex: Number,
    columnType: String,
  },
  methods: {
    restrictContentToAccordion() {
      this.isRestrictContentToAccordion = !this.isRestrictContentToAccordion;
    },
    changeLineOrientation(
      index,
      childIndex,
      columnType,
      orientation,
      variationIndex
    ) {
      this.$emit(
        "changeLineOrientation",
        index,
        childIndex,
        columnType,
        orientation,
        variationIndex
      );
    },
    changeLineAlignment(
      index,
      childIndex,
      columnType,
      alignment,
      variationIndex
    ) {
      this.$emit(
        "changeLineAlignment",
        index,
        childIndex,
        columnType,
        alignment,
        variationIndex
      );
    },
    optionsAccordion() {
      this.isOptionsAccordion = !this.isOptionsAccordion;
    },
    generalAccordion() {
      this.isGeneralAccordion = !this.isGeneralAccordion;
    },
    responsiveDesignAccordion() {
      this.isResponsiveDesignAccordion = !this.isResponsiveDesignAccordion;
    },

    minusButtonFrontChange(e) {
      ;
      this.emitFunction("minusButtonFront", e);
    },

    plusButtonFrontChange(e) {
      this.emitFunction("plusButtonFront", e);
    },

    debounceInput(event) {
      this.emitFunction("marginInput", event);
    },

    emitFunction(type, e, isFirstValue, isSecondValue) {
      this.spacerControllerEmit(
        type,
        e,
        this.index,
        this.childIndex,
        this.columnType,
        isFirstValue,
        isSecondValue,
        this.column.properties.selectedVariationIndex
      );
    },

    spacerControllerEmit(
      type,
      e,
      index,
      childIndex,
      columnType,
      isFirstValue,
      isSecondValue,
      variationIndex
    ) {
      if (type == "RemoveLinks") {
        var isThere = false;
        this.draggable_editor_data[index][columnType][
          childIndex
        ].propertiesArray[variationIndex].mediaDataSeeMore.forEach(
          (mediaDataSeeMores) => {
            if (e.name == mediaDataSeeMores.name) {
              isThere = true;
            }
          }
        );
        if (!isThere) {
          this.draggable_editor_data[index][columnType][
            childIndex
          ].propertiesArray[variationIndex].mediaDataSeeMore.push(e);
        }
        this.draggable_editor_data[index][columnType][
          childIndex
        ].propertiesArray[variationIndex].mediaData.splice(isFirstValue, 1);
      }
      if (type == "addLinks") {
        var isThere = false;
        this.draggable_editor_data[index][columnType][
          childIndex
        ].propertiesArray[variationIndex].mediaDataSeeMore.forEach(
          (mediaDataSeeMores) => {
            if (e.name == mediaDataSeeMores.name) {
              isThere = true;
            }
          }
        );
        if (isThere) {
          this.draggable_editor_data[index][columnType][
            childIndex
          ].propertiesArray[variationIndex].mediaDataSeeMore.splice(
            isFirstValue,
            1
          );
          this.draggable_editor_data[index][columnType][
            childIndex
          ].propertiesArray[variationIndex].mediaData.push(e);
        }
      }
      if (type == "leftAlignmentButton") {
        this.draggable_editor_data[index][columnType][
          childIndex
        ].propertiesArray[variationIndex].container_style.textAlign = "left";
      }
      if (type == "centerAlignmentButton") {
        this.draggable_editor_data[index][columnType][
          childIndex
        ].propertiesArray[variationIndex].container_style.textAlign = "center";
      }
      if (type == "rightAlignmentButton") {
        this.draggable_editor_data[index][columnType][
          childIndex
        ].propertiesArray[variationIndex].container_style.textAlign = "right";
      }

      if (type == "minusButtonFront") {
        var onlyPxInt =
          this.draggable_editor_data[index][columnType][
            childIndex
          ].propertiesArray[variationIndex].style.height.split("p");
        this.draggable_editor_data[index][columnType][
          childIndex
        ].propertiesArray[variationIndex].style.height = onlyPxInt[0];
        this.draggable_editor_data[index][columnType][
          childIndex
        ].propertiesArray[variationIndex].style.height -= 1;
        if (
          this.draggable_editor_data[index][columnType][childIndex]
            .propertiesArray[variationIndex].style.height < 0
        ) {
          this.draggable_editor_data[index][columnType][
            childIndex
          ].propertiesArray[variationIndex].style.height = "16";
        }
        this.draggable_editor_data[index][columnType][
          childIndex
        ].propertiesArray[variationIndex].style.height =
          this.draggable_editor_data[index][columnType][childIndex]
            .propertiesArray[variationIndex].style.height + "px";
        this.styleHeightLocal =
          this.draggable_editor_data[index][columnType][
            childIndex
          ].propertiesArray[variationIndex].style.height.split("p")[0];
      }
      if (type == "plusButtonFront") {
        var onlyPxInt =
          this.draggable_editor_data[index][columnType][
            childIndex
          ].propertiesArray[variationIndex].style.height.split("p");
        this.draggable_editor_data[index][columnType][
          childIndex
        ].propertiesArray[variationIndex].style.height = parseInt(onlyPxInt[0]);
        this.draggable_editor_data[index][columnType][
          childIndex
        ].propertiesArray[variationIndex].style.height =
          this.draggable_editor_data[index][columnType][childIndex]
            .propertiesArray[variationIndex].style.height + 1;
        this.draggable_editor_data[index][columnType][
          childIndex
        ].propertiesArray[variationIndex].style.height =
          this.draggable_editor_data[index][columnType][childIndex]
            .propertiesArray[variationIndex].style.height + "px";
        this.styleHeightLocal =
          this.draggable_editor_data[index][columnType][
            childIndex
          ].propertiesArray[variationIndex].style.height.split("p")[0];
      }
      if (type == "marginInput") {
        this.draggable_editor_data[index][columnType][
          childIndex
        ].propertiesArray[variationIndex].style.height = pxInputHandler(
          e.target.value
        );
        this.styleHeightLocal =
          this.draggable_editor_data[index][columnType][
            childIndex
          ].propertiesArray[variationIndex].style.height.split("p")[0];
      }
    },
  },
};
</script>
