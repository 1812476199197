<template src="./PreviewAndTestComponent.html"></template>
<style lang="scss">
@import "./PreviewAndTestComponent.scss";
</style>

<script>
import { Button } from "@progress/kendo-vue-buttons";
import EmailTemplateMobileAndDesktopPreview from "../emailTemplateMobileAndDesktopPreview/EmailTemplateMobileAndDesktopPreview.vue";
import SendTestModalComponent from "./sendTestModalComponent/SendTestModalComponent.vue";
import { templateSendTestEmail } from "@/services/clubDetails/emailTemplate.service";
import AppSnackBar from "@/components/common/AppSnackBar/AppSnackBar.vue";
import AppLoader from "@/components/common/AppLoader/AppLoader.vue";

export default {
  name: "emailTemplatePreview",
  components: {
    "k-button": Button,
    EmailTemplateMobileAndDesktopPreview,
    SendTestModalComponent,
    AppSnackBar,
    AppLoader,
  },
  props: {
    draggable_editor_data: Array,
    global_style: Object,
    link_style: Object,
    templateData: Object,
    wrapperContainerStyle: Object,
    previewType: String,
    toggleModal: Function,
    clubId : String
  },
  data() {
    return {
      isLoading: false,
      templatePreviewType: "desktop",
      sendTestModalVisibility: false,
      campaignData: {},
      isCampaignSubjectLineViewMore: false,
      templateHtml: "",
      notifyMessage: "",
      notifyType: "",
      isNotify: false,
    };
  },
  mounted() {
    this.campaignData = JSON.parse(sessionStorage.getItem("emailCampaignData"));
    ;
  },
  methods: {
    catchTemplateHtml(html) {
      this.templateHtml = html;
    },
    onConfirmSendEmail(emails) {
      const jsonData = {
        draggable_editor_data: this.draggable_editor_data,
        global_style: this.global_style,
        link_style: this.link_style,
        wrapperContainerStyle: this.wrapperContainerStyle,
        templateData: this.templateData,
      };
      this.sendTestModalVisibility = false;
      this.isLoading = true;
      const payload = {
        receiverEmail: emails,
        senderEmail: this.campaignData.senderEmail,
        senderName: this.campaignData.senderName,
        previewText: this.campaignData.previewText,
        subject: this?.campaignData?.campaignSubject?.[0]?.subjectLine
          ? "[Test] " + this?.campaignData?.campaignSubject?.[0]?.subjectLine
          : "[Test] " + "Untitled subject",
        templateHtml: this.templateHtml,
        templateJsonObject: JSON.stringify(jsonData),
        clubId: this.clubId,
      };
      templateSendTestEmail(payload)
        .then((res) => {
          this.isLoading = false;
          this.showNotification("Email sent successfully", "success");
        })
        .catch((err) => {
          this.isLoading = false;
          this.showNotification("something went wrong", "error");
        });
    },
    toggleSubjectLineViewMore() {
      this.isCampaignSubjectLineViewMore = !this.isCampaignSubjectLineViewMore;
    },
    toggleView(type) {
      ;
      this.templatePreviewType = type;
    },
    doneButtonHandler() {
      this.toggleModal(false);
    },
    sendTestButtonHandler(type) {
      this.sendTestModalVisibility = type;
    },
    showNotification(message, type) {
      this.notifyMessage = message;
      this.notifyType = type;
      this.isNotify = true;
      setTimeout(
        () => (
          (this.notifyMessage = ""),
          (this.notifyType = "success"),
          (this.isNotify = false)
        ),
        4000
      );
    },
  },
};
</script>

<style scoped></style>
