<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M14 2V3H2V2H14ZM14 6H2V7H14V6ZM5 5H14V4H5V5ZM2 11V10H14V11H2ZM5 9H14V8H5V9ZM5 13V12H14V13H5Z"
      fill="#24243A"
    />
  </svg>
</template>
    <script>
export default {
  name: "alignmentRight",
};
</script>
    <style scoped></style>