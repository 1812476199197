<template>
  <div class="widget-accordion-wrap">
    <div :class="isVideoAccordion
          ? 'widget-accordion-item show'
          : 'widget-accordion-item'
          ">
      <div @click="videoAccordion()" class="widget-accordion-head">
        <span>Video</span>
        <span class="icon">
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M4.06066 11.0607L8.00003 7.12128L11.9394 11.0607L13 10L8.00003 5L3 10L4.06066 11.0607Z"
              fill="#24243A" />
          </svg>
        </span>
      </div>
      <div class="widget-accordion-body">
        <div class="widget-input-item">
          <label class="b-form-label">Video URL</label>
          <input type="text" class="b-form-field" placeholder="" @focusout="updateThumbnail(
                        index,
                        childIndex,
                        columnType,
                        column.propertiesArray[column.properties.selectedVariationIndex]
                          .videoUrl,
                        column.properties.selectedVariationIndex
                      )
                      " v-model="column.propertiesArray[column.properties.selectedVariationIndex]
                  .videoUrl
                " />
          <div class="mt-2">
            <small class="font-s-12">Add a YouTube or Vimeo URL to automatically generate a preview
              image. The image will link to the provided URL.</small>
          </div>
        </div>
        <div class="widget-input-item">
          <widthSliderComponent :column="column" :columnType="columnType" :index="index" :childIndex="childIndex" />
        </div>

        <div class="widget-input-item alignment">
          <alignmentComponent :column="column" :columnType="columnType" :index="index" :childIndex="childIndex" />
        </div>
      </div>
    </div>
    <!-- Widget Accordion item-->
    <div :class="isGeneralAccordion
            ? 'widget-accordion-item show'
            : 'widget-accordion-item'
          ">
      <div @click="generalAccordion()" class="widget-accordion-head">
        <span>GENERAL</span>
        <span class="icon">
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M4.06066 11.0607L8.00003 7.12128L11.9394 11.0607L13 10L8.00003 5L3 10L4.06066 11.0607Z"
              fill="#24243A" />
          </svg>
        </span>
      </div>
      <generalAccordionComponent :column="column" :columnType="columnType" :index="index" :childIndex="childIndex" />
    </div>
    <div :class="isRestrictContentToAccordion
            ? 'widget-accordion-item show'
            : 'widget-accordion-item'
          ">
      <div @click="restrictContentToAccordion()" class="widget-accordion-head">
        <span>RESTRICT CONTENT TO</span>
        <span class="icon">
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M4.06066 11.0607L8.00003 7.12128L11.9394 11.0607L13 10L8.00003 5L3 10L4.06066 11.0607Z"
              fill="#24243A" />
          </svg>
        </span>
      </div>
      <RestrictContentToComponent :column="column" :columnType="columnType" :index="index" :childIndex="childIndex" />
    </div>
  </div>
</template>

<script>
import { Button } from "@progress/kendo-vue-buttons";
import { Switch } from "@progress/kendo-vue-inputs";
import widthSliderComponent from "../../common-components/widthSliderComponent.vue";
import alignmentComponent from "../../common-components/alignmentComponent.vue";
import generalAccordionComponent from "../../common-components/generalAccordionComponent.vue";
import RestrictContentToComponent from "../../common-components/restrictContentToComponent.vue";
import axios from "axios";
import { configuration } from "@/configurationProvider";

export default {
  inject: ["draggable_editor_data"],
  name: "videoController",
  components: {
    "k-button": Button,
    Switch,
    widthSliderComponent,
    alignmentComponent,
    generalAccordionComponent,
    RestrictContentToComponent,
  },
  data() {
    return {
      isVideoAccordion: true,
      isGeneralAccordion: true,
      isResponsiveDesignAccordion: true,
      isRestrictContentToAccordion: true,
    };
  },
  props: {
    column: Object,
    index: Number,
    childIndex: Number,
    columnType: String,
  },
  methods: {
    restrictContentToAccordion() {
      this.isRestrictContentToAccordion = !this.isRestrictContentToAccordion;
    },
    updateThumbnail(index, childIndex, columnType, url, variationIndex) {
      ;
      try {
        if (url) {
          if (
            url.includes("https://www.youtube.com") ||
            url.includes("http://www.youtube.com") ||
            url.includes("https://vimeo.com") ||
            url.includes("http://vimeo.com")
          ) {
            this.draggable_editor_data[index][columnType][
              childIndex
            ].propertiesArray[variationIndex].isLoading = true

            axios({
              url: `${configuration.apiBaseUrl}/api/v1/Document/vedio-play-url?vedioPath=${url}`,
              method: "GET",
            })
              .then((response) => {
                this.draggable_editor_data[index][columnType][
                  childIndex
                ].propertiesArray[variationIndex].videoThumbnail = response?.data?.data?.url;

                this.draggable_editor_data[index][columnType][
                  childIndex
                ].propertiesArray[variationIndex].isLoading = false
              })
              .catch((error) => {

                this.draggable_editor_data[index][columnType][
                  childIndex
                ].propertiesArray[variationIndex].videoThumbnail =
                  "https://cdn.tools.unlayer.com/video/placeholder.png";

                this.draggable_editor_data[index][columnType][
                  childIndex
                ].propertiesArray[variationIndex].isLoading = false
              });
          } else {
            this.draggable_editor_data[index][columnType][
              childIndex
            ].propertiesArray[variationIndex].videoThumbnail =
              "https://cdn.tools.unlayer.com/video/placeholder.png";
          }
        } else {
          this.draggable_editor_data[index][columnType][
            childIndex
          ].propertiesArray[variationIndex].videoThumbnail =
            "https://cdn.tools.unlayer.com/video/placeholder.png";
        }
      } catch (e) {
        throw e;
      }
    },
    getVimeoIdByUrl(url) {
      let splitter = url.split("/");
      return splitter[3];
    },
    videoAccordion() {
      this.isVideoAccordion = !this.isVideoAccordion;
    },
    generalAccordion() {
      this.isGeneralAccordion = !this.isGeneralAccordion;
    },
    responsiveDesignAccordion() {
      this.isResponsiveDesignAccordion = !this.isResponsiveDesignAccordion;
    },
  },
};
</script>
