<template>
  <div class="content-properties">
    <span class="block-name">{{ blockName }}</span>
    <div class="move-handler drag-handle">
      <button class="move-btn '">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M12 24L7.11 19.11L8.43 17.79L11.1 20.46V14.79H12.9V20.46L15.57 17.79L16.89 19.11L12 24ZM4.74 16.74L0 12L4.77 7.23L6.09 8.55L3.54 11.1H9.21V12.9H3.54L6.06 15.42L4.74 16.74ZM19.26 16.74L17.94 15.42L20.46 12.9H14.82V11.1H20.46L17.94 8.58L19.26 7.26L24 12L19.26 16.74ZM11.1 9.18V3.54L8.58 6.06L7.26 4.74L12 0L16.74 4.74L15.42 6.06L12.9 3.54V9.18H11.1Z"
            fill="white" />
        </svg>
      </button>
    </div>
    <div class="action-btn">
      <button @click="deleteHandle(targetElement)" class="btn delete-btn" title="Delete">
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd"
            d="M13 3H10V2C10 1.45 9.55 1 9 1H6C5.45 1 5 1.45 5 2V3H2L2 5H3L3 14C3 14.55 3.45 15 4 15H11C11.55 15 12 14.55 12 14V5H13V3ZM6 3C6.00312 2.99688 6.00313 2.00311 6.00003 2H8.99687L9 2.00312V3L6.00313 3H6ZM4.00312 14H11V5H10V13H9V5H8L8 13H7L7 5H6V13H5V5H4L4 13.9969L4.00312 14Z"
            fill="white" />
        </svg>
      </button>
      <button @click="
        duplicateHandle(targetElement, targetIndex, targetType, childIndex)
      " class="btn duplicate-btn" title="Duplicate">
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd"
            d="M4 12H2L2 2H9L8 1L2 1C1.44687 1 1 1.44687 1 2L1 12C1 12.5531 1.44687 13 2 13H4V12ZM15 6L12 3L6 3C5.44688 3 5 3.44688 5 4V14C5 14.5531 5.44688 15 6 15H14C14.5531 15 15 14.5531 15 14V6ZM14 14H6L6 4H11V7L14 7V14Z"
            fill="white" />
        </svg>
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "builderLayerProperties",
  props: {
    blockName: String,
    targetElement: Number,
    targetIndex: Number,
    targetType: String,
    childIndex: Number,
  },
  methods: {
    deleteHandle(data) {
      this.$emit("deleteHandle", data);
    },
    duplicateHandle(targetElement, index, type, childIndex) {
      this.$emit("duplicateHandle", targetElement, index, type, childIndex);
    },
  },
};
</script>

<style scoped></style>
