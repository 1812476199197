<template>
  <div class="d-flex justify-content-between align-items-center">
    <label class="b-form-label mb-0">Font Size</label>
    <div class="d-flex buttons">
      <button
        @click="minusButtonFrontChange($event)"
        class="k-button-solid-base border-0"
        title="Decrease"
      >
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M3 7V9H13V7H3Z" fill="#6C707D" />
        </svg>
      </button>
      <KInput
        width="5px"
        type="number"
        @blur="debounceInput($event)"
        :min="0"
        v-model="
          this.draggable_editor_data[this.index][this.columnType][
            this.childIndex
          ].propertiesArray[this.column.properties.selectedVariationIndex][
            this.stateKey
          ]
        "
      />
      <button
        @click="plusButtonFrontChange($event)"
        class="k-button-solid-base border-0"
        title="Increase"
      >
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M9 7V3H7V7H3V9H7V13H9V9H13V7H9Z" fill="#6C707D" />
        </svg>
      </button>
    </div>
  </div>
</template>
  
<script>
import { Input } from "@progress/kendo-vue-inputs";

export default {
  inject: ["draggable_editor_data"],
  name: "alignmentComponent",
  components: {
    KInput: Input,
  },
  props: {
    column: Object,
    index: Number,
    childIndex: Number,
    columnType: String,
    styleCssClass: Array,
    stateKey: {
      type: String,
      default: "",
    },
    label: {
      type: String,
      default: "",
    },
  },
  data() {
    return {};
  },
  mounted() {},
  methods: {
    debounceInput(event) {
      let value = event.target.value;
      this.draggable_editor_data[this.index][this.columnType][
        this.childIndex
      ].propertiesArray[this.column.properties.selectedVariationIndex][
        this.stateKey
      ] = value;
    },
    minusButtonFrontChange(event) {
      let value =
        this.draggable_editor_data[this.index][this.columnType][this.childIndex]
          .propertiesArray[this.column.properties.selectedVariationIndex][
          this.stateKey
        ];
      if (value > 0) {
        value = parseInt(value) - 1;
        this.draggable_editor_data[this.index][this.columnType][
          this.childIndex
        ].propertiesArray[this.column.properties.selectedVariationIndex][
          this.stateKey
        ] = value;
      }
    },
    plusButtonFrontChange(event) {
      let value =
        this.draggable_editor_data[this.index][this.columnType][this.childIndex]
          .propertiesArray[this.column.properties.selectedVariationIndex][
          this.stateKey
        ];

      value = parseInt(value) + 1;

      this.draggable_editor_data[this.index][this.columnType][
        this.childIndex
      ].propertiesArray[this.column.properties.selectedVariationIndex][
        this.stateKey
      ] = value;
    },
  },
};
</script>
<style scoped></style>
    