<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M2 3H14V2H2V3ZM2 4H11V5H2V4ZM14 6H2V7H14V6ZM2 8H11V9H2V8ZM14 10H2V11H14V10ZM2 12H11V13H2V12Z"
      fill="#24243A"
    />
  </svg>
</template>
  <script>
export default {
  name: "alignmentLeft",
};
</script>
  <style scoped></style>