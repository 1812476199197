<template>
    <svg width="32" height="28" viewBox="0 0 32 28" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M12 9C12 9.79565 11.6839 10.5587 11.1213 11.1213C10.5587 11.6839 9.79565 12 9 12C8.20435 12 7.44129 11.6839 6.87868 11.1213C6.31607 10.5587 6 9.79565 6 9C6 8.20435 6.31607 7.44129 6.87868 6.87868C7.44129 6.31607 8.20435 6 9 6C9.79565 6 10.5587 6.31607 11.1213 6.87868C11.6839 7.44129 12 8.20435 12 9Z" fill="#2B354C" fill-opacity="0.65"/>
        <path d="M4 0C2.93913 0 1.92172 0.421428 1.17157 1.17157C0.421427 1.92172 0 2.93914 0 4V24C0 25.0609 0.421427 26.0783 1.17157 26.8284C1.92172 27.5786 2.93913 28 4 28H28C29.0609 28 30.0783 27.5786 30.8284 26.8284C31.5786 26.0783 32 25.0609 32 24V4C32 2.93914 31.5786 1.92172 30.8284 1.17157C30.0783 0.421428 29.0609 0 28 0H4ZM28 2C28.5304 2 29.0391 2.21071 29.4142 2.58579C29.7893 2.96086 30 3.46957 30 4V17L22.446 13.106C22.2584 13.0121 22.0461 12.9795 21.839 13.0128C21.6319 13.0462 21.4405 13.1439 21.292 13.292L13.872 20.712L8.552 17.168C8.35992 17.0401 8.12952 16.9826 7.89988 17.0052C7.67023 17.0278 7.45547 17.1291 7.292 17.292L2 22V4C2 3.46957 2.21071 2.96086 2.58579 2.58579C2.96086 2.21071 3.46957 2 4 2H28Z" fill="#2B354C" fill-opacity="0.65"/>
    </svg>
</template>
<script>
    export default {
        name: "imageIcon"
    }
</script>
<style scoped></style>