<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M2 2H14V3H2V2ZM2 5H14V4H2V5ZM2 11H14V10H2V11ZM14 9H2V8H14V9ZM2 7H14V6H2V7ZM14 13H2V12H14V13Z"
      fill="#24243A"
    />
  </svg>
</template>
      <script>
export default {
  name: "alignmentCenter",
};
</script>
      <style scoped></style>