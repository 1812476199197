<modal :title="modalDetails?.title||'Test Email'" :handler="true" :cssClass="'modal-md-5 content-px-0'" :footer="false"
    @modalHandler="onCancel(false)">
    <template v-slot:content>
        <div class="px-16 pb-4">
            <label>Send test email to <span class="red">*</span></label>
            <textarea v-model="testEmails" placeholder="email@example.com, john@xyz.com"
                class="w-100 h-108 py-6 px-12 border-normal-1 font-16 border-radius-2"></textarea>
            <p class="font-12">Separate multiple email addresses with commas.</p>
        </div>
        <dialog-actions-bar>
            <div class="d-flex justify-content-end mt-2">
                <div class="me-1">
                    <kbutton @click="onCancel(false)" :class="'w-100'">Cancel</kbutton>
                </div>
                <div class="ms-1">
                    <kbutton @click="sendEmailHandler" :theme-color="'primary'" :class="'w-100 h-38'">Send Email
                    </kbutton>
                </div>
            </div>
        </dialog-actions-bar>
    </template>
</modal>

<AppSnackBar :message="notifyMessage" :notificationType="notifyType" v-if="isNotify"></AppSnackBar>
<AppLoader v-if="isLoading" :type="'pulsing'"></AppLoader>