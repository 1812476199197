<template>
  <div class="widget-accordion-body">
    <div class="widget-input-item">
      <div class="d-flex justify-content-between align-items-center">
        <label class="b-form-label mb-0">Background Color</label>
        <ColorPicker
          :gradient-settings="{ opacity: false }"
          v-model="row.properties.color_properties.backgroundColor"
        />
      </div>
    </div>
    <div class="widget-input-item">
      <label class="b-form-label">Image</label>
      <EditorFileUploader
        v-model="row.properties.style.src"
        :index="index"
        :isFrom="'rowProperty'"
      />
    </div>
    <div class="widget-input-item">
      <label class="b-form-label">Image URL</label>
      <input
        type="text"
        v-model="row.properties.style.src"
        class="b-form-field"
      />
    </div>
    <div class="widget-input-item">
      <div class="container-padding">
        <label class="b-form-label">Padding</label>
        <div :class="isRowPaddingLock ? 'inputs-button lock' : 'inputs-button'">
          <div class="inputs" v-if="!isRowPaddingLock">
            <input
              type="text"
              class="b-form-field"
              placeholder="5px"
              @focusout="RowPaddingTopWidthHandler($event)"
              v-model="row.properties.style.paddingTop"
            />
            <input
              type="text"
              class="b-form-field"
              placeholder="5px"
              @focusout="RowPaddingRightWidthHandler($event)"
              v-model="row.properties.style.paddingRight"
            />
            <input
              type="text"
              class="b-form-field"
              placeholder="5px"
              @focusout="RowPaddingBottomWidthHandler($event)"
              v-model="row.properties.style.paddingBottom"
            />
            <input
              type="text"
              class="b-form-field"
              placeholder="5px"
              @focusout="RowPaddingLeftWidthHandler($event)"
              v-model="row.properties.style.paddingLeft"
            />
          </div>
          <div class="inputs" v-if="isRowPaddingLock">
            <input
              @focusout="RowPaddingChangeFunction($event)"
              v-model="isRowPaddingLockData"
              type="text"
              class="b-form-field"
              placeholder="5px"
            />
          </div>

          <button
            @click="onClickLockBtn($event)"
            title="Lock"
            :class="isRowPaddingLock ? 'lock' : ''"
          >
            <span v-if="!isRowPaddingLock">
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M7 7H13C13.55 7 14 7.45 14 8V14C14 14.55 13.55 15 13 15H5C4.45 15 4 14.55 4 14V11V8C4 7.45 4.45 7 5 7H6V4C6 2.89688 5.10313 2 4 2C2.89688 2 2 2.89688 2 4V7V8H1V4C1 2.34375 2.34375 1 4 1C5.65625 1 7 2.34375 7 4V7ZM8 11C8 11.5531 8.44688 12 9 12C9.55313 12 10 11.5531 10 11C10 10.4469 9.55313 10 9 10C8.44688 10 8 10.4469 8 11Z"
                  fill="#404A61"
                />
              </svg>
            </span>
            <span v-if="isRowPaddingLock">
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M12 7H11V4C11 2.34375 9.65625 1 8 1C6.34375 1 5 2.34375 5 4V7H4C3.45 7 3 7.45 3 8V14C3 14.55 3.45 15 4 15H12C12.55 15 13 14.55 13 14V8C13 7.45 12.55 7 12 7ZM6 4C6 2.89688 6.89687 2 8 2C9.10313 2 10 2.89688 10 4V7H6V4ZM7 11C7 11.5531 7.44688 12 8 12C8.55312 12 9 11.5531 9 11C9 10.4469 8.55312 10 8 10C7.44688 10 7 10.4469 7 11Z"
                  fill="#404A61"
                />
              </svg>
            </span>
          </button>
        </div>
        <div class="inputs-buttons-name d-flex" v-if="!isRowPaddingLock">
          <span>Top</span>
          <span>Right</span>
          <span>Bottom</span>
          <span>Left</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ColorPicker } from "@progress/kendo-vue-inputs";
import EditorFileUploader from "../../common-components/EditorFileUploader.vue";
import { pxInputHandler } from "../../helpers/utility";
export default {
  inject: ["draggable_editor_data"],
  name: "blockRowControllers",
  components: {
    ColorPicker,
    EditorFileUploader,
  },
  props: {
    row: Object,
    index: Number,
  },
  data() {
    return {
      isRowPaddingLock: false,
      isRowPaddingLockData: "",
    };
  },
  mounted() {
    this.isRowPaddingLock = this.draggable_editor_data[
      this.index
    ]?.properties?.style?.isPaddingLock;

    if (this.isRowPaddingLock)
      this.isRowPaddingLockData = this.draggable_editor_data[
        this.index
      ]?.properties?.style?.paddingTop;
  },
  methods: {
    onClickLockBtn(e) {
      this.isRowPaddingLock = !this.isRowPaddingLock;
      this.paddingOnClickLockBtn(e);
    },
    paddingOnClickLockBtn() {
      this.draggable_editor_data[
        this.index
      ].properties.style.isPaddingLock = this.isRowPaddingLock;

      if (this.draggable_editor_data[this.index].properties.style.paddingTop) {
        this.isRowPaddingLockData = this.draggable_editor_data[
          this.index
        ]?.properties?.style?.paddingTop;
        this.draggable_editor_data[this.index].properties.style.paddingTop;
        this.draggable_editor_data[
          this.index
        ].properties.style.paddingRight = this.draggable_editor_data[
          this.index
        ].properties.style.paddingTop;
        this.draggable_editor_data[
          this.index
        ].properties.style.paddingBottom = this.draggable_editor_data[
          this.index
        ].properties.style.paddingTop;
        this.draggable_editor_data[
          this.index
        ].properties.style.paddingLeft = this.draggable_editor_data[
          this.index
        ].properties.style.paddingTop;
      } else {
        this.draggable_editor_data[this.index].properties.style.paddingTop =
          "0px";
        this.draggable_editor_data[this.index].properties.style.paddingRight =
          "0px";
        this.draggable_editor_data[this.index].properties.style.paddingBottom =
          "0px";
        this.draggable_editor_data[this.index].properties.style.paddingLeft =
          "0px";
      }
    },
    RowPaddingChangeFunction(e) {
      this.RowPaddingLockHandler(e);
    },
    RowPaddingLockHandler(e) {
      this.isRowPaddingLockData = pxInputHandler(e.target.value);
      this.draggable_editor_data[
        this.index
      ].properties.style.paddingTop = pxInputHandler(e.target.value);
      this.draggable_editor_data[
        this.index
      ].properties.style.paddingRight = pxInputHandler(e.target.value);
      this.draggable_editor_data[
        this.index
      ].properties.style.paddingBottom = pxInputHandler(e.target.value);
      this.draggable_editor_data[
        this.index
      ].properties.style.paddingLeft = pxInputHandler(e.target.value);
      // this.draggable_editor_data[this.index].properties.style.textAlign =
      //   "center";
    },
    RowPaddingLeftWidthHandler(e) {
      this.draggable_editor_data[
        this.index
      ].properties.style.paddingLeft = pxInputHandler(e.target.value);
    },
    RowPaddingBottomWidthHandler(e) {
      this.draggable_editor_data[
        this.index
      ].properties.style.paddingBottom = pxInputHandler(e.target.value);
    },
    RowPaddingRightWidthHandler(e) {
      this.draggable_editor_data[
        this.index
      ].properties.style.paddingRight = pxInputHandler(e.target.value);
    },
    RowPaddingTopWidthHandler(e) {
      this.draggable_editor_data[
        this.index
      ].properties.style.paddingTop = pxInputHandler(e.target.value);
    },
  },
};
</script>

<style scoped></style>
