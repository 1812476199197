<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M4 3H7.61875C8.40938 3 9 3.03438 9.3875 3.1C9.775 3.16563 10.125 3.30312 10.4313 3.5125C10.7375 3.72187 10.9937 4 11.2 4.34687C11.4062 4.69375 11.5062 5.08437 11.5062 5.51562C11.5062 5.98438 11.3781 6.4125 11.1281 6.80312C10.875 7.19375 10.5312 7.4875 10.1 7.68437C10.7094 7.8625 11.1781 8.16562 11.5062 8.59062C11.8344 9.01562 11.9969 9.52188 11.9969 10.0969C11.9969 10.5531 11.8906 10.9938 11.6781 11.4219C11.4688 11.8531 11.1781 12.1969 10.8125 12.4531C10.4469 12.7094 9.99687 12.8688 9.45937 12.9281C9.12187 12.9656 8.3125 12.9875 7.02187 12.9969H4V3ZM6 4.66562V6.97813H6.96563C7.75312 6.97813 8.24062 6.96563 8.43125 6.94375C8.77813 6.90313 9.04688 6.78125 9.24687 6.58437C9.44375 6.3875 9.54375 6.125 9.54375 5.80312C9.54375 5.49375 9.45937 5.24062 9.2875 5.05C9.11562 4.85625 8.8625 4.7375 8.525 4.7C8.325 4.67813 7.75 4.66562 6.8 4.66562H6ZM6 11.3188V8.64375L7.27187 8.64062C8.19063 8.64062 8.7875 8.6875 9.0625 8.78438C9.3375 8.87813 9.54688 9.03125 9.69375 9.24062C9.84062 9.45 9.9125 9.70625 9.9125 10.0062C9.9125 10.3625 9.82188 10.6438 9.63125 10.8562C9.44062 11.0688 9.19688 11.2 8.89687 11.2563C8.7 11.2969 8.24062 11.3188 7.5125 11.3188H6Z"
      fill="#24243A"
    />
  </svg>
</template>
    <script>
export default {
  name: "boldIcon",
};
</script>

<style scoped></style>