<template>
    <svg width="29" height="32" viewBox="0 0 29 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_7230_103642)">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M28.667 18V14H0.666992L0.666992 18H28.667ZM14.6231 24V20H16.667V24H20.667L15.667 32L10.667 24H14.6231ZM10.667 8L15.667 0L20.667 8H16.7109V12H14.667V8H10.667Z" fill="#2B354C" fill-opacity="0.65"/>
        </g>
        <defs>
        <clipPath id="clip0_7230_103642">
        <rect width="32" height="28" fill="white" transform="translate(0.666992 32) rotate(-90)"/>
        </clipPath>
        </defs>
    </svg>        
</template>
<script>
    export default {
        name: "spacerIcon"
    }
</script>
<style scoped></style>