<template>
  <div class="widget-accordion-wrap">
    <!-- Widget Accordion item-->
    <div
      :class="
        isProductAccordionVisible
          ? 'widget-accordion-item show'
          : 'widget-accordion-item'
      "
    >
      <div
        @click="toggleAccordion('isProductAccordionVisible')"
        class="widget-accordion-head"
      >
        <span>Product</span>
        <span class="icon">
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M4.06066 11.0607L8.00003 7.12128L11.9394 11.0607L13 10L8.00003 5L3 10L4.06066 11.0607Z"
              fill="#24243A"
            />
          </svg>
        </span>
      </div>
      <div class="widget-accordion-body">
        <div class="widget-input-item widget-editor input-link-custom-modal">
          <!-- do here -->
          <label class="b-form-label">Content</label>
          <div class="common-inner-tab">
            <div class="product-tab-buttons d-flex gap-2 mb-12">
              <div
                class="w-100 p-7 radius-6 cursor-pointer d-flex justify-content-center align-items-center bg-gray-3"
                @click="onClickTabChange('Static')"
                :class="{ active: activeTab === 'Static' }"
              >
                <span class="me-1">
                  <TickIcon />
                </span>
                <p class="font-14 l-height-1 font-w-600 base-color mb-0">
                  Static
                </p>
              </div>
              <div
                class="w-100 p-7 radius-6 cursor-pointer d-flex justify-content-center align-items-center bg-gray-3"
                @click="onClickTabChange('Dynamic')"
                :class="{ active: activeTab === 'Dynamic' }"
              >
                <span class="me-1">
                  <TickIcon />
                </span>
                <p class="font-14 l-height-1 font-w-600 base-color mb-0">
                  Dynamic
                </p>
              </div>
            </div>
            <div class="tab-content">
              <div v-if="activeTab === 'Static'">
                <div class="product-info-tabs">
                  <div
                    v-for="(product, index) in this.draggable_editor_data[
                      this.index
                    ][this.columnType][this.childIndex].propertiesArray[
                      this.column.properties.selectedVariationIndex
                    ].productsList"
                    :key="product?.id"
                    class="product-info-tab-single border-normal-1 px-16 py-13 mb-12 radius-6"
                    :class="product?.isVisible ? 'active' : 'inactive'"
                  >
                    <div
                      class="product-tab-head d-flex justify-content-start align-items-center"
                    >
                      <span
                        @click="handleProductVisibility(index)"
                        class="title-text cursor-pointer font-14 font-w-600"
                        >{{ product?.name }}</span
                      >
                      <span
                        @click="onClickDeleteProduct(index)"
                        class="remove-btn ms-auto cursor-pointer"
                      >
                        <svg
                          width="11"
                          height="14"
                          viewBox="0 0 11 14"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M11 2H8V1C8 0.45 7.55 0 7 0H4C3.45 0 3 0.45 3 1V2H0V4H1V13C1 13.55 1.45 14 2 14H9C9.55 14 10 13.55 10 13V4H11V2ZM4 2C4.00312 1.99688 4.00313 1.00311 4.00003 1H6.99687L7 1.00312V2H4.00313H4ZM2.00312 13H9V4H8V12H7V4H6V12H5V4H4V12H3V4H2V12.9969L2.00312 13Z"
                            fill="#24243A"
                          />
                        </svg>
                      </span>
                      <span
                        @click="handleProductVisibility(index)"
                        class="dropdown-icon ms-4"
                      >
                        <svg
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M4.93934 4.06066L8.87872 8.00003L4.93934 11.9394L6 13L11 8.00003L6 3L4.93934 4.06066Z"
                            fill="#24243A"
                          />
                        </svg>
                      </span>
                    </div>
                    <div v-if="product?.isVisible" class="product-tab-content">
                      <div class="product-tab-product mt-12">
                        <span
                          class="d-inline-block border-normal-1 h-96 w-96 radius-5 mb-12"
                        >
                          <img :src="product?.image" loading="lazy" alt="" />
                        </span>
                      </div>
                      <div class="k-form-field text-start">
                        <label>Product name</label>
                        <k-input
                          :class="'mb-12'"
                          v-model="product.name"
                          :placeholder="'The Videographer Snowboard'"
                        />
                      </div>
                      <div class="k-form-field text-start">
                        <label>Price</label>
                        <k-input
                          :class="'mb-12'"
                          v-model="product.basePrice"
                          :placeholder="'885.95'"
                        />
                      </div>
                      <div class="k-form-field text-start">
                        <label>Product link address</label>
                        <k-input
                          v-model="product.url"
                          :class="'mb-12'"
                          :placeholder="'https://testify-solutions.myshopify.com/products/the-videographer-snowboard'"
                        />
                      </div>
                      <div
                        v-if="activeTab == 'Static'"
                        class="k-form-field text-start"
                      >
                        <label>Description</label>
                        <textarea
                          placeholder="This is a text cell."
                          class="w-100 h-90 py-6 px-12 border-normal-1 font-16 border-radius-2"
                          v-model="product.custom_description"
                        ></textarea>
                      </div>
                    </div>
                  </div>
                </div>
                <kbutton
                  :class="'mb-21'"
                  :size="'medium'"
                  :theme-color="'primary'"
                  :fill-mode="'outline'"
                  @click="handleModalControl('browseProductModal', true)"
                >
                  Add Products
                </kbutton>
              </div>
              <div v-if="activeTab === 'Dynamic'">
                <div
                  class="d-flex justify-content-between align-items-center mb-24"
                >
                  <kbutton
                    @click="handleModalControl('productListsModal', true)"
                    :class="'me-2'"
                    :theme-color="'primary'"
                    :fill-mode="'outline'"
                  >
                    See all product list
                  </kbutton>
                  <!-- <p class="font-14 mb-0">Recent Product lists</p> -->
                  <kbutton
                    @click="handleModalControl('createProductListModal', true)"
                    :class="'me-2'"
                  >
                    Create product list
                  </kbutton>
                </div>
                <p class="font-14 mb-0">Product list</p>
                <div
                  v-if="
                    this.draggable_editor_data[this.index][this.columnType][
                      this.childIndex
                    ].propertiesArray[
                      this.column.properties.selectedVariationIndex
                    ].dynamicProductsList?.id
                  "
                  class="product-selectors mb-24"
                >
                  <div
                    class="product-selector-single py-8 px-16 border-normal-1 radius-6 d-flex justify-content-start align-items-center mb-12"
                  >
                    <div class="min-w-32">
                      <radiobutton
                        :size="'large'"
                        :name="'group1'"
                        :value="'first'"
                        :checked="true"
                      />
                    </div>
                    <div>
                      <p class="font-12 l-height-20 m-0">
                        <b class="d-block">{{
                          this.draggable_editor_data[this.index][
                            this.columnType
                          ][this.childIndex].propertiesArray[
                            this.column.properties.selectedVariationIndex
                          ].dynamicProductsList?.parameterName
                        }}</b>
                      </p>
                    </div>
                    <!-- <div class="min-w-32 text-right ms-auto">
                      <kbutton
                        :class="'p-0 m-0'"
                        :fill-mode="'flat'"
                        :icon="'delete'"
                        @click="removeDynamicProductList(index, item)"
                      ></kbutton>
                    </div> -->
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="product-details p-16 pb-0 border-normal-1 radius-6">
            <p class="font-14 font-w-600 color-black">
              Show these product details:
            </p>
            <div
              v-for="item in Object.keys(
                this.draggable_editor_data[this.index][this.columnType][
                  this.childIndex
                ].propertiesArray[this.column.properties.selectedVariationIndex]
                  .itemsVisibility
              )"
              :key="item"
            >
              <div
                v-if="
                  this.draggable_editor_data[this.index][this.columnType][
                    this.childIndex
                  ].propertiesArray[
                    this.column.properties.selectedVariationIndex
                  ].itemsVisibility[item]?.tab?.includes(activeTab)
                "
                class="d-flex align-items-center mb-18"
              >
                <div>
                  <h3 class="font-16 mb-0">
                    {{
                      this.draggable_editor_data[this.index][this.columnType][
                        this.childIndex
                      ].propertiesArray[
                        this.column.properties.selectedVariationIndex
                      ].itemsVisibility[item]?.text
                    }}
                  </h3>
                </div>
                <div class="ms-auto mb-2 mb-sm-0 w-100-vsm">
                  <k-switch
                    v-model="
                      this.draggable_editor_data[this.index][this.columnType][
                        this.childIndex
                      ].propertiesArray[
                        this.column.properties.selectedVariationIndex
                      ].itemsVisibility[item].isVisible
                    "
                    @change="handleTextItemVisibility"
                    :size="'small'"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Widget Accordion item Layout-->
    <div
      :class="
        isLayoutAccordion
          ? 'widget-accordion-item show'
          : 'widget-accordion-item'
      "
    >
      <div
        @click="toggleAccordion('isLayoutAccordion')"
        class="widget-accordion-head"
      >
        <span>Layout</span>
        <span class="icon">
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M4.06066 11.0607L8.00003 7.12128L11.9394 11.0607L13 10L8.00003 5L3 10L4.06066 11.0607Z"
              fill="#24243A"
            />
          </svg>
        </span>
      </div>
      <div class="widget-accordion-body">
        <div class="widget-input-item orientation border-bottom-0 mb-0">
          <customOptionComponent
            :column="column"
            :columnType="columnType"
            :index="index"
            :childIndex="childIndex"
            :multiSelect="false"
            :options="orientationOptions"
            :parentKey="'layoutOrientation'"
            :label="'Orientation'"
          />
        </div>
        <div class="widget-input-item alignment border-bottom-0 mb-0">
          <alignmentComponent
            :column="column"
            :columnType="columnType"
            :index="index"
            :childIndex="childIndex"
          />
        </div>
        <div class="widget-input-item border-bottom-0 mb-0">
          <div class="d-flex justify-content-between align-items-center">
            <label class="b-form-label mb-0">Products per row</label>

            <dropdownlist
              :class="'w-78'"
              :data-items="productsPerRow"
              :disabled="
                this.draggable_editor_data[this.index][this.columnType][
                  this.childIndex
                ].propertiesArray[this.column.properties.selectedVariationIndex]
                  .layoutOrientation.isVerticalRight ||
                this.draggable_editor_data[this.index][this.columnType][
                  this.childIndex
                ].propertiesArray[this.column.properties.selectedVariationIndex]
                  .layoutOrientation.isVerticalLeft
              "
              :value="
                this.draggable_editor_data[this.index][this.columnType][
                  this.childIndex
                ].propertiesArray[this.column.properties.selectedVariationIndex]
                  .productsPerRow
              "
              @change="
                handleValueChange($event?.target?.value, 'productsPerRow')
              "
            />
          </div>
        </div>
        <div
          v-if="activeTab === 'Dynamic'"
          class="widget-input-item border-bottom-0 mb-0"
        >
          <div class="d-flex justify-content-between align-items-center">
            <label class="b-form-label mb-0">Number of rows</label>
            <dropdownlist
              :class="'w-78'"
              :data-items="numberOfRows"
              :value="
                this.draggable_editor_data[this.index][this.columnType][
                  this.childIndex
                ].propertiesArray[this.column.properties.selectedVariationIndex]
                  .numberOfRows
              "
              @change="handleValueChange($event?.target?.value, 'numberOfRows')"
            />
          </div>
        </div>
        <div class="widget-input-item single-input">
          <div class="d-flex justify-content-between align-items-center">
            <label class="b-form-label mb-0">Max Image Height</label>
            <div class="single-input-box">
              <KInput
                :class="'mb-0 border-0'"
                :min="1"
                :max="300"
                type="number"
                @blur="
                  handleValueChange($event?.target?.value, 'maxImageHeight')
                "
                v-model="
                  this.draggable_editor_data[this.index][this.columnType][
                    this.childIndex
                  ].propertiesArray[
                    this.column.properties.selectedVariationIndex
                  ].maxImageHeight
                "
              />
              <span class="sib-type">PX</span>
            </div>
          </div>
        </div>
        <productItemSettings
          :class="'product-layout-product-item-settings'"
          :column="column"
          :columnType="columnType"
          :index="index"
          :childIndex="childIndex"
          :paddingStateKey="'item_padding_style'"
          :borderStateKey="'item_border_styles'"
        />
      </div>
    </div>
    <!-- Widget Accordion item General-->
    <!-- <div :class="isGeneralAccordion
            ? 'widget-accordion-item show'
            : 'widget-accordion-item'
            ">
            <div @click="toggleAccordion('isGeneralAccordion')" class="widget-accordion-head">
                <span>General</span>
                <span class="icon">
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M4.06066 11.0607L8.00003 7.12128L11.9394 11.0607L13 10L8.00003 5L3 10L4.06066 11.0607Z"
                            fill="#24243A" />
                    </svg>
                </span>
            </div>
            <generalAccordionComponent :column="column" :columnType="columnType" :index="index" :childIndex="childIndex" />
        </div> -->
    <!-- Widget Accordion item Text-->
    <div
      :class="
        isTextAccordion ? 'widget-accordion-item show' : 'widget-accordion-item'
      "
      v-if="textAccordionVisibilityHandler()"
    >
      <div
        @click="toggleAccordion('isTextAccordion')"
        class="widget-accordion-head"
      >
        <span>Text</span>
        <span class="icon">
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M4.06066 11.0607L8.00003 7.12128L11.9394 11.0607L13 10L8.00003 5L3 10L4.06066 11.0607Z"
              fill="#24243A"
            />
          </svg>
        </span>
      </div>
      <div class="widget-accordion-body">
        <div class="common-inner-tab">
          <div class="product-tab-buttons-2 d-flex mb-12">
            <template v-for="tab in textTabs" :key="tab.id">
              <div
                v-if="textTabVisbilityHandler(tab.value)"
                class="button p-7 cursor-pointer d-flex justify-content-center align-items-center bg-white border-normal-1"
                @click="onClickTextTabChange(tab.value)"
                :class="{ active: activeTextTab === tab.value }"
              >
                <p class="font-14 l-height-1 font-w-600 base-color mb-0">
                  {{ tab.name }}
                </p>
              </div>
            </template>
          </div>
        </div>
        <template v-for="tab in textTabs" :key="tab.id">
          <div
            v-if="
              activeTextTab == tab?.value && textTabVisbilityHandler(tab.value)
            "
          >
            <div class="widget-input-item font-size border-bottom-0 mb-0">
              <numberIncrementalComponent
                :column="column"
                :columnType="columnType"
                :index="index"
                :childIndex="childIndex"
                :stateKey="tab.fontSize"
                :label="'Font Size'"
              />
            </div>
            <div class="widget-input-item border-bottom-0 mb-0">
              <div class="d-flex justify-content-between align-items-center">
                <label class="b-form-label mb-0">Font Family</label>
                <div class="min-w-150">
                  <dropdownlist
                    :data-items="fontList"
                    :text-field="'text'"
                    :data-item-key="'value'"
                    :value="
                      this.draggable_editor_data[this.index][this.columnType][
                        this.childIndex
                      ].propertiesArray[
                        this.column.properties.selectedVariationIndex
                      ][tab.fontFamily]
                    "
                    @change="
                      handleValueChange($event?.target?.value, tab.fontFamily)
                    "
                  />
                </div>
              </div>
            </div>
            <div class="widget-input-item border-bottom-0 mb-0">
              <div class="d-flex justify-content-between align-items-center">
                <label class="b-form-label mb-0">Color</label>
                <ColorPicker
                  :show-clear-button="false"
                  :gradient-settings="{ opacity: false }"
                  v-model="
                    this.draggable_editor_data[this.index][this.columnType][
                      this.childIndex
                    ].propertiesArray[
                      this.column.properties.selectedVariationIndex
                    ][tab.color]
                  "
                />
              </div>
            </div>
            <div class="widget-input-item alignment border-bottom-0 mb-0">
              <customOptionComponent
                :column="column"
                :columnType="columnType"
                :index="index"
                :childIndex="childIndex"
                :options="
                  [2, 3].includes(tab.id)
                    ? fontStyleOptions.filter((item) => item.id !== 4)
                    : fontStyleOptions
                "
                :parentKey="tab.fontStyle"
                :label="'Font Style'"
                :noneSelected="true"
              />
            </div>
          </div>
        </template>
      </div>
    </div>
    <!-- Widget Accordion item Button-->
    <div
      :class="
        isButtonAccordion
          ? 'widget-accordion-item show'
          : 'widget-accordion-item'
      "
    >
      <div
        @click="toggleAccordion('isButtonAccordion')"
        class="widget-accordion-head"
      >
        <span>Button</span>
        <span class="icon">
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M4.06066 11.0607L8.00003 7.12128L11.9394 11.0607L13 10L8.00003 5L3 10L4.06066 11.0607Z"
              fill="#24243A"
            />
          </svg>
        </span>
      </div>
      <div class="widget-accordion-body">
        <div class="k-form-field text-start mb-24">
          <label>Button text</label>
          <k-input
            v-model="
              draggable_editor_data[this.index][this.columnType][
                this.childIndex
              ].propertiesArray[this.column.properties.selectedVariationIndex]
                .buttonProperties.btn_text
            "
            :class="'mb-12'"
            :placeholder="'Shop now'"
          />
        </div>

        <div class="widget-input-item font-size border-bottom-0 mb-0">
          <div class="d-flex justify-content-between align-items-center">
            <label class="b-form-label mb-0">Font Family</label>
            <div class="min-w-150">
              <dropdownlist
                :data-items="fontList"
                :text-field="'text'"
                :data-item-key="'value'"
                :value="
                  this.draggable_editor_data[this.index][this.columnType][
                    this.childIndex
                  ].propertiesArray[
                    this.column.properties.selectedVariationIndex
                  ].button_name_font_family
                "
                @change="
                  handleValueChange(
                    $event?.target?.value,
                    'button_name_font_family'
                  )
                "
              />
            </div>
          </div>
        </div>

        <div class="widget-input-item font-size border-bottom-0 mb-0">
          <numberIncrementalComponent
            :column="column"
            :columnType="columnType"
            :index="index"
            :childIndex="childIndex"
            :stateKey="'button_font_size'"
            :label="'Font Size'"
          />
        </div>

        <div class="widget-input-item alignment border-bottom-0 mb-0">
          <customOptionComponent
            :column="column"
            :columnType="columnType"
            :index="index"
            :childIndex="childIndex"
            :options="fontStyleOptions"
            :parentKey="'button_name_font_style'"
            :label="'Font Style'"
            :noneSelected="true"
          />
        </div>

        <div class="d-flex justify-content-between align-items-center mb-24">
          <label class="b-form-label mb-0">Color</label>
          <ColorPicker
            :show-clear-button="false"
            :gradient-settings="{ opacity: false }"
            v-model="
              this.draggable_editor_data[this.index][this.columnType][
                this.childIndex
              ].propertiesArray[this.column.properties.selectedVariationIndex]
                .buttonProperties.style.backgroundColor
            "
          />
        </div>

        <div class="widget-input-item alignment">
          <buttonCornerRadius
            :class="'product-corner-radius'"
            :column="column"
            :columnType="columnType"
            :index="index"
            :childIndex="childIndex"
            :stateKey="'buttonCornerStyles'"
          >
          </buttonCornerRadius>
        </div>

        <div class="widget-input-item border-bottom-0 mb-0">
          <div class="d-flex justify-content-between align-items-center">
            <label class="b-form-label mb-0">Border</label>
            <k-switch
              v-model="
                this.draggable_editor_data[this.index][this.columnType][
                  this.childIndex
                ].propertiesArray[this.column.properties.selectedVariationIndex]
                  .buttonProperties.isBorderApply
              "
              :size="'small'"
            />
          </div>
        </div>

        <productItemSettings
          v-if="!isLoadingExcept"
          :except="exceptBorders"
          :column="column"
          :columnType="columnType"
          :index="index"
          :childIndex="childIndex"
          :paddingStateKey="'buttonPaddingstyle'"
          :borderStateKey="'buttonBorderStyles'"
          :borderWidthLocked="true"
          :class="'mb-24'"
        />
        <!-- <div class="widget-input-item alignment border-bottom-0 mb-0">
          <alignmentComponent
            :column="column"
            :columnType="columnType"
            :index="index"
            :childIndex="childIndex"
            :stateKey="'buttonContainerStyle'"
          />
        </div> -->
      </div>
    </div>
    <div
      :class="
        isGeneralAccordion
          ? 'widget-accordion-item show'
          : 'widget-accordion-item'
      "
    >
      <div
        @click="toggleAccordion('isGeneralAccordion')"
        class="widget-accordion-head"
      >
        <span>GENERAL</span>
        <span class="icon">
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M4.06066 11.0607L8.00003 7.12128L11.9394 11.0607L13 10L8.00003 5L3 10L4.06066 11.0607Z"
              fill="#24243A"
            />
          </svg>
        </span>
      </div>
      <generalAccordionComponent
        :column="column"
        :columnType="columnType"
        :index="index"
        :childIndex="childIndex"
        :except="['borderColor', 'borderWidth', 'borderType']"
      />
    </div>
    <div
      :class="
        isRestrictContentToAccordion
          ? 'widget-accordion-item show'
          : 'widget-accordion-item'
      "
    >
      <div
        @click="toggleAccordion('isRestrictContentToAccordion')"
        class="widget-accordion-head"
      >
        <span>RESTRICT CONTENT TO</span>
        <span class="icon">
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M4.06066 11.0607L8.00003 7.12128L11.9394 11.0607L13 10L8.00003 5L3 10L4.06066 11.0607Z"
              fill="#24243A"
            />
          </svg>
        </span>
      </div>
      <RestrictContentToComponent
        :column="column"
        :columnType="columnType"
        :index="index"
        :childIndex="childIndex"
      />
    </div>
  </div>
  <template v-for="key in Object.keys(this.modalControls)" :key="key">
    <component
      :is="modalControls[key].component"
      v-if="modalControls[key].isVisible"
      :key="key"
      @addStaticProducts="addStaticProducts"
      @addDynamicProductsList="addDynamicProductsList"
      @close="handleModalControl(key, false)"
      :widgetElement="
        this.draggable_editor_data[this.index][this.columnType][this.childIndex]
          .propertiesArray[this.column.properties.selectedVariationIndex]
      "
    />
  </template>

  <template v-for="key in Object.keys(this.warningModals)" :key="key">
    <WarningModal
      v-if="warningModals[key].visibility"
      :modalDetails="warningModals[key]"
      :onCancel="() => onCancelWarningModal(key)"
      :onConfirm="warningModals[key].onConfirm"
    />
  </template>

  <app-loader v-if="isLoading" />
</template>
<script>
import { Input, Switch } from "@progress/kendo-vue-inputs";
import { Button } from "@progress/kendo-vue-buttons";
import generalAccordionComponent from "../../common-components/generalAccordionComponent.vue";
import tablePreferenceControl from "../table/tablePreferenceControl.vue";
import manageTableModal from "../table/manageTableModal.vue";
import RestrictContentToComponent from "../../common-components/restrictContentToComponent.vue";

import { NumericTextBox } from "@progress/kendo-vue-inputs";
import alignmentComponent from "../../common-components/alignmentComponent.vue";
import { ColorPicker } from "@progress/kendo-vue-inputs";
import browseProductModal from "./browseProductModal/browseProductModal.vue";
import createProductListModal from "./createProductListModal/createProductListModal.vue";
import { DropDownList } from "@progress/kendo-vue-dropdowns";
import { RadioButton } from "@progress/kendo-vue-inputs";
import TickIcon from "@/components/svg-icons/tickIcon.vue";
import customOptionComponent from "../../common-components/customOptionComponent.vue";
import numberIncrementalComponent from "../../common-components/numberIncrementalComponent.vue";
import fontList from "../../helpers/fontList";
import productItemSettings from "../../common-components/productItemSettings.vue";
import buttonCornerRadius from "../../common-components/buttonCornerRadius.vue";
import WarningModal from "@/components/clubDetails/emailCampaign/warningModal/WarningModal.vue";
import AppLoader from "@/components/common/AppLoader/AppLoader.vue";
import { deleteProductWidgetDynamicQueryService } from "@/services/clubDetails/productWidget.service";
import productListsModal from "./productListsModal/productListsModal.vue";

export default {
  name: "productController",

  inject: ["draggable_editor_data"],
  components: {
    KInput: Input,
    kbutton: Button,
    KSwitch: Switch,
    generalAccordionComponent,
    RestrictContentToComponent,
    manageTableModal,
    tablePreferenceControl,
    NumericTextBox,
    alignmentComponent,
    ColorPicker,
    browseProductModal,
    createProductListModal,
    dropdownlist: DropDownList,
    radiobutton: RadioButton,
    TickIcon,
    customOptionComponent,
    numberIncrementalComponent,
    productItemSettings,
    buttonCornerRadius,
    WarningModal,
    AppLoader,
    productListsModal,
  },
  props: {
    column: Object,
    index: Number,
    childIndex: Number,
    columnType: String,
  },
  data() {
    return {
      selectedDynamicProductListIndex: null,
      selectedProductIndex: null,
      isLoading: false,
      warningModals: {
        deleteProductListModal: {
          visibility: false,
          title: "Remove Confirmation",
          message:
            "Are you sure you want to remove this product list? This action cannot be undone.",
          confirmText: "Delete",
          cancelText: "Cancel",
          onConfirm: this.onDeleteProductList,
        },
        deleteProductModal: {
          visibility: false,
          title: "Remove Confirmation",
          message:
            "Are you sure you want to remove this product? This action cannot be undone.",
          confirmText: "Delete",
          cancelText: "Cancel",
          onConfirm: this.onDeleteProduct,
        },
      },
      editorOptions: {
        behavioursEnabled: true,
        wrapBehavioursEnabled: true,
        highlightActiveLine: false,
        highlightSelectedWord: false,
        useSoftTabs: true,
        cursorStyle: "smooth",
      },

      isProductAccordionVisible: true,
      isLayoutAccordion: true,
      isTextAccordion: true,
      isButtonAccordion: true,
      isGeneralAccordion: true,
      isRestrictContentToAccordion: true,
      isGeneralAccordion: true,

      activeTab: "Static",
      activeTextTab: "Name",
      textTabComponentVisibility: true,
      textTabs: [
        {
          id: 1,
          name: "Name",
          value: "name",
          fontSize: "text_name_font_size",
          fontFamily: "text_name_font_family",
          color: "text_name_color",
          fontStyle: "text_name_font_style",
        },
        {
          id: 2,
          name: "Price",
          value: "price",
          fontSize: "text_price_font_size",
          fontFamily: "text_price_font_family",
          color: "text_price_color",
          fontStyle: "text_price_font_style",
        },
        {
          id: 3,
          name: "Sale Price",
          value: "salePrice",
          fontSize: "text_sale_price_font_size",
          fontFamily: "text_sale_price_font_family",
          color: "text_sale_price_color",
          fontStyle: "text_sale_price_font_style",
        },
        {
          id: 4,
          name: "Description",
          value: "description",
          fontSize: "text_description_font_size",
          fontFamily: "text_description_font_family",
          color: "text_description_color",
          fontStyle: "text_description_font_style",
        },
      ],

      modalControls: {
        browseProductModal: {
          isVisible: false,
          component: "browseProductModal",
        },
        createProductListModal: {
          isVisible: false,
          component: "createProductListModal",
        },
        productListsModal: {
          isVisible: false,
          component: "productListsModal",
        },
      },

      fontList: fontList,
      fontStyleOptions: [
        {
          id: 1,
          key: "isBold",
          icon: "boldIcon",
          title: "Bold",
        },
        {
          id: 2,
          key: "isItalic",
          icon: "italicIcon",
          title: "Italic",
        },
        {
          id: 3,
          key: "isUnderline",
          icon: "underLineIcon",
          title: "Underline",
        },
        {
          id: 4,
          key: "isStrikethrough",
          icon: "strikethroughIcon",
          title: "Strikethrough",
        },
      ],
      productsPerRow: [1, 2, 3, 4, 5],
      numberOfRows: [1, 2, 3, 4, 5],

      alignmentOptions: [
        {
          id: 1,
          key: "isLeft",
          icon: "alignmentLeft",
          title: "Left",
        },
        {
          id: 2,
          key: "isCenter",
          icon: "alignmentCenter",
          title: "Center",
        },
        {
          id: 3,
          key: "isRight",
          icon: "alignmentRight",
          title: "Right",
        },
        {
          id: 4,
          key: "isIndent",
          icon: "alignmentIndent",
          title: "Indent",
        },
      ],
      orientationOptions: [
        {
          id: 1,
          key: "isHorizontal",
          icon: "orientationHorizontal",
          title: "Horizontal",
        },
        {
          id: 2,
          key: "isVerticalRight",
          icon: "orientationVerticalRight",
          title: "Vertical Right",
        },
        {
          id: 3,
          key: "isVerticalLeft",
          icon: "orientationVerticalLeft",
          title: "Vertical Left",
        },
      ],
      copyTags: [],
      isLoadingExcept: false,
    };
  },
  computed: {
    exceptBorders() {
      this.isLoadingExcept = true;
      const isBorderApply =
        this.draggable_editor_data[this.index][this.columnType][this.childIndex]
          .propertiesArray[this.column.properties.selectedVariationIndex]
          .buttonProperties.isBorderApply;

      setTimeout(() => {
        this.isLoadingExcept = false;
      }, 100);

      return !isBorderApply ? ["borderColor", "borderWidth", "borderType"] : [];
    },
  },
  mounted() {
    this.reloadState();
  },
  methods: {
    textAccordionVisibilityHandler() {
      return (
        this.draggable_editor_data[this.index][this.columnType][this.childIndex]
          .propertiesArray[this.column.properties.selectedVariationIndex]
          .itemsVisibility?.name?.isVisible ||
        this.draggable_editor_data[this.index][this.columnType][this.childIndex]
          .propertiesArray[this.column.properties.selectedVariationIndex]
          .itemsVisibility?.price?.isVisible ||
        this.draggable_editor_data[this.index][this.columnType][this.childIndex]
          .propertiesArray[this.column.properties.selectedVariationIndex]
          .itemsVisibility?.salePrice?.isVisible ||
        this.draggable_editor_data[this.index][this.columnType][this.childIndex]
          .propertiesArray[this.column.properties.selectedVariationIndex]
          .itemsVisibility?.description?.isVisible
      );
    },
    handleTextItemVisibility() {
      const items =
        this.draggable_editor_data[this.index][this.columnType][this.childIndex]
          .propertiesArray[this.column.properties.selectedVariationIndex]
          .itemsVisibility;

      Object.keys(items).every((key) => {
        if (items[key].isVisible) {
          this.activeTextTab = key;
          return false;
        }
        return true;
      });
    },
    textTabVisbilityHandler(value) {
      if (value == "description" && this.activeTab === "Dynamic") {
        return false;
      } else {
        return this.draggable_editor_data[this.index][this.columnType][
          this.childIndex
        ].propertiesArray[this.column.properties.selectedVariationIndex]
          .itemsVisibility[value]?.isVisible;
      }
    },
    onClickDeleteProduct(index) {
      this.selectedProductIndex = index;
      this.onOpenWarningModal("deleteProductModal");
    },
    onDeleteProduct() {
      this.onCancelWarningModal("deleteProductModal");

      this.draggable_editor_data[this.index][this.columnType][
        this.childIndex
      ].propertiesArray[
        this.column.properties.selectedVariationIndex
      ].productsList.splice(this.selectedProductIndex, 1);

      this.draggable_editor_data[this.index][this.columnType][
        this.childIndex
      ].propertiesArray[
        this.column.properties.selectedVariationIndex
      ].productIds.splice(this.selectedProductIndex, 1);

      this.selectedProductIndex = null;

      this.$store.commit("SHOW_NOTIFICATION", {
        message: "Product removed successfully.",
        type: "success",
      });
    },
    handleProductVisibility(index) {
      this.draggable_editor_data[this.index][this.columnType][
        this.childIndex
      ].propertiesArray[
        this.column.properties.selectedVariationIndex
      ].productsList[index].isVisible =
        !this.draggable_editor_data[this.index][this.columnType][
          this.childIndex
        ].propertiesArray[this.column.properties.selectedVariationIndex]
          .productsList[index]?.isVisible;
    },
    isElementVisible(type) {
      return this.draggable_editor_data[this.index][this.columnType][
        this.childIndex
      ].propertiesArray[this.column.properties.selectedVariationIndex]
        .itemsVisibility[type].isVisible;
    },
    onCancelWarningModal(key) {
      this.warningModals[key].visibility = false;
    },
    onOpenWarningModal(key) {
      this.warningModals[key].visibility = true;
    },
    addStaticProducts(products, ProductIds) {
      this.draggable_editor_data[this.index][this.columnType][
        this.childIndex
      ].propertiesArray[
        this.column.properties.selectedVariationIndex
      ].productsList = products;
      this.draggable_editor_data[this.index][this.columnType][
        this.childIndex
      ].propertiesArray[
        this.column.properties.selectedVariationIndex
      ].productIds = ProductIds;
    },
    addDynamicProductsList(productsList) {
      this.draggable_editor_data[this.index][this.columnType][
        this.childIndex
      ].propertiesArray[
        this.column.properties.selectedVariationIndex
      ].dynamicProductsList = productsList;

      this.modalControls.createProductListModal.isVisible = false;
    },
    removeDynamicProductList(index, item) {
      this.selectedDynamicProductListIndex = index;
      this.onOpenWarningModal("deleteProductListModal");
    },
    onDeleteProductList() {
      this.onCancelWarningModal("deleteProductListModal");
      const payload = {
        id: this.draggable_editor_data[this.index][this.columnType][
          this.childIndex
        ].propertiesArray[this.column.properties.selectedVariationIndex]
          .dynamicProductsList[this.selectedDynamicProductListIndex].id,
        clubId: this.$route.query.club_id,
      };

      this.isLoading = true;

      deleteProductWidgetDynamicQueryService(payload)
        .then((response) => {
          this.isLoading = false;
          this.$store.commit("SHOW_NOTIFICATION", {
            message: "Product list removed successfully.",
            type: "success",
          });

          if (
            this.draggable_editor_data[this.index][this.columnType][
              this.childIndex
            ].propertiesArray[this.column.properties.selectedVariationIndex]
              .dynamicProductsList[this.selectedDynamicProductListIndex].checked
          ) {
            if (this.selectedDynamicProductListIndex > 0) {
              this.draggable_editor_data[this.index][this.columnType][
                this.childIndex
              ].propertiesArray[
                this.column.properties.selectedVariationIndex
              ].dynamicProductsList[
                this.selectedDynamicProductListIndex - 1
              ].checked = true;
            } else {
              if (
                this.draggable_editor_data[this.index][this.columnType][
                  this.childIndex
                ].propertiesArray[this.column.properties.selectedVariationIndex]
                  .dynamicProductsList[this.selectedDynamicProductListIndex + 1]
                  ?.id
              ) {
                this.draggable_editor_data[this.index][this.columnType][
                  this.childIndex
                ].propertiesArray[
                  this.column.properties.selectedVariationIndex
                ].dynamicProductsList[
                  this.selectedDynamicProductListIndex + 1
                ].checked = true;
              }
            }
          }
          this.draggable_editor_data[this.index][this.columnType][
            this.childIndex
          ].propertiesArray[
            this.column.properties.selectedVariationIndex
          ].dynamicProductsList.splice(this.selectedDynamicProductListIndex, 1);
          this.selectedDynamicProductListIndex = null;
        })
        .catch((error) => {
          this.isLoading = false;
          this.$store.commit("SHOW_NOTIFICATION", {
            message:
              error.response.data?.Status?.Message ||
              "Something went wrong. Please try again.",
            type: "error",
          });
        });
    },
    handleValueChange(value, key) {
      this.draggable_editor_data[this.index][this.columnType][
        this.childIndex
      ].propertiesArray[this.column.properties.selectedVariationIndex][key] =
        value;

      if (key === "maxImageHeight") {
        if (value < 1) {
          this.draggable_editor_data[this.index][this.columnType][
            this.childIndex
          ].propertiesArray[this.column.properties.selectedVariationIndex][
            key
          ] = 1;
        }
        if (value > 300) {
          this.draggable_editor_data[this.index][this.columnType][
            this.childIndex
          ].propertiesArray[this.column.properties.selectedVariationIndex][
            key
          ] = 300;
        }
      }
    },
    reloadState() {
      this.activeTab =
        this.draggable_editor_data[this.index][this.columnType][
          this.childIndex
        ].propertiesArray[
          this.column.properties.selectedVariationIndex
        ].productAddProcessType;

      this.activeTextTab =
        this.draggable_editor_data[this.index][this.columnType][
          this.childIndex
        ].propertiesArray[
          this.column.properties.selectedVariationIndex
        ].activeTextTab;
    },
    handleModalControl(modalName, value) {
      this.modalControls[modalName].isVisible = value;
    },
    toggleAccordion(accordionName) {
      this[accordionName] = !this[accordionName];
    },
    onClickTabChange(tabName) {
      this.activeTab = tabName;

      this.draggable_editor_data[this.index][this.columnType][
        this.childIndex
      ].propertiesArray[
        this.column.properties.selectedVariationIndex
      ].productAddProcessType = tabName;
    },
    onClickTextTabChange(tabName) {
      this.activeTextTab = tabName;
      this.textTabComponentVisibility = false;

      setTimeout(() => {
        this.textTabComponentVisibility = true;
      }, 5);

      this.draggable_editor_data[this.index][this.columnType][
        this.childIndex
      ].propertiesArray[
        this.column.properties.selectedVariationIndex
      ].activeTextTab = tabName;
    },
    generateUniqueId() {
      return Math.random().toString(36).substr(2, 9);
    },
  },
};
</script>
<style lang="scss">
@import "./productController.scss";
</style>
